// React
import React, { useState } from 'react'

// Components
import { Box, Typography } from '@mui/material'
import SimpleDatePicker from '../../input/SimpleDatePicker'

// Utils
import { Dates, IClassroomModules } from '../../../models/IBooks'
import { formattedDate } from '../../../utils/functions'

// Icons
import BarCodeIcon from '../../../assets/components/Books/barcode-icon.svg'

// Styles
import { FormBox, ModuleItemContainer } from './styles/ModuleItem.styles'

interface ModuleItemProps {
  module: IClassroomModules
  chapterId: number
  classroomChapterId: number
  dates: Dates[]
  changeModuleDate: (chapterId: number, classroomChapterId: number, moduleId: number, key: any, value: Date) => void
  classroomChapterDates: {
    start_date?: Date,
    end_date?: Date
  }
  dateLim: {
    start_date?: Date,
    end_date?: Date
  }
  enableDateField?: boolean
}

const ModuleItem: React.FC<ModuleItemProps> = ({
  module,
  chapterId,
  classroomChapterId,
  dates,
  changeModuleDate,
  classroomChapterDates,
  dateLim,
  enableDateField
}) => {
  const [date, setDate] = useState({
    start_date: module.start_date ? new Date(module.start_date) : undefined,
    end_date: module.end_date ? new Date(module.end_date) : undefined
  })

  const chapterItem = dates?.find((date) => date.chapterId === chapterId)
  const classroomChapterItem = chapterItem?.classroom_chapters?.find((item: any) => item.id === classroomChapterId)
  const moduleItem = classroomChapterItem?.classroom_modules?.find((item: any) => item.id === module.id)
  const startDate = formattedDate(moduleItem?.start_date)
  const endDate = formattedDate(moduleItem?.end_date)

  const handleChangeDate = (chapterId: number, classroomChapterId: number, moduleId: number, key: any, value: Date) => {
    setDate({ ...date, [key]: value })
    changeModuleDate(chapterId, classroomChapterId, moduleId, key, value)
  }

  const filterRangeStartDate = (time: any) => {
    const selectedDate = String(date.end_date)
    const selectDateTime = new Date(selectedDate)
    return time < selectDateTime.getTime()
  }

  const filterRangeEndDate = (time: any) => {
    const selectedDate = String(date.start_date)
    const selectedDateTime = new Date(selectedDate)
    return time > selectedDateTime.getTime()
  }

  const startDateLim = classroomChapterDates.start_date ? new Date(classroomChapterDates.start_date) : undefined
  startDateLim?.setHours(0)
  startDateLim?.setMinutes(0)

  const endDateLim = classroomChapterDates.end_date ? new Date(classroomChapterDates.end_date) : undefined
  endDateLim?.setHours(23)
  endDateLim?.setMinutes(59)

  return (
    <ModuleItemContainer>
      <Box className='module'>
        <Box className='module_code'>
          <img src={BarCodeIcon} />
          <Typography
            component='span'
          >
            Código:
          </Typography>

          <Typography
            component='span'
          >
            {module.content_module.id}
          </Typography>
        </Box>

        <Box className='module_name'>
          <Typography
            component='span'
          >
            Nome:
          </Typography>

          <Typography
            component='span'
            title={module.content_module.name}
          >
            {module.content_module.name}
          </Typography>
        </Box>
      </Box>

      <FormBox>
        <Box className='field'>
          <Typography className='field_label'>Início:</Typography>

          <Box className='field_datepicker'>
            <SimpleDatePicker
              name='start_date'
              placeholder='dd/mm/aaaa'
              autoComplete='off'
              locale='pt-BR'
              selectsStart
              disabled={!enableDateField}
              value={startDate}
              startDate={date?.start_date}
              endDate={date?.end_date}
              minDate={startDateLim ?? dateLim?.start_date}
              maxDate={date?.end_date ?? endDateLim ?? dateLim?.end_date}
              changeDate={(value: any) =>
                handleChangeDate(
                  chapterId,
                  classroomChapterId,
                  module.id,
                  'start_date',
                  value
                )}
              filterTime={date?.end_date && filterRangeStartDate}
              readOnly
            />
          </Box>
        </Box>

        <Box className='field'>
          <Typography className='field_label'>
            Fim:
          </Typography>

          <Box className='field_datepicker'>
            <SimpleDatePicker
              name='end_date'
              placeholder='dd/mm/aaaa'
              autoComplete='off'
              locale='pt-BR'
              selectsEnd
              disabled={!enableDateField}
              value={endDate}
              startDate={date?.start_date}
              endDate={date?.end_date}
              minDate={date?.start_date ?? startDateLim ?? dateLim?.start_date}
              maxDate={endDateLim ?? dateLim?.end_date}
              changeDate={(value: any) =>
                handleChangeDate(
                  chapterId,
                  classroomChapterId,
                  module.id,
                  'end_date',
                  value
                )}
              filterTime={filterRangeEndDate}
              readOnly
            />
          </Box>
        </Box>
      </FormBox>
    </ModuleItemContainer>
  )
}

export default ModuleItem
