import { Box, Grid } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Container = styled(Box)(() => ({
  background: '#f5f5f5',
  borderRadius: '8px'
}))

export const TableCollapse = styled(Grid)(({ theme }) => ({
  cursor: 'pointer',
  padding: '8px',

  '& .table_cell': {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',

    '&:nth-of-type(4), &:nth-of-type(5)': {
      justifyContent: 'center',
    },

    '&:last-of-type': {
      justifyContent: 'flex-end',
    },

    '& p': {
      color: theme.palette.text.primary,
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '120%',

      '& > span': {
        color: theme.palette.secondary.main,
        fontWeight: 700
      }
    },
  },

  '& .author': {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    gap: '8px',
    maxWidth: '320px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    '&_name': {
      color: theme.palette.text.primary,
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '120%',
      maxWidth: '250px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }
  },

  '& .schools': {
    maxWidth: '180px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },

  '& .subjects': {
    maxWidth: '250px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
}))
