import React from 'react'

import { Avatar, Box, Grid, Paper, Skeleton, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
// import { ActivityParticipants, Participants } from '../../models/IActivities'
import { ButtonLightStyled } from '../button/Button'
import CreatrosTable, { Columns } from '../table/CreatorsTable'
import { stringAvatar } from '../../utils/functions'
import { ReactComponent as ViewSVG } from '../../assets/icons/view.svg'
import { ReactComponent as StudentSVG } from '../../assets/components/Books/student-icon.svg'
import { ReactComponent as BookSVG } from '../../assets/components/Books/book-icon.svg'

const paperStyled = {
  borderRadius: 4,
  mt: 4,
  p: 3,
  '& .MuiTable-root': {
    minWidth: '300px'
  },
  '& td': {
    p: 1,
    '&:first-of-type': {
      pl: 2
    },
    '&:last-of-type': {
      pr: 2
    }
  },
  '& span': {
    color: '#04AEC9',
    fontWeight: '500'
  },
  '& .red span': {
    color: '#F69E9E'
  }
}

// interface IStudentsPerformanceViewProps {
//   participants?: Array<Participants>
//   classroomId: number | string | null,
//   activityValue?: number | string | null
//   overview?: Array<ActivityParticipants>
//   activityType?: string
//   isFetching?: boolean
//   authors: Array
// }

const BookCreatorsRankingView: any = ({authors} : any) => {
  const navigate = useNavigate()

  const getColumns = () => {
    const columns: Columns[] = [
      {
        key: 'avatar',
        name: '',
        width: '45px',
        render: (row: any) => {
          return <Avatar {...stringAvatar(row.name)} variant='rounded' />
        }
      },
      {
        key: 'students',
        name: '',
        render: (row: any) => {
          return <Box display='flex' flexDirection='column' >
            <Typography variant='subtitle1' color='#666' fontWeight={600}>{row.name}</Typography>
            {/* <p style={{ margin: '0' }}>{row.school_classroom.name}</p> */}
          </Box>
        }
      },
      {
        key: 'scores',
        name: '',
        width: '150px',
        render: (row: any) => {
            return <Box sx={{ alignItems: 'center', display: 'flex', gap: '8px', width: '100%', justifyContent: 'flex-end' }}>
                <BookSVG />
                <Typography>{row.books}</Typography>
              </Box>
        }
      }
    ]

    return columns
  }


const mostCreated = authors?.more_active
const lessCreated = authors?.less_active


  return (
    <Paper sx={paperStyled}>

      {
        authors?.isFetching ? (
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Skeleton animation='wave' height={50} width={200} />
              <Skeleton animation='wave' height={80} width={200} />
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '32px'
              }}
            >
              <Box width={'100%'}>
                <Skeleton variant='text' sx={{ fontSize: '1rem', width: '40%' }} />
                {Array(5).fill(0).map((_, index) => (
                  <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', gap: '8px', alignItems: 'center', height:'72px' }}>
                    <Skeleton animation='wave' variant='circular' width={40} height={40} sx={{ flexShrink: 0}} />
                    <Skeleton animation='wave' height={'90px'} width={'100%'} />
                  </Box>
                  ))}
              </Box>
              <Box width={'100%'}>
                <Skeleton variant='text' sx={{ fontSize: '1rem', width: '40%' }} />
                {Array(5).fill(0).map((_, index) => (
                  <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', gap: '8px', alignItems: 'center', height:'72px' }}>
                    <Skeleton animation='wave' variant='circular' width={40} height={40} sx={{ flexShrink: 0}} />
                    <Skeleton animation='wave' height={'90px'} width={'100%'} />
                  </Box>
                  ))}
              </Box>
            </Box>
          </Box>
        ) : (
          <>
            <Box display='flex' justifyContent='space-between' mb={2}>
              <Typography component='h2' sx={{ fontSize: 18, fontWeight: 600, mb: 2 }}>
                <StudentSVG style={{ marginRight: 16 }} />
                Ranking de criação de livros
              </Typography>
              <ButtonLightStyled
                onClick={() => navigate('/books/reports/authors')}
                startIcon={<ViewSVG />}
                variant='outlined'>
                Visualizar lista completa
              </ButtonLightStyled>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} sx={{ width: 520 }}>
                <Typography component='p' variant='subtitle2' color='#9F9F9F'>
                    Autores que mais criaram
                </Typography>
                <CreatrosTable
                  columns={getColumns()}
                  data={mostCreated?.slice(0, 5) || []}
                  withoutHead
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography component='p' variant='subtitle2' color='#9F9F9F'>
                Autores que menos criaram
                </Typography>
                <CreatrosTable
                  columns={getColumns()}
                  data={lessCreated?.slice(0, 5) || []}
                  withoutHead
                  minus
                />
              </Grid>
            </Grid>
          </>
        )
      }
    </Paper>
  )
}

export default BookCreatorsRankingView
