import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const AddressFormContainer = styled(Box)(({ theme }) => ({
  '& .secondary_title': {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '120%',
    marginBottom: '16px',
  },

  '& .datafield': {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',

    '& > label': {
      color: '#9f9f9f',
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: '0.14px',
    },

    '& .error_message': {
      color: theme.palette.error.main,
      fontWeight: 400,
      fontSize: '0.75rem',
    }
  }
}))
