import React, { useEffect, useState } from 'react'
import arrayMutators from 'final-form-arrays'
import { useLocation, useNavigate } from 'react-router-dom'

// Components
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography
} from '@mui/material'
import { FieldArray } from 'react-final-form-arrays'
import { Field, Form } from 'react-final-form'
import AddressForm from './AddressForm'
import AutoCompleteSelect from '../../select/AutoCompleteInputSelect'
import ClassroomForm from './ClassroomForm'
import CpfInput from '../../input/CpfInput'
import DateInput from '../input/DataPickerInput'
import Input from '../../input/Input'
import PhoneInput from '../../input/PhoneInput'
import SelectField from '../../select/SelectField'
import SuccessMessage from '../../message/SuccessMessage'

// Redux
import {
  createStudentRequest,
  getAllStudentsRequest,   // eslint-disable-line
  resetStudent,
  updateStudentDataRequest
} from '../../../store/students/actions'
import { getSchoolAppIdRequest } from '../../../store/school_app_id/actions'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'

// Utils
import {
  required,
  validateEmail,
  maxLength,
  composeValidators,
  minLength
} from '../../../utils/formUtils'
import { IGeneralStudents, IStudentFormValues } from '../../../models/IStudent'
import { removeNonNumericCharacters } from '../../../utils/functions'

// Icons and images
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import ClearIcon from '@mui/icons-material/Clear'
import { ReactComponent as DataIcon } from '../../../assets/components/ManagersPage/data-icon.svg'

// Styles
import { BtnBox, BtnContainer, Container, ParentFormContainer } from './styles/StudentForm.styles'

const StudentFormTwo = ({ initialValues }: any) => {
  const [taxpayerNumber, setTaxpayerNumber] = useState('')  // eslint-disable-line
  const [customValues, setCustomValues] = useState({} as IStudentFormValues)
  const navigate = useNavigate()
  const { state } = useLocation()
  const dispatch = useAppDispatch()
  const { selectedUnit } = useAppSelector(state => state.persistable)
  const { items, isSaving, isSuccess } = useAppSelector(state => state.students)
  const { items: appIds } = useAppSelector(state => state.schoolAppId)

  const findStudentWithCpf = (items: IGeneralStudents[], taxpayerNumber: string) => {  // eslint-disable-line
    const singleStudent = items?.find((student) => {
      return removeNonNumericCharacters(student?.user?.taxpayer_number) === removeNonNumericCharacters(taxpayerNumber)
    })
    return singleStudent
  }

  const handleBackToPage = () => {
    navigate('/manage-students')
  }

  const handleSubmitForm = (values: IStudentFormValues) => {
    const appId = appIds?.find((item) => item?.school_unit?.id === selectedUnit?.id)
    const cpfWithoutFormatting = values?.taxpayer_number?.replace(/\D/g, '')
    const parentsData = values?.parents?.map((parent) => {
      return {
        ...parent,
        email: parent?.email?.toLowerCase(),
        taxpayer_number: parent?.taxpayer_number?.replace(/\D/g, ''),
        additional_data: {
          address: {
            ...parent?.additional_data?.address
          }
        }
      }
    })

    const data = {
      ...values,
      taxpayer_number: cpfWithoutFormatting,
      school_unit_id: selectedUnit?.id,
      parents: parentsData,
      app_id: appId?.id
    }

    if (initialValues?.id) {
      dispatch(updateStudentDataRequest(data))
    } else {
      dispatch(createStudentRequest(data))
    }
  }

  useEffect(() => {
    // !initialValues?.id && dispatch(getAllStudentsRequest({ school_unit_id: selectedUnit?.id }))
    dispatch(getSchoolAppIdRequest())

    return () => {
      dispatch(resetStudent())
    }
  }, [selectedUnit])

  useEffect(() => {
    if (state) {
      const item = [{
        grade: state?.grade?.name,
        classroom: {
          id: state?.classroom?.id,
          name: state?.classroom?.name,
          grade: state?.grade
        }
      }]

      setCustomValues(prevState => ({
        ...prevState,
        classrooms: item
      }))
    }
  }, [state])

  // useEffect(() => {
  //   const student = findStudentWithCpf(items, taxpayerNumber)
  //   console.log('student', student)  // eslint-disable-line
  // }, [taxpayerNumber])

  return (
    <>
      {isSuccess ? (
        <SuccessMessage
          type='students'
          path='manage-students'
          isEdit={Boolean(initialValues?.id)}
        />
      ) : (
        <Container>
          <Box className='title'>
            <DataIcon />
            <Typography component='h2'>Dados cadastrais</Typography>
          </Box>

          <Box>
            <Form
              onSubmit={handleSubmitForm}
              mutators={{ ...arrayMutators }}
              initialValues={initialValues && Object?.keys(initialValues)?.length > 0
                ? initialValues
                : customValues}
              render={({ handleSubmit, form, values }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Paper className='form_container'>
                      <Typography
                        component='h3'
                        className='primary_title'
                      >
                        Aluno
                      </Typography>

                      <Box className='student_form'>
                        <Typography
                          component='h4'
                          className='secondary_title'
                        >
                          Dados pessoais
                        </Typography>

                        <Grid container columnSpacing={3} rowSpacing={3}>
                          <Grid item xs={3}>
                            <Box className='field'>
                              <label htmlFor='taxpayer_number'>CPF *</label>
                              <Field
                                id='taxpayer_number'
                                name='taxpayer_number'
                                component={AutoCompleteSelect}
                                disabled={Boolean(initialValues?.id)}
                                validate={composeValidators(required, maxLength(14), minLength(14))}
                                placeholder='000.000.000-00'
                                setTaxpayerNumber={setTaxpayerNumber}
                                options={items?.map((option) => option?.user?.taxpayer_number)}
                                disableClearable
                              />
                            </Box>
                          </Grid>

                          <Grid item xs={3}>
                            <Box className='field'>
                              <label htmlFor='registration'>Matrícula *</label>
                              <Field
                                id='registration'
                                name='registration'
                                component={Input}
                                validate={required}
                                placeholder='Digite a matrícula...'
                              />
                            </Box>
                          </Grid>

                          <Grid item xs={6}>
                            <Box className='field'>
                              <label htmlFor='name'>Nome do aluno(a) *</label>
                              <Field
                                id='name'
                                name='name'
                                component={Input}
                                validate={required}
                                placeholder='Insira o nome completo'
                              />
                            </Box>
                          </Grid>

                          <Grid item xs={3}>
                            <Box className='field'>
                              <label htmlFor='email'>E-mail *</label>
                              <Field
                                id='email'
                                name='email'
                                component={Input}
                                validate={composeValidators(required, validateEmail)}
                                placeholder='nome@exemplo.com'
                              />
                            </Box>
                          </Grid>

                          <Grid item xs={3}>
                            <Box className='field'>
                              <label htmlFor='phone'>Contato *</label>
                              <Field
                                id='phone'
                                name='phone'
                                component={PhoneInput}
                                validate={required}
                                placeholder='(00) 00000-0000'
                              />
                            </Box>
                          </Grid>

                          <Grid item xs={3}>
                            <Box className='field'>
                              <label htmlFor='birthday'>Data de nascimento *</label>
                              <Field
                                id='birthday'
                                name='birthday'
                                component={DateInput}
                                validate={required}
                                placeholder='00/00/00'
                              />
                            </Box>
                          </Grid>

                          <Grid item xs={3}>
                            <Box className='field'>
                              <label htmlFor='genre'>Sexo biológico *</label>
                              <Field
                                id='genre'
                                name='genre'
                                component={SelectField}
                                validate={required}
                                width='100%'
                                hasBorder
                                placeholder='Selecione...'
                                options={[
                                  { name: 'Masculino', value: 'male' },
                                  { name: 'Feminino', value: 'female' }
                                ]}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>

                      <Box className='academic_form'>
                        <Typography
                          component='h4'
                          className='secondary_title'
                        >
                          Informações acadêmicas
                        </Typography>

                        <ClassroomForm
                          unitId={selectedUnit?.id}
                          change={form.change}
                          hasAttribution={values?.classrooms?.length > 0}
                          values={values?.classrooms}
                        />
                      </Box>

                      <AddressForm
                        // defaultValue={state?.student?.additional_data}
                        change={form.change}
                        values={values?.zipcode}
                      />

                      <Box
                        className='parent_form'
                        sx={{ marginTop: values?.parents?.length > 0 ? '56px' : 0 }}
                      >
                        <FieldArray name='parents'>
                          {({ fields }) => {
                            return (
                              <>
                                <Box className='form'>
                                  {fields.map((name, index) => (
                                    <Box key={index}>
                                      <Typography
                                        component='h3'
                                        className='title'
                                      >
                                        Responsável {index + 1}
                                      </Typography>

                                      <ParentFormContainer>
                                        <Tooltip title='Remover responsável'>
                                          <IconButton
                                            type='button'
                                            className='btn_remove'
                                            size='small'
                                            onClick={() => fields.remove(index)}
                                          >
                                            <ClearIcon className='icon' />
                                          </IconButton>
                                        </Tooltip>

                                        <Grid container columnSpacing={2} rowSpacing={2}>
                                          <Grid item xs={12}>
                                            <Box className='field'>
                                              <label htmlFor={`${name}.name`}>Nome do responsável {index + 1}</label>
                                              <Field
                                                id={`${name}.name`}
                                                name={`${name}.name`}
                                                component={Input}
                                                placeholder='Insira o nome completo do responsável'
                                              />
                                            </Box>
                                          </Grid>

                                          <Grid item xs={3}>
                                            <Box className='field'>
                                              <label htmlFor={`${name}.taxpayer_number`}>CPF</label>
                                              <Field
                                                id={`${name}.taxpayer_number`}
                                                name={`${name}.taxpayer_number`}
                                                component={CpfInput}
                                                maxLength={14}
                                                // validate={composeValidators(required, maxLength(14), minLength(14))}
                                                placeholder='000.000.000-00'
                                              // disabled={initialValues?.parents && initialValues?.parents[index]?.taxpayer_number}
                                              />
                                            </Box>
                                          </Grid>

                                          <Grid item xs={3}>
                                            <Box className='field'>
                                              <label htmlFor={`${name}.birthday`}>Data de nascimento</label>
                                              <Field
                                                id={`${name}.birthday`}
                                                name={`${name}.birthday`}
                                                component={DateInput}
                                                placeholder='00/00/00'
                                                label='Data de nascimento'
                                              />
                                            </Box>
                                          </Grid>

                                          <Grid item xs={3}>
                                            <Box className='field'>
                                              <label htmlFor={`${name}.email`}>E-mail</label>
                                              <Field
                                                id={`${name}.email`}
                                                name={`${name}.email`}
                                                component={Input}
                                                placeholder='nome@exemplo.com'
                                              />
                                            </Box>
                                          </Grid>

                                          <Grid item xs={3}>
                                            <Box className='field'>
                                              <label htmlFor={`${name}.phone`}>Contato</label>
                                              <Field
                                                id={`${name}.phone`}
                                                name={`${name}.phone`}
                                                component={PhoneInput}
                                                placeholder='(00) 00000-0000 '
                                              />
                                            </Box>
                                          </Grid>
                                        </Grid>

                                        <Box className='address_form'>
                                          <AddressForm
                                            name={`${name}.additional_data.address`}
                                            change={form.change}
                                            values={values?.parents[index]?.additional_data?.address?.zipcode}
                                          />
                                        </Box>
                                      </ParentFormContainer>
                                    </Box>
                                  ))}
                                </Box>

                                <BtnContainer>
                                  <Button
                                    type='button'
                                    variant='contained'
                                    className='btn_add'
                                    onClick={() => fields.push({})}
                                    startIcon={
                                      <AddCircleOutlineIcon className='icon' />
                                    }
                                  >
                                    Adicionar responsável
                                  </Button>
                                </BtnContainer>
                              </>

                            )
                          }}
                        </FieldArray>
                      </Box>

                      <Typography className='required'>* Campo obrigatório</Typography>
                    </Paper>

                    <BtnBox>
                      <Button
                        type='button'
                        variant='outlined'
                        className='back_btn'
                        disabled={isSaving}
                        onClick={handleBackToPage}
                      >
                        Voltar
                      </Button>

                      <Button
                        type='submit'
                        variant='contained'
                        className='submit_btn'
                        disabled={isSaving}
                        startIcon={isSaving
                          ? (
                            <CircularProgress
                              size={20}
                              variant='indeterminate'
                              color='inherit'
                            />
                          ) : undefined}
                      >
                        Salvar
                      </Button>
                    </BtnBox>
                  </form>
                )
              }}
            />
          </Box>
        </Container>
      )}
    </>
  )
}

export default StudentFormTwo
