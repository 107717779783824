import { Box, Paper } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Title = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: '16px',
  margin: '33px 0px 18px',

  '& > h2': {
    color: theme.palette.text.primary,
    fontSize: '18px',
    fontWeight: 600,
    letterSpacing: '0.18px'
  }
}))

export const PaperFormContainer = styled(Paper)(({ theme }) => ({
  borderRadius: '16px',
  padding: '32px 24px 24px',

  '& .primary_title': {
    color: theme.palette.text.primary,
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '120%',
    marginBottom: '32px',
  },

  '& .secondary_title': {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '120%'
  },

  '& .personal_form': {
    margin: '24px 0 56px',
  },

  '& .datafield': {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',

    '& > label': {
      color: '#9f9f9f',
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: '0.14px',
    }
  },

  '& .academic_form': {
    margin: '16px 0 56px',
  },

  '& .required': {
    color: '#9f9f9f',
    fontSize: '14px',
    fontStyle: 'italic',
    marginTop: '20px',
    textAlign: 'right',
  }
}))

export const BtnContainer = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  gap: '16px',
  justifyContent: 'flex-end',
  marginTop: '32px',

  '& .back_btn': {
    borderRadius: '8px',
    borderColor: '#b7b2c8',
    color: '#b7b2c8',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '120%',
    padding: '14.5px 47.5px',
    textTransform: 'capitalize'
  },

  '& .submit_btn': {
    borderRadius: '8px',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '120%',
    padding: '14.5px 39px',
    textTransform: 'capitalize'
  }
}))
