import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const ClassroomItemContainer = styled(Box)(({
  background: '#f5f5f5',
  borderRadius: '8px',
  padding: '16px'
}))

export const Container = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',

  '& .col-1': {
    order: 1
  },

  '& .col-2': {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    gap: '24px',
    justifyContent: 'flex-end',
    marginRight: '8px',
    order: 2,

    '& .label': {
      color: theme.palette.text.primary,
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '150%'
    },
  },

  '& .col-3': {
    order: 3,

    '& .collapse_btn': {
      height: '18px',
      width: '18px'
    },
  },

  [theme.breakpoints.down(1150)]: {
    flexWrap: 'wrap',

    '& .col-1': {
      flex: 1
    },

    '& .col-2': {
      order: 3,
      flex: '1 0 100%',
      justifyContent: 'flex-start',
      marginTop: '8px'
    },

    '& .col-3': {
      order: 2
    }
  }
}))

export const SchoolName = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.light,
  borderRadius: '8px',
  color: '#ffffff',
  cursor: 'pointer',
  maxWidth: '350px',
  padding: '5.5px 18px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}))

export const FormBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '32px',

  '& .field': {
    alignItems: 'center',
    display: 'flex',
    gap: '8px',

    '&_label': {
      color: theme.palette.text.primary,
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: '0.14px'
    },

    '&_datepicker': {
      maxWidth: '170px',
    }
  }
}))

export const ModuleContent = styled(Box)(({ theme }) => ({
  marginTop: '8px',

  '& .module_container': {
    marginTop: '32px',
    '& .label': {
      color: theme.palette.text.primary,
      fontSize: '16px',
      fontWeight: 700
    },

    '& .module_items': {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      marginTop: '16px'
    }
  }
}))
