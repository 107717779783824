import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const ChapterContainer = styled(Box)(({ theme }) => ({
  border: '1px dashed #b7b2c8',
  borderRadius: '8px',

  '& .chapter_container': {
    padding: '20px 16px',

    '& .chapter': {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',

      '& .wrapper': {
        alignItems: 'center',
        display: 'flex',
        gap: '8px',

        '& .chapter_number': {
          color: theme.palette.primary.main,
          fontSize: '16px',
          fontWeight: 700,
          lineHeight: '150%',
        },

        '& .chapter_name': {
          color: theme.palette.text.primary,
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '150%',
        }
      },

      '& .chapter_order': {
        alignItems: 'center',
        display: 'flex',
        gap: '8px',

        '& > img': {
          width: '16px',
        },

        '& > span': {
          color: '#9f9f9f',
          fontSize: '14px',
          fontWeight: 700,
        }
      }
    },

    '& .chapter_description': {
      color: '#9f9f9f',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '150%',
      marginTop: '16px',
    },

    '& .collapse': {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      gap: '8px',
      marginTop: '24px',
      width: 'fit-content',

      '& .collapse_label': {
        alignItems: 'center',
        display: 'flex',
        gap: '4px',

        '& > span': {
          fontSize: '16px',
          fontWeight: 700,
          lineHeight: '150%',

          '&:first-of-type': {
            color: theme.palette.text.primary,
          },

          '&:last-of-type': {
            color: theme.palette.primary.light,
          }
        }
      },

      '& .collapse_btn': {
        height: '18px',
        width: '18px',
      }
    },

    '& .modules_container': {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
      marginTop: '24px',

      '& .module': {
        alignItems: 'center',
        background: '#f0f3f4',
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '16px',

        '& .module_name': {
          alignItems: 'center',
          display: 'flex',

          '& span:first-of-type': {
            color: theme.palette.primary.light,
            fontSize: '14px',
            fontWeight: 700,
            lineHeight: '120%',
            margin: '0 6px 0 4px',
          },

          '& span:last-of-type': {
            color: theme.palette.text.primary,
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '150%',
          }
        },

        '& .module_counts': {
          alignItems: 'center',
          display: 'flex',
          gap: '4px',

          '& span:first-of-type': {
            color: theme.palette.text.primary,
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '150%',
          },

          '& span:last-of-type': {
            color: theme.palette.primary.light,
            fontSize: '14px',
            fontWeight: 700,
            lineHeight: '150%',
          }
        }
      }
    }
  }
}))
