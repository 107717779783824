import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Components
import { Box, CircularProgress, Skeleton, Typography } from '@mui/material'
import { Form } from 'react-final-form'
import Btn from '../../button/Button'
import SchoolCollapse from '../collapse/SchoolCollapse'

// Redux
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import {
  addClassroomsRequest,
  clearClassrooms,
  fetchBookPublishedRequest,
  fetchClassroomsRequest,
  fetchChaptersRequest
} from '../../../store/books/actions'

// Utils
import { IUnit } from '../../../models/IUnit'
import { scrollToTop } from '../../../utils/functions'

// Icons and images
import SchoolIcon from '../../../assets/components/Books/school-icon.svg'

// Styles
import { BtnContainer, PaperContainer } from './styles/BookDestination.styles'

export interface School {
  id: number
  name: string
  class: Class[]
}

export interface Class {
  id: number
  name: string
  students: Student[]
}

export interface Student {
  id: number
  name: string
}

interface BookDestinationFormProps {
  id: number
  activeTab: number
  nextTab: () => void
}

const BookDestinationForm: React.FC<BookDestinationFormProps> = ({ id, activeTab, nextTab }) => {
  const navigate = useNavigate()
  const { user: { units } } = useAppSelector(state => state.auth)
  const {
    classrooms: { items, isFetching },
    bookToPublish: { book, isProcessing },
    chapters
  } = useAppSelector(state => state.books)
  const dispatch = useAppDispatch()
  const schoolIds = units?.map(unit => unit.id)
  const ids = book?.chapters?.[0]?.classroom_chapters?.map(classroom => classroom.school_classroom.id)
  const [classroomsIds, setClassroomsIds] = useState<any[]>([])
  const [isError, setIsError] = useState<boolean>(false)

  const modules = chapters.items.flatMap((item) => item.modules.map((module, index) => ({
    id: module.id,
    module_order: index + 1
  })))

  const handleSelectClassroom = (
    event: React.ChangeEvent<HTMLInputElement>,
    classroomId?: number
  ) => {
    const { checked } = event.target
    if (checked) {
      setClassroomsIds(prevState => ([...prevState, classroomId]))
    } else {
      const classrooms = classroomsIds.filter(item => item !== classroomId)
      setClassroomsIds(classrooms)
    }
  }

  const handleAddclassroomsOnBooks = () => {
    if (classroomsIds.length === 0) {
      setIsError(true)
    } else {
      setIsError(false)
      dispatch(addClassroomsRequest({
        id: Number(id),
        school_classroom_ids: classroomsIds,
        modules,
        nextTab: () => nextTab()
      }))
    }
  }

  useEffect(() => {
    scrollToTop()
    dispatch(fetchBookPublishedRequest({ id: Number(id) }))
  }, [])

  useEffect(() => {
    ids && ids?.length > 0 && setClassroomsIds(ids)
  }, [book?.chapters])

  useEffect(() => {
    dispatch(fetchChaptersRequest({ bookId: Number(id) }))
    schoolIds && dispatch(fetchClassroomsRequest({ school_unit_ids: schoolIds }))

    return () => {
      dispatch(clearClassrooms())
    }
  }, [])

  if (activeTab !== 1) {
    return null
  }

  if (isFetching) {
    return (
      <>
        <Skeleton variant='rounded' sx={{ borderRadius: '16px', height: '230px' }} />
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            gap: '16px',
            justifyContent: 'flex-end',
            marginTop: '32px'
          }}
        >
          <Skeleton variant='rounded' sx={{ borderRadius: '8px', height: '52px', width: '163px' }} />
          <Skeleton variant='rounded' sx={{ borderRadius: '8px', height: '52px', width: '163px' }} />
        </Box>
      </>
    )
  }

  return (
    <Form
      onSubmit={() => undefined}
      render={({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <PaperContainer>
              <Box className='title'>
                <img src={SchoolIcon} alt='ícone da escola' />
                <Typography component='h3'>
                  Selecione a(s) escola(s)
                </Typography>
              </Box>

              <Box className='required_message-box'>
                <Typography className='required_message'>
                  Selecione pelo menos uma turma para avançar. *
                </Typography>
              </Box>

              <Box className='collapse_container'>
                {units && units?.map((school: IUnit) => {
                  return (
                    <SchoolCollapse
                      key={school?.id}
                      numberOfClassrooms={items?.[school?.id]?.length}
                      schoolName={school?.name}
                      schoolData={items?.[school?.id]}
                      classroomsSelected={classroomsIds}
                      handleSelectClassroom={handleSelectClassroom}
                    />
                  )
                })}
              </Box>

              {isError && (
                <Typography className='error_message'>
                  Selecione pelo menos uma turma para agendar este livro *
                </Typography>
              )}
            </PaperContainer>

            <BtnContainer>
              <Btn
                type='button'
                variant='outlined'
                onClick={() => navigate('/books')}
                disabled={isProcessing}
              >
                Voltar
              </Btn>
              <Btn
                variant='contained'
                onClick={handleAddclassroomsOnBooks}
                disabled={isProcessing}
                startIcon={
                  isProcessing
                    ? <CircularProgress size={20} variant='indeterminate' color='inherit' />
                    : undefined
                }
              >
                Próximo
              </Btn>
            </BtnContainer>
          </form>
        )
      }}
    />
  )
}

export default BookDestinationForm
