import { AnyAction, createReducer } from '@reduxjs/toolkit'
import { Class, IClassroom } from '../../models/IClassroom'
import * as actions from './actions'

interface StateType {
  items: IClassroom[] | []
  classes: Class[]
  isFetching: boolean
  pagination: {
    current: number,
    totalOfPages: number,
    total: number
  }
  school: object
  students: object[]
  isError: boolean
  messageError: string | null
  migrateStudents: {
    isFetching: boolean,
    isSuccess: boolean,
    isError: boolean,
    messageError: string | null
  }
}

const initialState: StateType = {
  items: [],
  classes: [],
  isFetching: false,
  pagination: {
    current: 1,
    totalOfPages: 0,
    total: 0
  },
  school: [],
  students: [{}],
  isError: false,
  messageError: null,
  migrateStudents: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    messageError: null
  }
}

const classroomReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.classroomFetchRequest, (state: StateType): StateType => ({
      ...state,
      isFetching: true,
      isError: false,
      messageError: ''
    }))
    .addCase(actions.classroomFetchSuccess, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      isFetching: false,
      isError: false,
      messageError: '',
      items: action.payload.data,
      pagination: action.payload.pagination
    }))
    .addCase(actions.classroomFetchFailure, (state: StateType): StateType => ({
      ...state,
      isFetching: false,
      isError: true,
    }))
    .addCase(actions.fetchClassroomWithStudentsRequest, (state: StateType): StateType => ({
      ...state,
      isFetching: true,
      isError: false,
      messageError: ''
    }))
    .addCase(actions.fetchClassroomWithStudentsSuccess, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      isFetching: false,
      isError: false,
      messageError: '',
      classes: action.payload.data
    }))
    .addCase(actions.fetchClassroomWithStudentsFailure, (state: StateType): StateType => ({
      ...state,
      isFetching: false,
      isError: true,
    }))
    .addCase(actions.fetchClassroomWithStudentsCoordinatorRequest, (state: StateType): StateType => ({
      ...state,
      isFetching: true,
      isError: false,
      messageError: ''
    }))
    .addCase(actions.fetchClassroomWithStudentsCoordinatorSuccess, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      isFetching: false,
      isError: false,
      messageError: '',
      classes: action.payload.data
    }))
    .addCase(actions.fetchClassroomWithStudentsCoordinatorFailure, (state: StateType): StateType => ({
      ...state,
      isFetching: false,
      isError: true,
    }))
    .addCase(actions.clearClassroomWithStudents, (): StateType => ({
      ...initialState
    }))
    .addCase(actions.classroomWithStudentsChangePagination, (state: StateType, action: AnyAction) => ({
      ...state,
      pagination: {
        ...state.pagination,
        total: action.payload.total,
        totalOfPages: action.payload.totalOfPages,
        current: action.payload.current
      }
    }))
    .addCase(actions.migrateStudentsRequest, (state: StateType): StateType => ({
      ...state,
      migrateStudents: {
        ...state.migrateStudents,
        isFetching: true,
        isSuccess: false,
        isError: false,
        messageError: null
      }
    }))
    .addCase(actions.migrateStudentsSuccess, (state: StateType): StateType => ({
      ...state,
      migrateStudents: {
        isFetching: false,
        isSuccess: true,
        isError: false,
        messageError: null
      }
    }))
    .addCase(actions.migrateStudentsFailure, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      migrateStudents: {
        ...state.migrateStudents,
        isFetching: false,
        isSuccess: false,
        isError: true,
        messageError: action.payload
      }
    }))
    .addCase(actions.clearMigrateStudents, (state: StateType): StateType => ({
      ...state,
      migrateStudents: {
        ...initialState.migrateStudents
      }
    }))
})

export default classroomReducer
