import React, { useEffect, useRef } from 'react'
import { isEmpty } from 'lodash'
import { styled } from '@mui/material/styles'
import { Box, CircularProgress, Pagination, Typography } from '@mui/material'
import SelectInput from '../select/SelectInput'
import CheckboxInput from '../input/CheckboxInput'
import CheckButton from '../button/CheckButton'
import { ReactComponent as NoOptionsImage } from '../../assets/components/Modules/noOptions-image.svg'
import {
  fetchModuleByIdRequest,
  modulesItemsFetchRequest,
  clearCurrentItem,
  importModuleRequest
} from '../../store/modules/actions'
import ModuleCard from './ModuleCard'
import { IModule } from '../../models/IModule'
import NoItemsCard from '../card/NoItemsCard'
import { scrollToTop } from '../../utils/functions'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { setModules } from '../../store/books/actions'

const HeaderList = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: 24,
  '& p': {
    color: theme.palette.primary.main,
    fontWeight: 500
  },
  '& span': {
    fontSize: 14
  },
  '& .selectPage': {
    alignItems: 'center',
    display: 'flex',
    gap: 9
  }
}))

const ModulesList = ({
  modules,
  page,
  setPage,
  perPage,
  setPerPage,
  isSelectable
}: any) => {
  const moduleListElement = useRef<HTMLDivElement | null>(null)
  const dispatch = useAppDispatch()
  const { modulesSelected } = useAppSelector(state => state.books)

  useEffect(() => {
    setPage(1)
    dispatch(modulesItemsFetchRequest({ per: perPage, page: 1 }))
  }, [perPage])

  const handleGetModulesItems = (params: object) => {
    dispatch(modulesItemsFetchRequest(params))
  }

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
    handleGetModulesItems({ page: value })
    !isSelectable && scrollToTop()
    setTimeout(() => {
      isSelectable && moduleListElement.current && moduleListElement.current.scrollIntoView({ behavior: 'smooth' })
    }, 400)
  }

  const handleChangePerPage: React.ChangeEventHandler<HTMLInputElement> = event => {
    setPerPage(Number(event.target.value))
  }

  const handleViewContents = (id: number) => {
    dispatch(fetchModuleByIdRequest({ id: id }))
  }

  const addModulesInChapter = (moduleId: number, moduleName: string, numberOfItem: number) => {
    if (!modulesSelected?.some((module) => module.id === moduleId)) {
      dispatch(setModules([
        ...modulesSelected,
        {
          id: moduleId,
          name: moduleName,
          order: modulesSelected.length + 1,
          module_items_count: numberOfItem
        }]
      ))
    } else {
      const moduleRemoved = modulesSelected.filter((module) => module.id !== moduleId)
      dispatch(setModules(
        moduleRemoved.map((module, index) => ({
          id: module.id,
          name: module.name,
          order: index + 1,
          module_items_count: numberOfItem
        }))
      ))
    }
  }

  const addAllModulesInChapter = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const allModules = modules.items?.map((module: any, index: number) => ({
        id: module.id,
        name: module.name,
        order: index + 1
      }))
      dispatch(setModules(allModules))
    } else {
      dispatch(setModules([]))
    }
  }

  if (modules.isFetching) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '16px' }}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <>
      <HeaderList ref={moduleListElement}>
        <Typography component='p' variant='body2'>{modules.pagination?.total} módulo(s) listado(s)</Typography>
        {isSelectable && (
          <CheckboxInput
            name='select_all_modules'
            label='Selecionar todas os módulos'
            labelPlacement='end'
            onChange={addAllModulesInChapter}
            checked={modules.items?.every((item1: any) =>
              modulesSelected?.some((item2: any) =>
                item1.id === item2.id
              )
            )}
          />
        )}
        <div className='selectPage'>
          <Typography component='div' variant='body2'>Exibir:</Typography>
          <SelectInput
            name='selectPage'
            defaultValue={perPage}
            onChange={handleChangePerPage}
            width='150px'
            options={[
              { label: '1 por página', value: 1 },
              { label: '5 por página', value: 5 },
              { label: '10 por página', value: 10 },
              { label: '15 por página', value: 15 },
            ]}
          />
        </div>
      </HeaderList>
      <Box>
        <Typography
          sx={{
            color: '#f69e9e !important',
            fontSize: '12px',
            marginBottom: '16px'
          }}
        >
          Selecione pelo menos um item para avançar. *
        </Typography>
      </Box>
      {
        modules?.items && !isEmpty(modules.items) ? modules.items.map((module: IModule) => {
          return (
            <Box key={module.id} sx={{ marginBottom: '16px', border: '1px solid #d9d9d9', borderRadius: '16px', overflow: 'hidden' }}>
              <ModuleCard
                module={module}
                importModule={importModuleRequest}
                importModuleId={modules.importModule.importModuleId}
                isImporting={modules.importModule.isImporting}
                handleViewContents={handleViewContents}
                currentItem={modules.currentItem}
                clearCurrentItem={clearCurrentItem}
                isSelectable={isSelectable}
                selectModuleCheck={
                  isSelectable && (
                    <CheckButton
                      value={module.id}
                      label='Adicionar'
                      onChange={() => addModulesInChapter(module?.id, module.name, module.content_module_items_count)}
                      checked={modulesSelected?.some(item => item.id === module.id)}
                    />
                  )
                }
              />
            </Box>
          )
        })
          : modules?.items && isEmpty(modules.items) && Object.keys(modules.filters.query).length > 2
            ? (
              <NoItemsCard>
                <NoOptionsImage />
                <h3>Nenhum módulo encontrado.</h3>
              </NoItemsCard>
            ) : (
              <NoItemsCard>
                <NoOptionsImage />
                <h3>Você ainda não possui módulo listados</h3>
                <p>Para listar módulos preencha os <span>filtros de pesquisa</span> no canto esquerdo superior</p>
              </NoItemsCard>
            )
      }
      {!modules.isFetching && modules.pagination &&
        modules.pagination?.totalOfPages > 1 && (
          <Pagination
            count={modules.pagination?.totalOfPages}
            size='small'
            color='primary'
            page={page}
            onChange={handleChangePage}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          />
        )}
    </>
  )
}

export default ModulesList
