// React
import React, { useEffect, useState } from 'react'

// Components
import {
  Box,
  Collapse,
  IconButton,
  Typography
} from '@mui/material'
import ModuleItem from './ModuleItem'
import SimpleDatePicker from '../../input/SimpleDatePicker'

// Utils
import { Dates, IClassroomChapters } from '../../../models/IBooks'
import { formattedDate } from '../../../utils/functions'

// Icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

// Styles
import {
  ClassroomItemContainer,
  Container,
  FormBox,
  ModuleContent,
  SchoolName
} from './styles/ClassroomItem.styles'

interface ClassroomItemProps {
  data: IClassroomChapters
  chapterIndex: number
  chapterId: number
  dates: Dates[]
  changeChapterDate: (chapterId: number, classroomChapterId: number, key: any, value: Date) => void
  changeModuleDate: (chapterId: number, classroomChapterId: number, moduleId: number, key: any, value: Date) => void
  isEdit: boolean
}

const ClassroomItem: React.FC<ClassroomItemProps> = ({
  data,
  chapterIndex,
  chapterId,
  dates,
  changeChapterDate,
  changeModuleDate,
  isEdit
}) => {
  const [openClassroom, setOpenClassroom] = useState(false)
  const [date, setDate] = useState({
    start_date: data.start_date ? new Date(data.start_date) : undefined,
    end_date: data.end_date ? new Date(data.end_date) : undefined
  })

  const chapterDate = dates?.find((date) => date.chapterId === chapterId)
  const classroomChapterDate = chapterDate?.classroom_chapters?.find((item: any) => item.id === data.id)
  const startDate = formattedDate(classroomChapterDate?.start_date)
  const endDate = formattedDate(classroomChapterDate?.end_date)

  const handleChangeDate = (chapterId: number, classroomChapterId: number, key: any, value: Date) => {
    setDate({
      ...date,
      [key]: value
    })
    changeChapterDate(chapterId, classroomChapterId, key, value)
  }

  const filterRangeStartDate = (time: any) => {
    const selectedDate = String(date.end_date)
    const selectDateTime = new Date(selectedDate)
    return time < selectDateTime.getTime()
  }

  const filterRangeEndDate = (time: any) => {
    const selectedDate = String(date.start_date)
    const selectedDateTime = new Date(selectedDate)
    return time > selectedDateTime.getTime()
  }

  useEffect(() => {
    chapterIndex === 0 && setOpenClassroom(true)
  }, [])

  return (
    <ClassroomItemContainer>
      <Container>
        <Box className='col-1'>
          <SchoolName
            component='span'
            onClick={() => setOpenClassroom(prevState => !prevState)}
          >
            {data.school_classroom.name}
          </SchoolName>
        </Box>

        <Box className='col-2'>
          <Typography className='label'>
            Defina a data para essa turma:
          </Typography>

          <FormBox>
            <Box className='field'>
              <Typography className='field_label'>Início:</Typography>

              <Box className='field_datepicker'>
                <SimpleDatePicker
                  name='start_date'
                  placeholder='dd/mm/aaaa'
                  autoComplete='off'
                  locale='pt-BR'
                  selectsStart
                  value={startDate}
                  startDate={date?.start_date}
                  endDate={date?.end_date}
                  minDate={!isEdit && !date?.start_date && startDate}
                  maxDate={date?.end_date ?? endDate}
                  changeDate={(value: any) =>
                    handleChangeDate(
                      chapterId,
                      data.id,
                      'start_date',
                      value
                    )}
                  filterTime={date?.end_date && filterRangeStartDate}
                  readOnly
                />
              </Box>
            </Box>

            <Box className='field'>
              <Typography className='field_label'>
                Fim:
              </Typography>

              <Box className='field_datepicker'>
                <SimpleDatePicker
                  name='end_date'
                  placeholder='dd/mm/aaaa'
                  autoComplete='off'
                  locale='pt-BR'
                  selectsEnd
                  value={endDate}
                  startDate={date?.start_date}
                  endDate={date?.end_date}
                  minDate={date?.start_date ?? startDate}
                  maxDate={!isEdit && !date?.end_date && endDate}
                  changeDate={(value: any) =>
                    handleChangeDate(
                      chapterId,
                      data.id,
                      'end_date',
                      value
                    )}
                  filterTime={filterRangeEndDate}
                  readOnly
                />
              </Box>
            </Box>
          </FormBox>
        </Box>

        <Box className='col-3'>
          <IconButton
            onClick={() => setOpenClassroom(prevState => !prevState)}
            className='collapse_btn'
          >
            <KeyboardArrowDownIcon
              fontSize='small'
              sx={{ transform: openClassroom ? 'rotate(180deg)' : 'rotate(0deg)' }}
            />
          </IconButton>
        </Box>
      </Container>

      <Collapse in={openClassroom}>
        <ModuleContent>
          <Box className='module_container'>
            <Typography className='label'>
              Defina as datas de início e fim dos módulos para essa turma
            </Typography>

            <Box className='module_items'>
              {data?.classroom_modules?.map((module) => {
                return (
                  <Box key={module.id}>
                    <ModuleItem
                      key={module.id}
                      module={module}
                      chapterId={chapterId}
                      classroomChapterId={data.id}
                      dates={dates}
                      changeModuleDate={changeModuleDate}
                      classroomChapterDates={date}
                      dateLim={{
                        start_date: startDate,
                        end_date: endDate
                      }}
                      enableDateField={!!(startDate && endDate)}
                    />
                  </Box>
                )
              })}
            </Box>
          </Box>
        </ModuleContent>
      </Collapse>
    </ClassroomItemContainer>
  )
}

export default ClassroomItem
