import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Components
import { Box, Grid, MenuItem, Skeleton, Typography } from '@mui/material'
import AuthorsCollapse from '../components/Books/collapse/AuthorsCollapse'
import Button from '../components/button/Button'
import ErrorMessage from '../components/message/ErrorMessage'

// Redux
import { clearAuthors, clearCoauthors, fetchAuthorsRequest, fetchCoauthorsRequest } from '../store/books/actions'
import { useAppDispatch, useAppSelector } from '../store/hooks'

// Icons and images
import BookIcon from '../assets/components/Books/book-icon.svg'
import { ReactComponent as OrderByIcon } from '../assets/components/Books/orderby-icon.svg'

// Styles
import {
  BtnContainer,
  Container,
  Header,
  MenuStyle,
  OrderByBtn,
  PaperContainer,
  TableHeader
} from './styles/BooksAuthorsContainer.styles'

interface IBooksAuthorsContainerProps {
  type?: 'authors' | 'coauthors'
}

const BooksAuthorsContainer: React.FC<IBooksAuthorsContainerProps> = ({ type }) => {
  const navigate = useNavigate()
  const [anchorOrderByEl, setAnchorOrderByEl] = useState<HTMLElement | null>(null)
  const openOrderBy = Boolean(anchorOrderByEl)
  const dispatch = useAppDispatch()

  const { selectedUnit } = useAppSelector(state => state.persistable)
  const {
    book_authors: authors,
    book_coauthors: coauthors
  } = useAppSelector(state => state.books)

  const data = type === 'authors' ? authors : coauthors

  const handleFetchAuthors = (params?: object) => {
    dispatch(fetchAuthorsRequest({
      school_unit_ids: selectedUnit?.id,
      ...params
    }))
  }

  const handleFetchCoauthors = (params?: object) => {
    dispatch(fetchCoauthorsRequest({
      school_unit_ids: selectedUnit?.id,
      ...params
    }))
  }

  const handleOpenOrderBy = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorOrderByEl(event.currentTarget)
  }

  const handleCloseOrderBy = (event: any) => {
    const value = event.currentTarget.id
    setAnchorOrderByEl(null)
    value !== '' && type === 'authors' && handleFetchAuthors({ [value]: true })
    value !== '' && type === 'coauthors' && handleFetchCoauthors({ [value]: true })
  }

  const handleBackPage = () => {
    navigate('/books/reports')
  }

  useEffect(() => {
    type === 'authors' && handleFetchAuthors()
    type === 'coauthors' && handleFetchCoauthors()

    return () => {
      type === 'authors' && dispatch(clearAuthors())
      type === 'coauthors' && dispatch(clearCoauthors())
    }
  }, [selectedUnit])

  return (
    <Box>
      <Header>
        <Box className='label'>
          <img src={BookIcon} />
          <Typography component='h2'>
            Livros / {type === 'authors' ? 'Autores' : 'Coautores'}
          </Typography>
        </Box>

        <Box>
          <Box>
            <OrderByBtn
              id='orderBy'
              aria-controls={openOrderBy ? 'basic-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={openOrderBy ? 'true' : undefined}
              onClick={handleOpenOrderBy}
              variant='outlined'
            >
              <OrderByIcon />{' Ordenar por'}
            </OrderByBtn>
            <MenuStyle
              id='orderByMenu'
              anchorEl={anchorOrderByEl}
              open={openOrderBy}
              onClose={handleCloseOrderBy}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={handleCloseOrderBy} id='more_created'>Mais criaram</MenuItem>
              <MenuItem onClick={handleCloseOrderBy} id='less_created'>Menos criaram</MenuItem>
              <MenuItem onClick={handleCloseOrderBy} id='more_access'>Mais acessados</MenuItem>
              <MenuItem onClick={handleCloseOrderBy} id='less_access'>Menos acessados</MenuItem>
            </MenuStyle>
          </Box>
        </Box>
      </Header>

      <PaperContainer>
        {!data.isError && (
          <TableHeader container columnSpacing={1}>
            <Grid item xs={3} className='table_header'>Autor</Grid>
            <Grid item xs={2} className='table_header'>Escola</Grid>
            <Grid item xs={3} className='table_header'>Disciplina(s)</Grid>
            <Grid item xs={2} className='table_header'>Livros</Grid>
            <Grid item xs={1} className='table_header'>Iterações</Grid>
            <Grid item xs={1} className='table_header'></Grid>
          </TableHeader>
        )}

        {data.isFetching && (
          <Container>
            {Array(7).fill(0).map((_, index) => (
              <Skeleton
                key={index}
                animation='wave'
                variant='rounded'
                sx={{
                  borderRadius: '8px',
                  height: '56px',
                  width: '100%'
                }}
              />
            ))}
          </Container>
        )}

        {!data.isFetching && !data.isError && (
          <Container>
            {data?.items?.map((item) => {
              return (
                <AuthorsCollapse
                  key={item.id}
                  authorData={item}
                  school={selectedUnit}
                  type={type}
                />
              )
            })}
          </Container>
        )}

        {data.isError && <ErrorMessage />}
      </PaperContainer>

      <BtnContainer>
        <Button
          type='button'
          onClick={handleBackPage}
        >
          Voltar
        </Button>
      </BtnContainer>
    </Box>
  )
}

export default BooksAuthorsContainer
