import React from 'react'
import ManageTeachersContainerTwo from '../containers/ManageTeachersContainer'

const ManageTeacherPage = () => {
  return (
    <>
      <ManageTeachersContainerTwo />
    </>
  )
}

export default ManageTeacherPage
