import { Box, Paper } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Header = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: '16px',
  justifyContent: 'space-between',
  marginBottom: '24px',

  '& .title': {
    alignItems: 'center',
    display: 'flex',
    gap: '16px',

    '& .primary_title': {
      color: theme.palette.text.primary,
      fontSize: '18px',
      fontWeight: 600,
    }
  }
}))

export const PaperContainer = styled(Paper)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  marginTop: '16px',
}))
