import { Box, Paper } from '@mui/material'
import { styled } from '@mui/material/styles'

export const PaperContainer = styled(Paper)(({ theme }) => ({
  borderRadius: '16px',
  marginTop: '32px',
  padding: '32px 16px',

  '& .title': {
    display: 'flex',
    gap: '8px',
    marginBottom: '24px',

    '& > h3': {
      color: '#666666',
      fontSize: '18px',
      fontWeight: 700
    }
  },

  '& .required_message-box': {
    marginBottom: '8px',

    '& .required_message': {
      color: theme.palette.error.main,
      fontSize: '12px',
    }
  },

  '& .collapse_container': {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px'
  },

  '& .error_message': {
    color: theme.palette.error.main,
    fontSize: '13px',
    marginTop: '16px'
  }
}))

export const BtnContainer = styled(Box)(() => ({
  display: 'flex',
  gap: '16px',
  justifyContent: 'flex-end',
  marginTop: '32px'
}))
