import React from 'react'

// Components
import { Box, Grid, Typography, useMediaQuery } from '@mui/material'

// Utils
import { useTheme } from '@mui/material/styles'
import { IBookRanking } from '../../../models/IBooks'
import { textExtract } from '../../../utils/functions'

// Icons and Images
import { ReactComponent as MostUsedIcon } from '../../../assets/components/Books/most-used-icon.svg'
import { ReactComponent as LeastUsedIcon } from '../../../assets/components/Books/least-used-icon.svg'

// Styles
import {
  BookContainer,
  BookCover,
  NewBadge,
  PublishedBadge,
  TagsContainer
} from './styles/BookUsedItem.styles'

interface IBookUsedProps {
  book: IBookRanking
  tag: boolean
  rankingType?: 'mostUsed' | 'lessUsed'
}

const BookUsedItem: React.FC<IBookUsedProps> = ({
  book,
  tag,
  rankingType
}) => {
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('lg'))

  const daysInterval = (createdAt?: string | Date): number => {
    if (!createdAt) {
      return 0
    }

    const dataCriacao = typeof createdAt === 'string' ? new Date(createdAt) : createdAt
    const dataAtual = new Date()

    dataCriacao.setHours(0, 0, 0, 0)
    dataAtual.setHours(0, 0, 0, 0)

    const diferencaMs = dataAtual.getTime() - dataCriacao.getTime()
    const diferencaDias = Math.floor(diferencaMs / (1000 * 60 * 60 * 24))
    return diferencaDias
  }

  return (
    <>
      <BookContainer>
        <TagsContainer>
          <PublishedBadge>
            {book.status === 'published'
              ? 'Publicado'
              : book.status === 'draft'
                ? 'Rascunho'
                : 'À publicar'
            }
          </PublishedBadge>

          {daysInterval(book.created_at) < 15 && (
            <NewBadge>Novo</NewBadge>
          )}

          {tag && rankingType === 'mostUsed' && <MostUsedIcon />}
          {tag && rankingType === 'lessUsed' && <LeastUsedIcon />}
        </TagsContainer>

        <Box className='book'>
          <BookCover>
            {book.photo_url
              ? (
                <img
                  src={book.photo_url}
                  alt='Capa do livro'
                  width='100%'
                  height='100%'
                />
              )
              : (
                <Box className='book_without-cover'>
                  <span>Livro</span>
                  <span>sem</span>
                  <span>capa</span>
                </Box>
              )}
          </BookCover>

          <Box className='book_content'>
            <Typography component='h3' className='book_content-title'>
              {book.name}
            </Typography>

            <Typography className='book_content-description'>
              {!smDown ? textExtract(book.description, 85) : textExtract(book.description, 200)}
            </Typography>

            <Grid
              container
              columnSpacing={1}
              className='book_content-info'
            >
              <Grid item xs={6}>
                <Box className='book_info'>
                  <span>Cod.:</span>
                  <span className='violet'>{book.id}</span>
                </Box>
              </Grid>

              <Grid item xs={6} className='last_item'>
                <Box
                  className='book_info subject'
                  title={book.subjects.map((subject) => subject.name).join(', ')}
                >
                  <span>Disciplina:</span>
                  <span className='subject'>
                    {book.subjects.map((subject) => subject.name).join(', ')}
                  </span>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box
                  className='book_info school'
                  title={book.units?.map(unit => unit.name).join(', ')}
                >
                  <span>Escola:</span>
                  <span className='school'>
                    {book.units?.map(unit => unit.name).join(', ')}
                  </span>
                </Box>
              </Grid>

              <Grid item xs={6} className='last_item'>
                <Box className='book_info'>
                  <span>Performance:</span>
                  <span className='green'>{book.performance ?? '00'}%</span>
                </Box>
              </Grid>

              <Grid item xs={6}></Grid>

              <Grid item xs={6} className='last_item'>
                <Box className='book_info'>
                  <span>Acessos:</span>
                  <span className='violet'>{book.access ?? '00'}</span>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </BookContainer>
    </>
  )
}

export default BookUsedItem
