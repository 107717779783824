
import React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { styled } from '@mui/material/styles'
import { Box, IconButton, Typography } from '@mui/material'
import { ReactComponent as SearchIcon } from '../../assets/components/ManagersPage/Search-icon.svg'

type Props = FieldRenderProps<string, any>

const Container = styled('div')(({ theme }) => ({
  borderRadius: '8px',

  '& .zipcode_box': {
    alignItems: 'center',
    display: 'flex',
    gap: '8px',

    '& .btn': {
      background: theme.palette.primary.main,
    },

    '& input::placeholder': {
      color: '#cecece !important',
      fontFamily: theme.typography.fontFamily
    },

    '& > input': {
      border: '1px solid #cecece',
      borderRadius: '8px',
      padding: '10.53px 32px 10.53px 18px',
      fontSize: '14px',
      width: '100%',
      height: '40px',
      color: '#9F9F9F',
    },

    '& input:focus-visible': {
      outlineColor: `${theme.palette.primary.main} !important`
    },
  },

  '& .error_message': {
    color: theme.palette.error.main,
    fontSize: '0.75rem',
    textAlign: 'left',
    fontWeight: 400
  }
}))

const CepInput: React.FC<Props> = ({
  input,
  meta: { touched, error },
  handleSearchZipCode
}) => {
  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    input.onBlur(event)
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let cepInput = event.target.value.replace(/\D/g, '')
    const formattedCep = cepInput.slice(0, 8)

    if (formattedCep.length >= 5) {
      cepInput = formattedCep.slice(0, 5) + '-' + formattedCep.slice(5)
    }
    input.onChange(cepInput)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace' && input?.value?.length === 6) {
      event.preventDefault()
      const formattedCep = input?.value?.slice(0, 4)
      input.onChange(formattedCep)
    }
  }

  return (
    <>
      <Container>
        <Box className='zipcode_box'>
          <input
            type='text'
            value={input.value}
            placeholder='00000-000'
            maxLength={9}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
          />

          <IconButton
            type='button'
            className='btn'
            onClick={handleSearchZipCode}
          >
            <SearchIcon />
          </IconButton>
        </Box>
        {touched && error && (
          <Typography className='error_message'>
            {error}
          </Typography>
        )}
      </Container>
    </>
  )
}

export default CepInput
