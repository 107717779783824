/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { keyBy, uniq, isEmpty, flatten } from 'lodash'
import { Box, CircularProgress } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { FormValues } from '../components/Activities/form/ActivitiesSettingsForm'
import ActivitiesTab from '../components/Activities/ActivitiesTab'
import { setContentSelected } from '../store/contents/actions'
import {
  setQuestionSelected,
  classroomAddInActivity,
  addErrorsForm,
  clearSelectedQuestions,
  clearSelectedClassrooms
} from '../store/questions/actions'
import { activitySaveRequest, activityUpdateRequest, activityItemByIdFetchRequest } from '../store/activities/actions'
import { IQuestion } from '../models/IQuestion'
import { IClassroom } from '../models/IClassroom'
import { IUnit } from '../models/IUnit'
import { checkCurrentRole, scrollToTop } from '../utils/functions'
import { format } from 'date-fns'
import { IContent } from '../models/IContent'

type SelectedType = {
  byId: object
  allIds: []
  classroomsIds: []
}

const ActivitiesNewContainer = () => {
  const dispatch = useAppDispatch()
  const { id } = useParams()
  const [activeTab, setActiveTab] = useState(0)
  const [activeStep, setActiveStep] = useState(1)
  const {
    activities: { item, isFetching },
    auth: { roles },
    persistable: { selectedRole, selectedUnit },
    questions,
    contents,
    filters,
    grades,
    units: { unitsWithClassrooms }
  } = useAppSelector(state => state)
  const [formValues, setFormValues] = useState({} as FormValues)
  const [formEditValues, setFormEditValues] = useState({} as FormValues)
  const [submitForm, setSubmitForm] = useState(false)
  const [submitDraftForm, setSubmitDraftForm] = useState(false)
  const navigate = useNavigate()
  const { state } = useLocation()

  useEffect(() => {
    if (id || state) dispatch(activityItemByIdFetchRequest(id || state))
      return () => {
        dispatch(clearSelectedClassrooms())
        dispatch(clearSelectedQuestions())
      }
  }, [])

  useEffect(() => {
    if ((id || state) && item && !!item?.participants && item.modules !== undefined) {
      const classroomIds: any = item?.participants?.map(x => x.school_classroom.id)
      const classroomIdsFiltered = classroomIds?.filter((x: any, i: number) => (
        classroomIds?.indexOf(x) === i
      ))

      const byId = item?.participants?.map(item => {
        return {
          user_user_id: item.user.id,
          school_classroom_id: item.school_classroom.id,
          school_classroom_ids: [item.school_classroom.id]
        }
      }).reduce((acc: any, item) => {
        acc[item.user_user_id] = item
        return acc
      }, {})

      const questionItems = item.modules[0]?.module?.content_module_items.map((item: any, index: number) => {
        return {
          id: item.content.id,
          value_item: item.value_item,
          order: index
        }
      })

      const contentItems = questions.selectedQuestions.allIds.reduce((acc: any, item: any, index: number) => {
        let question
        const hasQuestion = questionItems.find((question: any) => String(question.id) === String(item))
        if (hasQuestion) {
          question = {
            id: item,
            value_item: hasQuestion.value_item,
            order: index
          }
        }
        acc = [...acc, question]
        return acc
      }, [])

      let shuffleOption
      const chosenShuffleOption = item.variants?.shuffle_option

      switch (chosenShuffleOption) {
        case 'questions_and_options':
          shuffleOption = { name: 'Questões e itens', value: 'questions_and_options' }
          break
        case 'only_questions':
          shuffleOption = { name: 'Apenas a ordem das questões', value: 'only_questions' }
          break
        case 'only_options':
          shuffleOption = { name: 'Apenas a ordem dos itens', value: 'only_options' }
          break
        default:
          shuffleOption = { name: 'Não embaralhar', value: 'no_shuffle' }
      }

      const data = {
        title: item.title,
        description: item.description,
        education_grade_ids: item?.participants?.[0]?.school_classroom.grade ? { value: item.participants[0]?.school_classroom.grade?.id, label: item.participants[0]?.school_classroom.grade.name } : undefined,
        subjects: item.subjects,
        knowledgeArea: item.subjects ? item.subjects.map((subject: any) => (
          subject?.knowledge_area ? { value: subject.knowledge_area?.id, label: subject.knowledge_area?.name } : null
        )) : [],
        activity_type: item.activity_type === 'commom'
          ? { value: item.activity_type, name: 'Exercício' }
          : item.activity_type === 'exam'
            ? { value: item.activity_type, name: 'Prova' } : undefined,
        exam_type: item.exam_type === 'virtual'
          ? { value: item.exam_type, name: 'Virtual' }
          : item.exam_type === 'presential'
            ? { value: item.exam_type, name: 'Presencial' } : undefined,
        education_segments_id: item.segments,
        content_items: contentItems,
        value: item.value,
        content_module_type: item?.modules[0]?.module?.module_type === 'exam'
            ? { id: 1, name: 'O gabarito aparece somente na data de término da prova', value: 'exam' }
            : item?.modules[0]?.module?.module_type === 'regular'
              ? { id: 2, name: 'O gabarito aparece durante a execução da prova', value: 'regular' }
              : undefined,
      } as FormValues

      if (!state) {
        setFormEditValues({
          ...data,
          id: id,
          start_date: new Date(String(item.start_date)),
          end_date: item.end_date ? new Date(String(item.end_date)) : null,
          duration: String(item?.duration),
          shuffle_options: shuffleOption,
          number_of_versions: item.variants?.number_of_versions
            ? { name: item.variants.number_of_versions, value: item.variants.number_of_versions }
            : { name: 0, value: 0 }
        })

        dispatch(classroomAddInActivity({
          classroomsIds: classroomIdsFiltered,
          allIds: item.participants && item.participants.map(item => item.user.id),
          byId: byId
        }))
      } else {
        setFormEditValues(data)
      }
    }
  }, [item])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue)
  }

  const previous = () => {
    scrollToTop()
    if (activeTab === 0) {
      setActiveStep(2)
      if (activeStep === 1) {
        navigate(-1)
      }
    } else {
      setActiveTab(prevState => prevState !== 0 ? prevState - 1 : prevState)
    }
  }

  const removeQuestion = (id: any) => {
    const deleteFromAllIds: (string | number)[] = questions.selectedQuestions.allIds.filter(item => String(item) !== String(id))
    const deleteFromById: { [key: string | number]: any } = {}
    const deleteFromTypes: { id?: number | string, type: string }[] | undefined = questions.selectedQuestions?.types && questions.selectedQuestions?.types.filter(item => String(item.id) !== String(id))
    for (const prop in questions.selectedQuestions.byId) {
      if (String(prop) !== String(id)) {
        deleteFromById[prop] = questions.selectedQuestions.byId[prop]
      }
    }

    const selectedQuestionsCopy = {
      allIds: deleteFromAllIds,
      byId: deleteFromById,
      types: deleteFromTypes
    }

    dispatch(setQuestionSelected({
      ...selectedQuestionsCopy
    }))
  }

  const selectContent = (event: React.ChangeEvent<HTMLInputElement>, content: IContent) => {
    const itemId = content.id
    if (event.target?.checked) {
      dispatch(setContentSelected({
        ...contents.selectedContents,
        byId: {
          ...contents.selectedContents.byId,
          [itemId]: {
            content,
            id: itemId
          }
        },
        allIds: [...contents.selectedContents.allIds, itemId]
      }))
    } else {
      removeQuestion(itemId)
    }
  }

  const selectQuestion = (event: React.ChangeEvent<HTMLInputElement>, question: IQuestion) => {
    const itemId = question.content_item_id || question.id
    const item = { id: itemId, type: question.content_parents ? 'question' : 'content' }
    const items = questions.selectedQuestions.types ?? []

    if (event.target.checked) {
      dispatch(setQuestionSelected({
        ...questions.selectedQuestions,
        byId: {
          ...questions.selectedQuestions.byId,
          [itemId]: {
            question,
            id: itemId
          }
        },
        allIds: [...questions.selectedQuestions.allIds, itemId],
        types: [...items, item]
      }))
    } else {
      removeQuestion(itemId)
    }
  }

  const unselectAllItemsBasedOnType = (
    selectedQuestionsIds: Array<string | number>,
    itemsIds: Array<string | number | undefined>,
    selectedItemsById: Array<{ question: IQuestion, id: string | number }>,
    remainingSelectedItemsIds: Array<string | number>
  ) => {
    if (selectedQuestionsIds.length === itemsIds.length) {
      dispatch(setQuestionSelected({
        byId: {},
        allIds: [],
        types: []
      }))
    } else {
      dispatch(setQuestionSelected({
        byId: keyBy(selectedItemsById, 'id'),
        allIds: remainingSelectedItemsIds,
        types: selectedItemsById.map((item: any) => ({
          id: item.id,
          type: item.question.content_parents ? 'question' : 'content'
        }))
      }))
    }
  }

  const selectAllItems = (type: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (type === 'question') {
      if (event.target.checked) {
        const questionsById: Array<{ question: IQuestion, id?: string | number }> = questions.items.result?.questions.map((questionId: string | number) => ({
          question: questions.items.entities.questions[questionId],
          id: questions.items.entities.questions[questionId]?.content_item_id
        }))

        const itemsTyped = questionsById.map((item: any) => ({
          id: item.id,
          type: item.question.content_parents ? 'question' : 'content'
        }))

        const questionsTyped = [...questions.selectedQuestions.types, ...itemsTyped]
        const questionsFiltered = new Set()
        const itemsTypedFiltered = questionsTyped.filter((question: any) => {
          const duplicatedQuestion = questionsFiltered.has(question.id)
          questionsFiltered.add(question.id)
          return !duplicatedQuestion
        })

        dispatch(setQuestionSelected({
          byId: {
            ...questions.selectedQuestions.byId,
            ...keyBy(questionsById, 'question.content_item_id')
          },
          allIds: uniq([
            ...questions.selectedQuestions.allIds,
            ...questionsById.map(question => question.question.content_item_id),
          ]),
          types: itemsTypedFiltered
        }))
      } else {
        const questionsIds = Object.values(questions?.items?.entities.questions).map(
          question => question.content_item_id
        )
        const selectedQuestionsIds = questions.selectedQuestions.allIds

        const remainingSelectedQuestionsIds = selectedQuestionsIds.filter(
          selectedQuestion => !questionsIds.includes(selectedQuestion)
        )

        const selectedQuestionsById = remainingSelectedQuestionsIds.map(
          questionId => ({
            question: questions.selectedQuestions.byId[questionId].question,
            id: questionId
          })
        )

        unselectAllItemsBasedOnType(
          selectedQuestionsIds,
          questionsIds,
          selectedQuestionsById,
          remainingSelectedQuestionsIds
        )
      }
    } else {
      if (event.target.checked) {
        const contentsById = contents.items.map(content => ({
          question: content,
          id: content.id
        }))

        const itemsTyped = contentsById.map((item: any) => ({
          id: item.id,
          type: item.question.content_parents ? 'question' : 'content'
        }))

        const contentsTyped = [...questions.selectedQuestions.types, ...itemsTyped]
        const contentsFiltered = new Set()
        const itemsTypedFiltered = contentsTyped.filter((question: any) => {
          const duplicatedQuestion = contentsFiltered.has(question.id)
          contentsFiltered.add(question.id)
          return !duplicatedQuestion
        })

        dispatch(setQuestionSelected({
          byId: { ...questions.selectedQuestions.byId, ...keyBy(contentsById, 'id') },
          allIds: uniq([
            ...questions.selectedQuestions.allIds,
            ...contentsById.map(content => content.id)
          ]),
          types: itemsTypedFiltered
        }))
      } else {
        const contentsIds = contents.items.map(content => content.id)
        const selectedQuestionsIds = questions.selectedQuestions.allIds

        const remainingSelectedContentsIds = selectedQuestionsIds.filter(
          selectedQuestion => !contentsIds.includes(selectedQuestion)
        )

        const selectedContentsById = remainingSelectedContentsIds.map(
          contentId => ({
            question: questions.selectedQuestions.byId[contentId].question,
            id: contentId
          })
        )

        unselectAllItemsBasedOnType(
          selectedQuestionsIds,
          contentsIds,
          selectedContentsById,
          remainingSelectedContentsIds
        )
      }
    }
  }

  const verifyAllItemsSelected = (itemsIds: Array<number | string | undefined>) => {
    const selectedQuestionsIds = questions.selectedQuestions.allIds
    if (
      itemsIds.length > 0 &&
      (selectedQuestionsIds && selectedQuestionsIds.length > 0)
    ) {
      const isEveryItemChecked = itemsIds.every(itemId =>
        selectedQuestionsIds.find(
          selectedQuestionId => itemId === selectedQuestionId
        )
      )
      return isEveryItemChecked
    }
  }

  const isAllItemsSelected = (type: string) => {
    if (type === 'question') {
      if (questions.items.entities && !isEmpty(questions.items.entities.questions)) {
        const questionsIds = Object.values(questions.items.entities.questions).map(
          question => question.content_item_id
        )

        return verifyAllItemsSelected(questionsIds)
      }
    } else {
      if (contents) {
        const contentsIds = contents.items.map(content => content.id)

        return verifyAllItemsSelected(contentsIds)
      }
    }
  }

  const changeOrder = (value: any, questionId: number) => {
    const ids = questions.selectedQuestions.allIds.filter(item => item !== questionId)
    const types = questions.selectedQuestions.types.filter(item => item.id !== questionId)
    const typesSelected = questions.selectedQuestions.types.filter(item => item.id === questionId)
    ids.splice(value - 1, 0, questionId)
    types.splice(value - 1, 0, typesSelected[0])
    dispatch(setQuestionSelected({
      ...questions.selectedQuestions,
      allIds: [...ids],
      types: [...types]
    }))
  }

  const addActivityToStudent = (classroom: IClassroom, student: { id: number }) => {
    const selectedClassrooms: any = questions.classrooms.selected

    const schoolClassroomIds =
      (selectedClassrooms.byId[student.id] &&
        selectedClassrooms.byId[student.id].school_classroom_ids) ||
      []

    return {
      ...selectedClassrooms,
      byId: {
        ...selectedClassrooms.byId,
        [student.id]: {
          school_classroom_id: classroom.id,
          school_classroom_ids: uniq([...schoolClassroomIds, classroom.id]),
          user_user_id: student.id
        }
      },
      allIds: uniq([...selectedClassrooms.allIds, student.id])
    }
  }

  const removeActivityFromStudent = (classroom: IClassroom, student: any) => {
    const selectedClassrooms: any = questions.classrooms.selected

    const hasMoreThanOneClass =
      selectedClassrooms.byId[student.id].school_classroom_ids.length > 1

    const schoolClassroomIds = (selectedClassrooms: SelectedType | any, studentId: number) =>
      selectedClassrooms.byId[studentId].school_classroom_ids.filter(
        (id: number) => id !== classroom.id
      )

    if (hasMoreThanOneClass) {
      return {
        ...selectedClassrooms,
        byId: {
          ...selectedClassrooms.byId,
          [student.id]: {
            ...selectedClassrooms.byId[student.id],
            school_classroom_ids: schoolClassroomIds(
              selectedClassrooms,
              student.id
            )
          }
        },
        allIds: uniq([...selectedClassrooms.allIds, student.id])
      }
    } else {
      const deleteFromAllIds = uniq(
        selectedClassrooms.allIds.filter((item: any) => item !== student.id)
      )
      const deleteFromById: any = {}
      for (const prop in selectedClassrooms.byId) {
        if (Number(prop) !== student.id) {
          deleteFromById[prop] = selectedClassrooms.byId[prop]
        }
      }
      const selectedClassroomsCopy = {
        allIds: deleteFromAllIds,
        byId: deleteFromById
      }

      return { ...selectedClassroomsCopy }
    }
  }


  const toggleCheckboxClassroom = (
    event: React.ChangeEvent<HTMLInputElement>,
    classroom: IClassroom,
    student: any
  ) => {
    if (event.target.checked) {
      const classroomsInActivity = addActivityToStudent(classroom, student)
      dispatch(classroomAddInActivity(classroomsInActivity))
    } else {
      const classroomsInActivity = removeActivityFromStudent(
        classroom,
        student
      )
      dispatch(classroomAddInActivity(classroomsInActivity))
    }
  }

  const toggleCheckboxAllStudents = (
    event: React.ChangeEvent<HTMLInputElement>,
    classroom: IClassroom
  ) => {
    const selectedClassrooms = questions.classrooms.selected
    type ReduceReturnType = {
      allIds: Array<string | number>,
      byId: object,
      classroomsIds: Array<string | number>
    }

    const checkAll = (updateFunction: any) =>
      classroom.students.reduce<ReduceReturnType>((acc, currentStudent) => {
        const newOne = updateFunction(classroom, currentStudent)
        const classroomsIds = uniq(acc.classroomsIds) || []
        let byId = acc.byId || {}
        const studentSelected = newOne.byId[currentStudent.id]
          ? {
            [currentStudent.id]: {
              ...newOne.byId[currentStudent.id]
            }
          }
          : {}

        const findNewOneIndex = newOne.allIds.findIndex(
          (id: number) => id === currentStudent.id
        )
        const newOneAllIds =
          newOne.allIds?.length > 0 && newOne.allIds[findNewOneIndex]
            ? [newOne.allIds[findNewOneIndex]]
            : []

        let allIds = acc.allIds || []

        if (!newOne.byId[currentStudent.id]) {
          const deleteFromById: any = {}
          for (const prop in acc.byId) {
            if (Number(prop) !== currentStudent.id) {
              deleteFromById[prop] = acc.byId[prop]
            }
          }
          byId = deleteFromById
          allIds = allIds.filter(id => currentStudent.id !== id)
        }

        return {
          classroomsIds: uniq([...classroomsIds]),
          byId: {
            ...byId,
            ...studentSelected
          },
          allIds: uniq([...allIds, ...newOneAllIds])
        }
      }, selectedClassrooms)

    if (event.target.checked) {
      const res = checkAll(addActivityToStudent)
      dispatch(classroomAddInActivity({
        ...res,
        classroomsIds: uniq([...res.classroomsIds, classroom.id])
      }))
    } else {
      const res = checkAll(removeActivityFromStudent)

      dispatch(classroomAddInActivity({
        ...res,
        classroomsIds: uniq(
          res.classroomsIds.filter(classroomId => classroomId !== classroom.id)
        )
      }))
    }
  }

  const normalizeSelectedClassrooms = (selectClassrooms: SelectedType | any) =>
    flatten(
      selectClassrooms.allIds.map((id: number) =>
        selectClassrooms.byId[id].school_classroom_ids.map((classroomId: number) => ({
          school_classroom_id: classroomId,
          user_user_id: selectClassrooms.byId[id].user_user_id
        }))
      )
    )

  const normalizeSelectedUnitsWithClassrooms = (selectedUnitsWithClassrooms: IUnit[]) =>
    flatten(
      selectedUnitsWithClassrooms.map((unit: { id: number, classrooms: IClassroom[] }) =>
        unit.classrooms.map(classroom => {
          return {
            school_unit_id: unit.id,
            school_classroom_id: classroom.id
          }
        })
      )
    )


  const validateForm = (params: FormValues) => { // eslint-disable-line
    const selectedClassrooms = questions.classrooms.selected
    let errors: string[] = []
    if (params === undefined) {
      errors = [
        ...errors,
        'Título',
        'Descrição',
        'Questões',
        'Data de início',
        'Disciplina',
        'Turmas'
      ]
    } else {
      if (isEmpty(params.title)) {
        errors = [...errors, 'Título']
      }
      if (params.title && params.title.length > 105) {
        errors = [...errors, 'Título deve ter no máximo 105 caracteres']
      }
      if (isEmpty(params.description)) {
        errors = [...errors, 'Descrição']
      }
      if (params.description && params.description.length > 255) {
        errors = [...errors, 'Descrição deve no máximo 255 caracteres']
      }
      if (isEmpty(params.exam_type)) {
        errors = [...errors, 'Tipo de prova']
      }
      if (!params.start_date) {
        errors = [...errors, 'Data de início']
      }
      if (questions.selectedQuestions.allIds.length === 0) {
        errors = [...errors, 'Selecione pelo menos uma questão ou um conteúdo']
      }
      if (
        params.subjects === undefined ||
        params.subjects.length === 0
      ) {
        errors = [...errors, 'Disciplina']
      }
      if (
        checkCurrentRole(selectedRole, 'teacher') &&
        selectedClassrooms.allIds.length === 0
      ) {
        errors = [...errors, 'Selecione pelo menos um aluno']
      }
      if (
        !checkCurrentRole(selectedRole, 'teacher') &&
        (!unitsWithClassrooms.selectedUnitsWithClassrooms ||
          unitsWithClassrooms.selectedUnitsWithClassrooms.length === 0)
      ) {
        errors = [...errors, 'Selecione pelo menos uma turma']
      }

      if (!params.content_module_type) {
        errors = [...errors, 'Selecione um tipo de correção']
      }
    }

    dispatch(addErrorsForm([...errors]))
    return errors.length > 0
  }

  const submitActivity = (isDraft = false) => {
    const selectedClassrooms = questions.classrooms.selected
    let normalizedValues

    if (id) {
      normalizedValues = {
        ...formEditValues,
        duration: Number(formEditValues.duration),
        content_module_type: formEditValues.content_module_type?.value,
        shuffle_options: formEditValues.shuffle_options?.value,
        number_of_versions: formEditValues.number_of_versions?.value,
        activity_type: formEditValues.activity_type?.value,
        exam_type: formEditValues.exam_type?.value,
        start_date: formEditValues?.start_date && format(formEditValues.start_date, 'dd/MM/yyyy HH:mm'),
        end_date: formEditValues?.end_date && format(formEditValues.end_date, 'dd/MM/yyyy HH:mm'),
        education_segments_id: formEditValues?.education_segments_id && formEditValues.education_segments_id.id,
        education_subject_ids: formEditValues.subjects && formEditValues.subjects.map(
          subject => subject.id
        ),
        lms_activity_participants: roles.some((role: any) => role.name === 'teacher')
          ? normalizeSelectedClassrooms(selectedClassrooms)
          : normalizeSelectedUnitsWithClassrooms(
                unitsWithClassrooms.selectedUnitsWithClassrooms
            )
      }
    } else {
      normalizedValues = {
        ...formValues,
        duration: Number(formValues.duration),
        content_module_type: formValues.content_module_type?.value,
        shuffle_options: formValues.shuffle_options?.value,
        number_of_versions: formValues.number_of_versions?.value,
        activity_type: formValues.activity_type?.value,
        exam_type: formValues.exam_type?.value,
        start_date: formValues.start_date && format(formValues.start_date, 'dd/MM/yyyy HH:mm'),
        end_date: formValues.end_date && format(formValues.end_date, 'dd/MM/yyyy HH:mm'),
        education_subject_ids: formValues.subjects && formValues.subjects.map(
          subject => subject.id
        ),
        education_segments_id: formValues?.education_segments_id && formValues.education_segments_id.id,
        lms_activity_participants: roles.some((role: any) => role.name === 'teacher')
          ? normalizeSelectedClassrooms(selectedClassrooms)
          : normalizeSelectedUnitsWithClassrooms(
                unitsWithClassrooms.selectedUnitsWithClassrooms
            )
      }
    }

    if (!checkCurrentRole(selectedRole, 'teacher')) {
      normalizedValues.education_grade_ids = grades.gradesWithClassrooms && grades.gradesWithClassrooms.map(
        (grade: { value: number }) => grade.value
      )
    }

    delete normalizedValues.subjects
    delete normalizedValues.questionsValue

    const activity = {
      ...normalizedValues,
      draft: isDraft
    }

    if (id) {
      dispatch(activityUpdateRequest({ id, activity }))
    } else {
      dispatch(activitySaveRequest({ activity }))
    }
  }

  useEffect(() => {
    submitForm && submitActivity()
  }, [formValues, formEditValues, submitForm])

  useEffect(() => {
    submitDraftForm && submitActivity(true)
  }, [formValues, formEditValues, submitDraftForm])

  const handleDraftSubmit = (values: any) => {
    if (values && values?.id) {
      setFormEditValues(prevState => ({ ...prevState, ...values }))
    } else {
      setFormValues(prevState => ({ ...prevState, ...values }))
    }
    setSubmitDraftForm(true)
  }

  const handleSubmit = (values: any) => {
    if (!values?.id) {
      setFormValues(prevState => ({ ...prevState, ...values }))
    } else {
      setFormEditValues(prevState => ({ ...prevState, ...values }))
    }
    const isLastPage = activeTab === 2
    if (isLastPage) {
      setSubmitForm(true)
    } else {
      setActiveTab(activeTab + 1)
      scrollToTop()
    }
  }

  return (
    <Box sx={{ mt: 4 }}>
      {isFetching && (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '16px' }}>
          <CircularProgress />
        </Box>
      )}

      {!isFetching && (
        <ActivitiesTab
          activeTab={activeTab}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          changeOrder={changeOrder}
          formEditValues={formEditValues}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          handleDraftSubmit={handleDraftSubmit}
          isAllItemsSelected={isAllItemsSelected}
          isItemSelected={questions.selectedQuestions.allIds.length > 0}
          isClassroomSelected={questions.classrooms.selected.allIds?.length > 0}
          previous={previous}
          removeQuestion={removeQuestion}
          selectAllItems={selectAllItems}
          selectQuestion={selectQuestion}
          selectContent={selectContent}
          submitActivity={submitActivity}
          submitDraftForm={submitDraftForm}
          submitForm={submitForm}
          toggleCheckboxAllStudents={toggleCheckboxAllStudents}
          toggleCheckboxClassroom={toggleCheckboxClassroom}
          unitId={selectedUnit?.id}
          filters={filters}
        />
      )}
    </Box>
  )
}

export default ActivitiesNewContainer
