import React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { ReactComponent as CalendarIcon } from '../../../assets/components/ManagersPage/calendar-icon.svg'
import { styled, useTheme } from '@mui/material/styles'
import { Typography } from '@mui/material'

type Props = FieldRenderProps<string, any>

const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '8px',
  'input::placeholder': {
    color: '#cecece !important',
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px'
  },
  '> input': {
    border: '1px solid #cecece',
    borderRadius: '8px',
    padding: '10.53px 32px 10.53px 38px',
    fontSize: '14px',
    width: '100%',
    height: '40px',
    color: '#9F9F9F',

  },
  '& input:focus-visible': {
    outlineColor: `${theme.palette.primary.main} !important`
  }
}))

const DateInput: React.FC<Props> = ({
  input,
  meta: { touched, error },
}) => {
  const theme = useTheme()

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    input.onBlur(event)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let dateInput = event.target.value.replace(/\D/g, '')
    if (dateInput.length > 8) {
      dateInput = dateInput.slice(0, 8)
    }
    let formattedDate = ''
    if (dateInput.length > 4) {
      formattedDate = `${dateInput.slice(0, 2)}/${dateInput.slice(2, 4)}/${dateInput.slice(4)}`
    } else if (dateInput.length > 2) {
      formattedDate = `${dateInput.slice(0, 2)}/${dateInput.slice(2)}`
    } else {
      formattedDate = dateInput
    }

    input.onChange(formattedDate)
  }

  return (
    <>
      <Container>
        <input
          type='text'
          value={input.value}
          placeholder='dd / mm / aaaa'
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <div style={{ position: 'absolute', top: '50%', left: '14px', transform: 'translateY(-50%)' }}>
          <CalendarIcon style={{ color: 'gray', fontSize: '20px' }} />
        </div>
      </Container>
      {touched && error && (
        <Typography
          sx={{
            color: theme.palette.error.main,
            fontSize: '12px',
            marginTop: '-8px',
            textAlign: 'left'
          }}
        >
          {error}
        </Typography>
      )}
    </>
  )
}

export default DateInput
