import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import DialogModal from '../../Modal/DialogModal'
import { format } from 'date-fns'
import { checkGenre, cpfFormatter } from '../../../utils/functions'
import { ClassroomStudent } from '../../../models/IClassroom'
import { ReactComponent as DataIcon } from '../../../assets/components/ManagersPage/data-icon.svg'
import { ModalContainer } from './styles/StudentInfoModal.styles'

interface IStudentInfoModalProps {
  student: ClassroomStudent
  openModal: boolean
  closeModal: () => void
}

const StudentInfoModal: React.FC<IStudentInfoModalProps> = ({
  student,
  openModal,
  closeModal
}) => {
  const formatDate = (date: string) => {
    if (date) {
      const dateSplited = date.split('-')
      return `${dateSplited[2]}/${dateSplited[1]}/${dateSplited[0]}`
    } else {
      return undefined
    }
  }

  return (
    <>
      <DialogModal
        open={openModal}
        handleClose={closeModal}
        width='1150px'
        height='650px'
      >
        <ModalContainer>
          <Box className='title'>
            <DataIcon />
            <Typography component='h2'>Dados cadastrais</Typography>
          </Box>

          <Typography
            component='h3'
            className='primary_label'
          >
            Aluno
          </Typography>

          <Box className='form_data'>
            <Typography
              component='h4'
              className='secondary_label'
            >
              Dados pessoais
            </Typography>

            <Grid container rowSpacing={4} columnSpacing={3}>
              <Grid item xs={6} md={3} className='grid_item'>
                <Box className='data_item'>
                  <Box
                    component='span'
                    className='label'
                  >
                    Nome do aluno
                  </Box>

                  <Box
                    component='span'
                    className='value'
                  >
                    {student?.name ?? '-'}
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={6} md={3} className='grid_item'>
                <Box className='data_item'>
                  <Box
                    component='span'
                    className='label'
                  >
                    Matrícula
                  </Box>

                  <Box
                    component='span'
                    className='value'
                  >
                    {student?.registration ?? '-'}
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={6} md={3} className='grid_item'>
                <Box className='data_item'>
                  <Box
                    component='span'
                    className='label'
                  >
                    Primeiro acesso
                  </Box>

                  <Box
                    component='span'
                    className='value'
                  >
                    {student?.first_access ? 'Pendente' : 'Realizado'}
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={6} md={3} className='grid_item'>
                <Box className='data_item'>
                  <Box
                    component='span'
                    className='label'
                  >
                    Fractal ID
                  </Box>

                  <Box
                    component='span'
                    className='value'
                  >
                    {student?.fractal_id ?? '-'}
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={6} md={3} className='grid_item'>
                <Box className='data_item'>
                  <Box
                    component='span'
                    className='label'
                  >
                    Data de nascimento
                  </Box>

                  <Box
                    component='span'
                    className='value'
                  >
                    {formatDate(student?.birthday) ?? '--/--/----'}
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={6} md={3} className='grid_item'>
                <Box className='data_item'>
                  <Box
                    component='span'
                    className='label'
                  >
                    Sexo biológico
                  </Box>

                  <Box
                    component='span'
                    className='value'
                  >
                    {checkGenre(student?.genre)}
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={6} md={3} className='grid_item'>
                <Box className='data_item'>
                  <Box
                    component='span'
                    className='label'
                  >
                    E-mail
                  </Box>

                  <Box
                    component='span'
                    className='value'
                  >
                    {student?.email ?? '-'}
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={6} md={3} className='grid_item'>
                <Box className='data_item'>
                  <Box
                    component='span'
                    className='label'
                  >
                    Contato
                  </Box>

                  <Box
                    component='span'
                    className='value'
                  >
                    {student?.phone ?? '-'}
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={6} md={3} className='grid_item'>
                <Box className='data_item'>
                  <Box
                    component='span'
                    className='label'
                  >
                    Série
                  </Box>

                  <Box
                    component='span'
                    className='value'
                  >
                    {student?.grade_name ?? '-'}
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={6} md={3} className='grid_item'>
                <Box className='data_item'>
                  <Box
                    component='span'
                    className='label'
                  >
                    Turma
                  </Box>

                  <Box
                    component='span'
                    className='value'
                  >
                    {student?.classroom_name ?? '-'}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box className='additional_data-form'>
            <Typography
              component='h4'
              className='secondary_label'
            >
              Endereço
            </Typography>

            <Grid container rowSpacing={4} columnSpacing={3}>
              <Grid item xs={3}>
                <Box className='data_item'>
                  <Box
                    component='span'
                    className='label'
                  >
                    CEP
                  </Box>

                  <Box
                    component='span'
                    className='value'
                  >
                    {student?.additional_data?.address?.zipcode ?? '-'}
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={9}>
                <Box className='data_item'>
                  <Box
                    component='span'
                    className='label'
                  >
                    Rua
                  </Box>

                  <Box
                    component='span'
                    className='value'
                  >
                    {student?.additional_data?.address?.street ?? '-'}
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={3}>
                <Box className='data_item'>
                  <Box
                    component='span'
                    className='label'
                  >
                    Bairro
                  </Box>

                  <Box
                    component='span'
                    className='value'
                  >
                    {student?.additional_data?.address?.neighborhood ?? '-'}
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={3}>
                <Box className='data_item'>
                  <Box
                    component='span'
                    className='label'
                  >
                    Número
                  </Box>

                  <Box
                    component='span'
                    className='value'
                  >
                    {student?.additional_data?.address?.number ?? '-'}
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box className='data_item'>
                  <Box
                    component='span'
                    className='label'
                  >
                    Complemento
                  </Box>

                  <Box
                    component='span'
                    className='value'
                  >
                    {student?.additional_data?.address?.complement ?? '-'}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>

          {student?.parents?.length > 0 && (
            <Box className='parents'>
              <Typography
                component='h3'
                className='primary_label'
              >
                Responsável
              </Typography>

              <Box className='parents_container'>
                {student?.parents?.map((parent, index) => (
                  <Box className='parent_form' key={parent?.fractal_id}>
                    <Grid container columnSpacing={2} rowSpacing={2}>
                      <Grid item xs={6} lg={4}>
                        <Box className='data_item-light'>
                          <Box className='label_light'>
                            Nome do responsável {index + 1}
                          </Box>

                          <Box className='value_light'>
                            {parent?.name ?? '-'}
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={3} lg={2}>
                        <Box className='data_item-light'>
                          <Box className='label_light'>
                            CPF
                          </Box>

                          <Box className='value_light'>
                            {parent?.taxpayer_number ? cpfFormatter(parent?.taxpayer_number) : '-'}
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={3} lg={2}>
                        <Box className='data_item-light'>
                          <Box className='label_light'>
                            Data de nascimento
                          </Box>

                          <Box className='value_light'>
                            {parent?.birthday ? format(new Date(parent?.birthday), 'dd/MM/yyyy') : '--/--/----'}
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={6} lg={2}>
                        <Box className='data_item-light'>
                          <Box className='label_light'>
                            E-mail
                          </Box>

                          <Box className='value_light'>
                            {parent?.email ?? '-'}
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={6} lg={2}>
                        <Box className='data_item-light'>
                          <Box className='label_light'>
                            Contato
                          </Box>

                          <Box className='value_light'>
                            {parent?.phone ?? '-'}
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>

                    <Typography
                      component='h4'
                      className='secondary_label-light'
                    >
                      Endereço
                    </Typography>

                    <Grid container columnSpacing={2} rowSpacing={2}>
                      <Grid item xs={4} lg={4}>
                        <Box className='data_item-light'>
                          <Box className='label_light'>
                            CEP
                          </Box>

                          <Box className='value_light'>
                            {parent?.additional_data?.address?.zipcode ?? '-'}
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={8} lg={4}>
                        <Box className='data_item-light'>
                          <Box className='label_light'>
                            Rua
                          </Box>

                          <Box className='value_light'>
                            {parent?.additional_data?.address?.street ?? '-'}
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={4} lg={4}>
                        <Box className='data_item-light'>
                          <Box className='label_light'>Número</Box>
                          <Box className='value_light'>
                            {parent?.additional_data?.address?.number}
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={4} lg={4}>
                        <Box className='data_item-light'>
                          <Box className='label_light'>Bairro</Box>
                          <Box className='value_light'>
                            {parent?.additional_data?.address?.neighborhood ?? '-'}
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={4} lg={8}>
                        <Box className='data_item-light'>
                          <Box className='label_light'>Complemento</Box>
                          <Box className='value_light'>
                            {parent?.additional_data?.address?.complement ?? '-'}
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </ModalContainer>
      </DialogModal>
    </>
  )
}

export default StudentInfoModal
