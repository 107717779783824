import React, { useState } from 'react'
import { Box, Button, Collapse, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { IContentModuleItems } from '../../models/IActivities'
import { ReactComponent as BarIcon } from '../../assets/components/activity/barcode-icon.svg'
import { ReactComponent as TagIcon } from '../../assets/components/activity/tag-icon.svg'
import { ReactComponent as ViewIcon } from '../../assets/components/activity/view-icon.svg'

const QuestionContainer = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.text.secondary}`,
  borderRadius: '16px',
  position: 'relative',

  '& .question_number': {
    alignItems: 'center',
    background: theme.palette.primary.light,
    borderRadius: '50%',
    color: '#fff',
    display: 'flex',
    fontSize: '24px',
    fontWeight: 600,
    height: '40px',
    justifyContent: 'center',
    left: '25px',
    position: 'absolute',
    top: '-20px',
    width: '40px',
  },

  '& .question': {
    padding: '40px 24px',

    '& .question_statement > div, p': {
      color: theme.palette.text.primary,
      fontWeight: 500,
    },

    '& .question_options': {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
      marginTop: '24px',

      '& .option': {
        alignItems: 'center',
        color: theme.palette.text.primary,
        display: 'flex',
        fontWeight: 500,
        gap: '8px',
        width: 'fit-content',

        '& > div > p': {
          margin: 0
        },

        '&.is_correct': {
          background: '#daffb2',
          borderRadius: '4px',
          padding: '2px 4px',
        }
      }
    }
  },

  '& .question_info': {
    alignItems: 'center',
    background: '#f5f5f5',
    borderTop: '1px solid #d9d9d9',
    borderBottomLeftRadius: '16px',
    borderBottomRightRadius: '16px',
    display: 'flex',
    gap: '32px',
    justifyContent: 'space-between',
    padding: '24px 20px',

    '& .label': {
      color: theme.palette.text.primary,
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '120%',
      whiteSpace: 'nowrap',
    },

    '& .value': {
      color: theme.palette.primary.light,
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '120%',
    },

    '& .tags': {
      display: 'flex',
      gap: '4px',
      flexWrap: 'wrap',
    },

    '& .question_code, & .question_tags, & .question_value': {
      alignItems: 'center',
      display: 'flex',
      gap: '4px'
    },

    '& .btn': {
      background: '#fff',
      borderColor: '#b7b2c8',
      borderRadius: '8px',
      color: '#b7b2c8',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '120%',
      padding: '14px 24px',
      textTransform: 'capitalize',
      transform: 'background 0.3s ease-in-out',
      whiteSpace: 'nowrap',

      '&:hover': {
        background: '#412a880a'
      }
    },

    '& .first_column-xxl': {
      display: 'flex',
      gap: '32px',
    },

    '& .last_column-xxl': {
      alignItems: 'center',
      display: 'flex',
      gap: '32px',
    },

    '& .first_column-xl, & .last_column-xl': {
      display: 'none'
    },
  },

  [theme.breakpoints.down('xl')]: {
    '& .question_info': {
      '& .first_column-xxl, & .last_column-xxl': {
        display: 'none'
      },

      '& .first_column-xl': {
        display: 'initial',
        flex: 1,

        '& .line': {
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '10px',
        }
      },

      '& .last_column-xl': {
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-end',
      }
    }
  }
}))

const ResolutionBox = styled(Box)(({ theme }) => ({
  padding: '24px',

  '& .title': {
    color: theme.palette.text.primary,
  },

  '& .resolution p': {
    color: theme.palette.text.primary,
    fontWeight: 500,
  },

  '& .resolution .no_resolution': {
    textAlign: 'center',
    fontSize: '18px',
  }
}))

const ChipBox = styled(Box)(() => ({
  background: '#fff',
  border: '1px solid #9f9f9f',
  borderRadius: '16px',
  color: '#9f9f9f',
  fontSize: '12px',
  fontWeight: 500,
  padding: '4px 8px',
  width: 'fit-content',

  '& .info': {
    flex: 1,
  }
}))

interface IChipProps {
  label: string
}

const Chip: React.FC<IChipProps> = ({ label }) => {
  return (
    <ChipBox>{label}</ChipBox>
  )
}

interface IQuestionCardProps {
  data: IContentModuleItems
  questionNumber: number
  activityType: string
}

const QuestionCard: React.FC<IQuestionCardProps> = ({ data, questionNumber, activityType }) => {
  const [open, setOpen] = useState(false)
  const alphabetic = 'abcdefghijklmnopqrstuvwxyz'

  const handleViewResolution = () => setOpen(prevState => !prevState)

  return (
    <QuestionContainer>
      <Box className='question_number'>{questionNumber}</Box>
      <Box className='question'>
        <div
          className='question_statement'
          dangerouslySetInnerHTML={{ __html: data.content.question.statement }}
        />

        <Box className='question_options'>
          {data.content.question.question_options.map((option, index) => (
            <Box
              className={`option ${open && option.is_correct ? 'is_correct' : ''}`}
              key={option.id}
            >
              <Box>{`${alphabetic[index]})`}</Box>
              <div dangerouslySetInnerHTML={{ __html: option.description }} />
            </Box>
          ))}
        </Box>
      </Box>

      <Box className='question_info'>
        <Box className='first_column-xxl'>
          <Box className='question_code'>
            <BarIcon />
            <Box className='label'>Código:</Box>
            <Box className='value'>{data.content.question.id}</Box>
          </Box>

          <Box className='question_tags'>
            <TagIcon />
            <Box className='label'>Tags:</Box>
            <Box className='tags'>
              {data.content.question.enem_type && <Chip label='ENEM' />}
              {[
                { value: 'only_choice', label: 'Objetiva' },
                { value: 'discursive', label: 'Discursiva' }
              ].map((item) => (
                item.value === data.content.question.question_type && (
                  <Chip key={item.value} label={item.label} />
                )
              ))}
              {data.content.question.segments?.map((segment) => (
                <Chip key={segment.id} label={segment.name} />
              ))}
              {data.content.question.subjects?.map((subject) => (
                <Chip key={subject.id} label={subject.name} />
              ))}
              {data.content.question.topics?.map((topic) => (
                <Chip key={topic.id} label={topic.complete_name} />
              ))}
            </Box>
          </Box>
        </Box>

        <Box className='last_column-xxl'>
          {activityType === 'exam' && (
            <Box className='question_value'>
              <Box className='label'>Valor da questão:</Box>
              <Box className='value'>{data.value_item}</Box>
            </Box>
          )}

          <Button
            type='button'
            className='btn'
            variant='outlined'
            startIcon={<ViewIcon />}
            onClick={handleViewResolution}
          >
            {open ? 'Ocultar resolução' : 'Ver resolução'}
          </Button>
        </Box>

        <Box className='first_column-xl'>
          <Box className='line'>
            <Box className='question_code'>
              <BarIcon />
              <Box className='label'>Código:</Box>
              <Box className='value'>{data.content.question.id}</Box>
            </Box>

            {activityType === 'exam' && (
              <Box className='question_value'>
                <Box className='label'>Valor da questão:</Box>
                <Box className='value'>{data.value_item}</Box>
              </Box>
            )}
          </Box>

          <Box className='question_tags'>
            <TagIcon />
            <Box className='label'>Tags:</Box>
            <Box className='tags'>
              {data.content.question.enem_type && <Chip label='ENEM' />}
              {[
                { value: 'only_choice', label: 'Objetiva' },
                { value: 'discursive', label: 'Discursiva' }
              ].map((item) => (
                item.value === data.content.question.question_type && (
                  <Chip key={item.value} label={item.label} />
                )
              ))}
              {data.content.question.segments?.map((segment) => (
                <Chip key={segment.id} label={segment.name} />
              ))}
              {data.content.question.subjects?.map((subject) => (
                <Chip key={subject.id} label={subject.name} />
              ))}
              {data.content.question.topics?.map((topic) => (
                <Chip key={topic.id} label={topic.complete_name} />
              ))}
            </Box>
          </Box>
        </Box>

        <Box className='last_column-xl'>
          <Button
            type='button'
            className='btn'
            variant='outlined'
            startIcon={<ViewIcon />}
            onClick={handleViewResolution}
          >
            {open ? 'Ocultar resolução' : 'Ver resolução'}
          </Button>
        </Box>
      </Box>

      <Collapse in={open}>
        <ResolutionBox>
          <Typography
            component='h3'
            className='title'
          >
            Resolução:
          </Typography>

          <Box className='resolution'>
            {data.content.question.resolution ? (
              <div dangerouslySetInnerHTML={{ __html: data.content.question.resolution }} />
            ) : (
              <Typography className='no_resolution'>Essa questão não possui resolução.</Typography>
            )}
          </Box>
        </ResolutionBox>
      </Collapse>
    </QuestionContainer>
  )
}

export default QuestionCard
