import React, { useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { Field } from 'react-final-form'
import CepInput from '../../input/CepInput'
import TextInput from '../../input/TextInput'
import { required } from '../../../utils/formUtils'
import { AddressFormContainer } from './styles/AddressForm.styles'

const AddressForm = ({ change, values, name }: any) => {
  const [cepError, setCepError] = useState('')

  const handleSearchZipCode = async (zipCode: any) => {
    try {
      const response = await fetch(`https://viacep.com.br/ws/${zipCode}/json/`)
      const data = await response.json()
      if (data.erro || data.erro === 'true') {
        setCepError('CEP não encontrado.')
      } else {
        change(name ? `${name}.street` : 'street', data.logradouro)
        change(name ? `${name}.neighborhood` : 'neighborhood', data.bairro)
        setCepError('')
      }
    } catch (error) {
      setCepError('CEP não encontrado!')
    }
  }

  useEffect(() => {
    values?.length === 9 && handleSearchZipCode(values)
  }, [values])

  return (
    <>
      <AddressFormContainer>
        <Typography
          component='h4'
          className='secondary_title'
        >
          Endereço
        </Typography>

        <Grid container rowSpacing={3} columnSpacing={3}>
          <Grid item lg={3} md={3}>
            <Box className='datafield'>
              <label htmlFor={name ? `${name}.zipcode` : 'zipcode'}>CEP {!name && '*'}</label>
              <Field
                id={name ? `${name}.zipcode` : 'zipcode'}
                name={name ? `${name}.zipcode` : 'zipcode'}
                component={CepInput}
                placeholder='00000-000'
                validate={!name ? required : undefined}
                handleSearchZipCode={() => handleSearchZipCode(values)}
              />
              {cepError && (
                <Typography className='error_message'>
                  {cepError}
                </Typography>
              )}
            </Box>
          </Grid>

          <Grid item lg={6} md={6} />

          <Grid item lg={6} md={6}>
            <Box className='datafield'>
              <label htmlFor={name ? `${name}.street` : 'street'}>Rua {!name && '*'}</label>
              <Field
                id={name ? `${name}.street` : 'street'}
                name={name ? `${name}.street` : 'street'}
                component={TextInput}
                validate={!name ? required : undefined}
                placeholder='Insira o nome da rua'
              />
            </Box>
          </Grid>

          <Grid item lg={2} md={6}>
            <Box className='datafield'>
              <label htmlFor={name ? `${name}.number` : 'number'}>Número {!name && '*'}</label>
              <Field
                id={name ? `${name}.number` : 'number'}
                name={name ? `${name}.number` : 'number'}
                component={TextInput}
                validate={!name ? required : undefined}
                placeholder='Insira o número'
              />
            </Box>
          </Grid>

          <Grid item lg={2} md={6}>
            <Box className='datafield'>
              <label htmlFor={name ? `${name}.complement` : 'complement'}>Complemento</label>
              <Field
                id={name ? `${name}.complement` : 'complement'}
                name={name ? `${name}.complement` : 'complement'}
                component={TextInput}
                placeholder='Insira o complemento'
              />
            </Box>
          </Grid>

          <Grid item lg={2} md={6}>
            <Box className='datafield'>
              <label htmlFor={name ? `${name}.neighborhood` : 'neighborhood'}>Bairro {!name && '*'}</label>
              <Field
                id={name ? `${name}.neighborhood` : 'neighborhood'}
                name={name ? `${name}.neighborhood` : 'neighborhood'}
                component={TextInput}
                validate={!name ? required : undefined}
                placeholder='Insira o nome do bairro'
              />
            </Box>
          </Grid>
        </Grid>
      </AddressFormContainer>
    </>
  )
}

export default AddressForm
