import React, { useState } from 'react'
import {
  AsyncPaginate,
  LoadOptions,
} from 'react-select-async-paginate'
import Box, { BoxProps } from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { DefaultOptionType } from '../../utils/types'
import http from '../../utils/http'
import { transformDataToOptions } from '../../utils/functions'

interface BoxStyledProps extends BoxProps {
  rowlabel?: 'true' | 'false'
  hasborder?: 'true' | 'false'
}

const BoxStyled = styled(Box)<BoxStyledProps>(({ theme, rowlabel, width, hasborder }) => {
  return ({
  alignItems: 'baseline',
  display: 'flex',
  flexDirection: rowlabel === 'true' ? 'row' : 'column',
  gap: rowlabel === 'true' ? '8px' : 0,
  '& > div': {
    fontSize: 14,
    minWidth: 80,
    width: width || '100%',
    '& .select__menu': {
      width: width || '100%',
    },
    '& .select__control': {
      borderRadius: 8,
      border: hasborder === 'true' ? '1px solid #D9D9D9' : 'none',
      background: hasborder === 'true' ? '#FFFFFF' : 'none',
      cursor: 'pointer',
      minHeight: 40,
      width: width || '100%',
      paddingLeft: hasborder === 'true' ? 20 : 0,
      '& .select__value-container': {
        padding: '2px 0'
      },
      '& .select__placeholder': {
        color: hasborder === 'true' ? '#D9D9D9' : '#9F9F9F',
        fontWeight: hasborder === 'true' ? 400 : 600,
        marginLeft: 0
      },
      '& .select__input-container': {
        color: theme.palette.text.primary
      },
      '& .select__dropdown-indicator > svg': {
        height: 16,
        width: 20
      },
      '& .select__multi-value': {
        background: '#FFFFFF',
        border: `0.75px solid ${theme.palette.text.secondary}`,
        borderRadius: 15,
        '& .select__multi-value__label': {
          color: theme.palette.text.primary,
          padding: '0 0 0 6px',
          whiteSpace: 'normal'
        },
        '& .select__multi-value__remove': {
          '&:hover': {
            background: 'none',
            '& svg': {
              backgroundColor: theme.palette.error.main
            }
          },
          '& svg': {
            fill: '#fff',
            borderRadius: '50%',
            backgroundColor: theme.palette.text.secondary,
            height: 10,
            width: 10
          }
        }
      }
    },
    '& .select__control--is-focused': {
      borderColor: hasborder === 'true' ? theme.palette.primary.main : 'none',
      boxShadow: hasborder === 'true' ? `0 0 0 1px ${theme.palette.primary.main}` : `0 0 0 0`
    }
  }
})})

const SelectField = ({
  request,
  options,
  placeholder,
  input: { name, value, onChange, ...restInput },
  meta: { touched, error },
  label,
  helperText,
  rowLabel,
  width,
  hasBorder = false,
  defaultFieldValue,
  ...rest
}: any) => {
  const [values, setValues] = useState<{ id?: number, value: string, name: string }[]>([])

  const loadOptions: LoadOptions<any, any, { page: number }> = async (
    searchQuery,
    loadedOptions,
    page
  ) => {
    if (request?.path) {
      const response = await http.get(request.path, { params: request.params })
      const newPage = (page?.page && page.page) || 1
      return {
        options: transformDataToOptions(response.data.data || response.data),
        hasMore: response.headers['x-total-pages'] ? newPage < Number(response.headers['x-total-pages']) : false,
        additional: {
          page: newPage + 1
        }
      }
    } else {
      return {
        options: options,
        hasMore: false
      }
    }
  }

  const handleOnChange = (option: DefaultOptionType) => {
    if (typeof onChange === 'function') {
      onChange(option)
      setValues((prevState: any) => prevState.concat(option))
    }
  }

  return (
    <BoxStyled width={width} rowlabel={rowLabel && rowLabel.toString()} hasborder={hasBorder.toString()}>
      {((label && values && values.length > 0) || (label && hasBorder)) &&
        <Typography
          sx={{
            color: '#9F9F9F',
            mb: hasBorder ? 1 : 0,
            fontSize: 14,
            fontWeight: hasBorder ? 500 : 600,
            whiteSpace: 'nowrap',
            wordWrap: 'unset'
          }}
          variant='body2'
          component='label'
        >
          {label}
        </Typography>}
      <AsyncPaginate
        classNamePrefix='select'
        key={`${JSON.stringify(name)}[${rest?.cacheUniqs}]`}
        loadOptions={loadOptions}
        getOptionValue={(option: { name: string }) => option.name}
        getOptionLabel={(option: { name: string }) => option.name}
        onChange={handleOnChange}
        isSearchable={false}
        placeholder={placeholder}
        value={value || defaultFieldValue}
        components={{
          IndicatorSeparator: () => null,
          ClearIndicator: () => null
        }}
        loadingMessage={() => 'Carregando...'}
        noOptionsMessage={() => 'Não encontrado'}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: 'rgba(217, 217, 217, 0.4)',
          },
          wordBreak: 'break-all'
        })}
        additional={{
          page: 1
        }}
        {...rest}
        {...restInput}
      />
      <Typography
        align='left'
        component='p'
        variant='caption'
        sx={{
          color: touched && error ? '#F69E9E' : '#D9D9D9',
          width: '100%',
        }}
      >
        {touched && error && error}
      </Typography>
    </BoxStyled>
  )
}

export default SelectField
