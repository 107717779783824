import React, { useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

// Components
import {
  Box,
  Button,
  Collapse,
  Drawer,
  IconButton,
  List,
  Theme,
  Tooltip,
  Typography
} from '@mui/material'

// Redux
import { useAppDispatch, useAppSelector } from '../../store/hooks'  // eslint-disable-line
import { signOutRequest } from '../../store/auth/actions'

// Utils
import { CSSObject, styled } from '@mui/material/styles'
import { useSidebarContext } from '../../contexts/SidebarContext'

// Icons
import ArrowIcon from '../../assets/components/HomePage/arrow-icon.svg'
import ArrowDownIcon from '../../assets/components/HomePage/arrow-down-icon.svg'
// import BookOutlinedIcon from '../../assets/components/HomePage/book-outlined-icon.svg'
import GearsOutlinedIcon from '../../assets/components/HomePage/gears-icon.svg'
import HomePageLightIcon from '../../assets/components/HomePage/homepage-light-icon.svg'
import ListIcon from '../../assets/components/HomePage/list-icon.svg'
import LogoutIcon from '../../assets/components/HomePage/logout.svg'
import PencilOutlinedIcon from '../../assets/components/HomePage/pencil-outlined-icon.svg'
import ProfLogo from '../../assets/components/HomePage/prof-logo.svg'
import ProfFullLogo from '../../assets/components/HomePage/prof-full-logo.svg'

const openedMixin = (theme: Theme): CSSObject => ({
  width: theme.spacing(25),
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  background: theme.palette.primary.main,
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(9)} + 1px)`,
  background: theme.palette.primary.main,
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '12px',
  justifyContent: 'center',
  padding: '16px 0 28px',
  ...theme.mixins.toolbar,
  '& .sidebar_header': {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',

    '& .sidebar_logo-btn ': {
      padding: 0,
      position: 'absolute',
      right: 0,
      top: 0,
      transition: 'transform 0.3s ease-in-out',
      transform: 'rotate(180deg)',

      '&.open': {
        transition: 'transform 0.3s ease-in-out',
        transform: 'rotate(0deg)',
      }
    }
  }
}))

const DrawerStyled = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    })
  }),
)

const MenuItem = styled(NavLink)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-start',
  padding: '8px 0 8px 17px',
  position: 'relative',
  textWrap: 'nowrap',
  width: '100%',

  '& .link_container': {
    alignItems: 'center',
    display: 'flex',
    gap: '12px',
    flexShrink: 0,
    textWrap: 'nowrap',

    '& .link_icon': {
      borderRadius: '4px',
      padding: '4px',
      height: '32px',
      transition: 'background 0.3s ease-in-out',
      '& img': {
        height: '24px',
      }
    },

    '& .link_description': {
      color: '#ffffff',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '120%',
      width: '100%',
      transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, color 0.3s ease-in-out',

      '&.hide': {
        width: 0,
        overflow: 'hidden',
        transition: 'width 0ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
      }
    }
  },

  '&.active': {
    '& .link_container': {
      '&::after': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: '50%',
        width: '2px',
        height: '32px',
        transform: 'translateY(-50%)',
        background: theme.palette.primary.light
      },

      '& .link_icon': {
        background: theme.palette.primary.light
      },

      '& .link_description': {
        color: theme.palette.primary.light,
        fontWeight: 600
      }
    }
  },

  '&:hover': {
    '& .link_icon': {
      background: theme.palette.primary.light,
      borderRadius: '4px',
    },

    '& .link_description': {
      color: theme.palette.primary.light,
      fontWeight: 600
    }
  }
}))

const MenuCollapse = styled(Box)(({ theme }) => ({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  padding: '8px 0 8px 17px',
  position: 'relative',
  width: '100%',
  '& .collapse_button': {
    alignItems: 'center',
    display: 'flex',
    width: '100%',

    '& .icon': {
      height: '32px',
      marginRight: '12px',
      padding: '4px',
      transition: 'background 0.3s ease-in-out',
      '& img': {
        height: '24px',
      },

      '&.hide': {
        marginRight: '4px'
      }
    },

    '& .description': {
      color: '#ffffff',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '120%',
      width: '100%',
      transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, color 0.3s ease-in-out',

      '&.hide': {
        width: 0,
        overflow: 'hidden',
        transition: 'width 0ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
      }
    },

    '& .arrow_icon': {
      height: '14px',
      marginLeft: 'auto',
      marginRight: '14px',
      flex: 1,
      transition: 'transform 0.3s ease-in-out',

      '&.open': {
        transition: 'transform 0.3s ease-in-out',
        '& img': {
          transform: 'rotate(180deg)',
        }
      }
    },

    '&.active': {
      '&::after': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: '8px',
        width: '2px',
        height: '32px',
        background: theme.palette.primary.light
      },

      '& .icon': {
        background: theme.palette.primary.light,
        borderRadius: '4px',
      }
    }
  },

  '& .menu_container': {
    width: '100%',
    margin: '14px 0 0 43px',
    borderLeft: '1px solid #bc9bfa4d',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',

    '& .active': {
      '& .link_container::after': {
        background: 'transparent',
        content: '""',
      }
    }
  },

  '&:hover': {
    '& .collapse_button': {
      '& .icon': {
        background: theme.palette.primary.light,
        borderRadius: '4px',
      },
    },

    '& .description': {
      color: theme.palette.primary.light,
      fontWeight: 600,
      lineHeight: '120%'
    }
  }
}))

const LogoutButton = styled(Button)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: '12px',
  justifyContent: 'flex-start',
  minWidth: 'fit-content',
  padding: '8px 0 8px 17px',
  position: 'relative',
  textWrap: 'nowrap',
  width: '100%',

  '& .icon': {
    padding: '4px',
    height: '32px',
    transition: 'background 0.3s ease-in-out',
    '& img': {
      height: '24px',
    }
  },

  '& .label': {
    color: '#ffffff',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '120%',
    width: '100%',
    textAlign: 'left',
    textTransform: 'capitalize',
    transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, background 0.3s ease-in-out',

    '&.hide': {
      width: 0,
      overflow: 'hidden',
      transition: 'width 0ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    }
  },

  '&:hover': {
    '& .icon': {
      background: theme.palette.primary.light,
      borderRadius: '4px',
    },

    '& .label': {
      color: theme.palette.primary.light,
      fontWeight: 600,
      lineHeight: '120%'
    }
  }
}))


const Sidebar = () => {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const { roles } = useAppSelector(state => state.auth)
  const [openAutoralCollapse, setOpenAutoralCollapse] = useState(false)
  const [openManageCollapse, setOpenManageCollapse] = useState(false)
  const { isDrawerOpen, toggleDrawerOpen } = useSidebarContext()

  const isAutoralActive = location.pathname === '/questions' || location.pathname === '/contents'
  const isManagerActive = location.pathname === '/manage-students' || location.pathname === '/manage-teachers'
  const isCoordinator = roles?.some((role: any) => role.name === 'coordinator')
  const isAdmin = roles?.some((role: any) => role.name === 'admin')
  const isDirector = roles?.some((role: any) => role.name === 'director')

  const handleOpenSidebar = () => {
    toggleDrawerOpen()
    isDrawerOpen && setOpenAutoralCollapse(false)
    isDrawerOpen && setOpenManageCollapse(false)
  }

  const handleAutoralCollapse = () => {
    !isDrawerOpen && toggleDrawerOpen()
    setOpenAutoralCollapse(prevState => !prevState)
  }

  const handleManageCollapse = () => {
    !isDrawerOpen && toggleDrawerOpen()
    setOpenManageCollapse(prevState => !prevState)
  }

  return (
    <>
      <DrawerStyled open={isDrawerOpen} variant='permanent'>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <DrawerHeader>
            <Box
              className='sidebar_header'
              onClick={handleOpenSidebar}
            >
              <IconButton className={`sidebar_logo-btn ${isDrawerOpen ? 'open' : ''}`}>
                <img src={ArrowIcon} />
              </IconButton>

              <img src={isDrawerOpen ? ProfFullLogo : ProfLogo} loading='lazy' />
            </Box>
          </DrawerHeader>

          <Box sx={{ flex: 1 }}>
            <List
              component='nav'
              sx={{
                alignItems: 'flex-start',
                display: 'flex',
                flexDirection: 'column',
                gap: '14px'
              }}
            >
              <Tooltip
                title={<Typography sx={{ fontSize: '14px' }}>Página inicial</Typography>}
                disableHoverListener={isDrawerOpen}
                placement='right'
                arrow
              >
                <MenuItem to='/'>
                  <Box className='link_container'>
                    <Box className='link_icon'>
                      <img src={HomePageLightIcon} />
                    </Box>
                    <Typography className={`link_description ${!isDrawerOpen ? 'hide' : ''}`}>
                      Página inicial
                    </Typography>
                  </Box>
                </MenuItem>
              </Tooltip>

              <Tooltip
                title={<Typography sx={{ fontSize: '14px' }}>Atividades</Typography>}
                disableHoverListener={isDrawerOpen}
                placement='right'
                arrow
              >
                <MenuItem to='/activities'>
                  <Box className='link_container'>
                    <Box className='link_icon'>
                      <img src={ListIcon} />
                    </Box>
                    <Typography className={`link_description ${!isDrawerOpen ? 'hide' : ''}`}>
                      Atividades
                    </Typography>
                  </Box>
                </MenuItem>
              </Tooltip>

              <MenuCollapse>
                <Tooltip
                  title={
                    !isDrawerOpen && (
                      <Typography sx={{ fontSize: '14px' }}>Autoral</Typography>
                    )
                  }
                  placement='right'
                  arrow
                >
                  <Box
                    className={`collapse_button ${isAutoralActive ? 'active' : ''}`}
                    onClick={handleAutoralCollapse}
                  >
                    <Box className={`icon ${!isDrawerOpen ? 'hide' : ''}`}>
                      <img src={PencilOutlinedIcon} />
                    </Box>
                    <Typography className={`description ${!isDrawerOpen ? 'hide' : ''}`}>
                      Autoral
                    </Typography>
                    <Box className={`arrow_icon ${openAutoralCollapse ? 'open' : ''}`}>
                      <img src={ArrowDownIcon} />
                    </Box>
                  </Box>
                </Tooltip>

                <Collapse in={openAutoralCollapse} sx={{ width: '100%' }}>
                  <Box className='menu_container'>
                    <MenuItem to='/questions'>
                      <Box className='link_container'>
                        <Typography className={`link_description ${!isDrawerOpen ? 'hide' : ''}`}>
                          Questões
                        </Typography>
                      </Box>
                    </MenuItem>

                    <MenuItem to='/contents'>
                      <Box className='link_container'>
                        <Typography className={`link_description ${!isDrawerOpen ? 'hide' : ''}`}>
                          Conteúdos
                        </Typography>
                      </Box>
                    </MenuItem>
                  </Box>
                </Collapse>
              </MenuCollapse>

              {/* <Tooltip
                title={<Typography sx={{ fontSize: '14px' }}>Livros</Typography>}
                placement='right'
                disableHoverListener={isDrawerOpen}
                arrow
              >
                <MenuItem to='/books'>
                  <Box className='link_container'>
                    <Box className='link_icon'>
                      <img src={BookOutlinedIcon} />
                    </Box>
                    <Typography className={`link_description ${!isDrawerOpen ? 'hide' : ''}`}>
                      Livros
                    </Typography>
                  </Box>
                </MenuItem>
              </Tooltip> */}

              {(isCoordinator || isAdmin || isDirector) && (
                <MenuCollapse>
                  <Tooltip
                    title={
                      !isDrawerOpen && (
                        <Typography sx={{ fontSize: '14px' }}>Gerenciar</Typography>
                      )
                    }
                    placement='right'
                    arrow
                  >
                    <Box
                      className={`collapse_button ${isManagerActive ? 'active' : ''}`}
                      onClick={handleManageCollapse}
                    >
                      <Box className={`icon ${!isDrawerOpen ? 'hide' : ''}`}>
                        <img src={GearsOutlinedIcon} />
                      </Box>
                      <Typography className={`description ${!isDrawerOpen ? 'hide' : ''}`}>
                        Gerenciar
                      </Typography>
                      <Box className={`arrow_icon ${openManageCollapse ? 'open' : ''}`}>
                        <img src={ArrowDownIcon} />
                      </Box>
                    </Box>
                  </Tooltip>

                  <Collapse in={openManageCollapse} sx={{ width: '100%' }}>
                    <Box className='menu_container'>
                      <MenuItem to='/manage-students'>
                        <Box className='link_container'>
                          <Typography className={`link_description ${!isDrawerOpen ? 'hide' : ''}`}>
                            Alunos
                          </Typography>
                        </Box>
                      </MenuItem>

                      {/* <MenuItem to='/manage-teachers'>
                        <Box className='link_container'>
                          <Typography className={`link_description ${!isDrawerOpen ? 'hide' : ''}`}>
                            Professores
                          </Typography>
                        </Box>
                      </MenuItem> */}
                    </Box>
                  </Collapse>
                </MenuCollapse>
              )}
            </List>
          </Box>

          <Box sx={{ flex: 1 }}>
            <Tooltip
              title={
                <Typography sx={{ fontSize: '14px' }}>
                  Sair
                </Typography>
              }
              placement='right'
              arrow
              disableHoverListener={isDrawerOpen}
            >
              <LogoutButton
                type='button'
                onClick={() => {
                  dispatch(signOutRequest())
                }}
              >
                <Box className='icon'>
                  <img src={LogoutIcon} />
                </Box>

                <Typography className={`label ${!isDrawerOpen ? 'hide' : ''}`}>
                  Sair
                </Typography>
              </LogoutButton>
            </Tooltip>
          </Box>
        </Box>
      </DrawerStyled>
    </>
  )
}

export default Sidebar
