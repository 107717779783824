import React from 'react'
import ActivityViewContainer from '../containers/ActivityViewContainer'

const ActivityViewPage = () => {
  return (
    <>
      <ActivityViewContainer />
    </>
  )
}

export default ActivityViewPage
