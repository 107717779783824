import React from 'react'

// Components
import { Box, Grid, Tooltip, Typography } from '@mui/material'

// Utils
import { textExtract } from '../../../utils/functions'
import { IAuthorOverview } from '../../../models/IBooks'

// Icons and images
import SeeMoreIcon from '../../../assets/components/Books/see-more-icon.svg'
// import TagMoreReadIcon from '../../../assets/components/Books/tagMoreRead-icon.svg'

// Styles
import {
  BookContainer,
  CardContainer,
  NewBookTag,
  StatusTag
} from './styles/BookDetailsCard.styles'

interface IReadMoreComponentProps {
  principalName?: string
  allNames: string
}

const ReadMoreComponent: React.FC<IReadMoreComponentProps> = ({ principalName, allNames }) => {
  return (
    <Tooltip title={allNames} arrow>
      <Box sx={{ alignItems: 'center', display: 'inline-flex', gap: '4px' }}>
        <Box component='span'>{principalName}</Box>
        <img src={SeeMoreIcon} />
      </Box>
    </Tooltip>
  )
}

interface IBookDetailsCardProps {
  bookData: IAuthorOverview
  school?: string
}

const BookDetailsCard: React.FC<IBookDetailsCardProps> = ({ bookData, school }) => {
  const checkStatus = (status: string) => {
    if (status === 'published') {
      return 'Publicado'
    } else if (status === 'ready_to_publish') {
      return 'Não Publicado'
    } else {
      return 'Rascunho'
    }
  }

  const checkIfIsNewBook = (publicationDate: string) => {
    const milisecondsInAday = 24 * 60 * 60 * 1000
    const currentDate: any = new Date()
    const publication: any = new Date(publicationDate)

    const differenceInDays = (currentDate - publication) / milisecondsInAday
    return differenceInDays < 15
  }

  return (
    <CardContainer>
      <Box className='tags'>
        <StatusTag status={bookData.book?.status}>
          {checkStatus(bookData.book?.status)}
        </StatusTag>
        {checkIfIsNewBook(bookData.book.created_at) && <NewBookTag>Novo</NewBookTag>}
        {/* <img
          src={TagMoreReadIcon}
          title='Livro muito utilizado'
          width='25px'
        /> */}
      </Box>

      <BookContainer>
        <Box className='cover'>
          {bookData.book?.photo_url
            ? (
              <img
                src={bookData.book?.photo_url}
                alt='Capa do livro'
                className='cover_image'
              />
            )
            : (
              <Box className='cover_image-default'>
                <span>Livro</span>
                <span>sem</span>
                <span>capa</span>
              </Box>
            )}
        </Box>

        <Box className='book'>
          <Typography
            className='book_title'
            component='h3'
          >
            {bookData?.book?.name}
          </Typography>

          <Typography className='book_description'>
            {bookData?.book?.description ? textExtract(bookData.book?.description, 88) : ''}
          </Typography>

          <Grid container className='book_data' rowSpacing={1}>
            <Grid item xs={6} className='book_data-item'>
              <Box className='data'>
                <Box component='span'>Cód:</Box>
                <Box component='span' className='code'>{bookData?.book?.id}</Box>
              </Box>
            </Grid>

            <Grid item xs={6} className='book_data-item last'>
              <Box className='data'>
                <Box component='span'>Disciplina:</Box>
                {bookData.book?.subjects.length === 1 ? (
                  <Box component='span'>{bookData.book?.subjects?.[0]?.name}</Box>
                ) : (
                  <ReadMoreComponent
                    principalName={bookData.book?.subjects?.[0]?.name}
                    allNames={bookData.book?.subjects.map(subject => subject.name).join(' / ')}
                  />
                )}
              </Box>
            </Grid>

            <Grid item xs={6} className='book_data-item'>
              <Box className='data'>
                <Box component='span'>Escola:</Box>
                <Box component='span'>{school}</Box>
              </Box>
            </Grid>

            <Grid item xs={6} className='book_data-item last'>
              <Box className='data'>
                <Box component='span'>Performance:</Box>
                <Box component='span' className='performance'>{bookData?.book?.performance?.toFixed(2) ?? 0}%</Box>
              </Box>
            </Grid>

            <Grid item xs={6} className='book_data-item'>
              {/* <Box className='data'>
                <Box component='span'>NPS:</Box>
                <Box component='span'>{bookData.nps}</Box>
              </Box> */}
            </Grid>

            <Grid item xs={6} className='book_data-item last'>
              <Box className='data'>
                <Box component='span'>Acessos:</Box>
                <Box component='span' className='access'>{bookData?.access}</Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </BookContainer>
    </CardContainer>
  )
}

export default BookDetailsCard
