import React, { useEffect } from 'react'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { Field, Form } from 'react-final-form'
import DialogModal from '../../Modal/DialogModal'
import Input from '../../input/Input'
import { forceUserTempPasswordRequest, resetTempPasswordFields } from '../../../store/user/actions'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { ModalContainer } from './styles/ResetPasswordModal.styles'

interface IFormValues {
  passwordTemporary: string
}

interface IResetPasswordModalProps {
  openModal: boolean
  closeModal: () => void
  user: {
    id: number,
    name: string,
    email: string
  }
}

const ResetPasswordModal: React.FC<IResetPasswordModalProps> = ({
  openModal,
  closeModal,
  user
}) => {

  const dispatch = useAppDispatch()
  const { changePasswordTemporary } = useAppSelector(state => state.users)

  const submitForm = (values: IFormValues) => {
    if (values?.passwordTemporary) {
      dispatch(forceUserTempPasswordRequest({
        email: user?.email,
        password: values?.passwordTemporary
      }))
    }
  }

  const validate = (values: IFormValues) => {
    const errors: any = {}

    if (!values?.passwordTemporary) {
      errors.passwordTemporary = 'Preecha o campo!'
    }
    if (values?.passwordTemporary?.length < 6) {
      errors.passwordTemporary = 'A senha deve ter pelo menos 6 caracteres.'
    }

    return errors
  }

  useEffect(() => {
    if (changePasswordTemporary.isSuccess) {
      closeModal()
      dispatch(resetTempPasswordFields())
    }
  }, [changePasswordTemporary.isSuccess])

  return (
    <>
      <DialogModal
        open={openModal}
        handleClose={closeModal}
        width='450px'
      >
        <ModalContainer>
          <Typography
            component='h2'
            className='title'
          >
            Gerar senha temporária
          </Typography>

          <Box className='field'>
            <Box
              component='span'
              className='label'
            >
              Nome:
            </Box>

            <Box
              component='span'
              className='value'
            >
              {user?.name}
            </Box>
          </Box>

          <Box className='field'>
            <Box
              component='span'
              className='label'
            >
              E-mail:
            </Box>

            <Box
              component='span'
              className='value'
            >
              {user?.email}
            </Box>
          </Box>

          <Box>
            <Form
              onSubmit={submitForm}
              validate={validate}
              render={({ handleSubmit }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Box className='field'>
                      <label
                        htmlFor='passwordTemporary'
                        className='label'
                      >
                        Senha:
                      </label>

                      <Box sx={{ width: '100%' }}>
                        <Field
                          id='passwordTemporary'
                          name='passwordTemporary'
                          component={Input}
                          placeholder='Digite a nova senha'
                          width='100%'
                        />
                      </Box>
                    </Box>

                    <Box className='btn_container'>
                      <Button
                        type='button'
                        variant='contained'
                        onClick={closeModal}
                        className='btn_cancel'
                        disabled={changePasswordTemporary.isLoading}
                      >
                        Cancelar
                      </Button>

                      <Button
                        type='submit'
                        variant='contained'
                        className='btn_submit'
                        disabled={changePasswordTemporary.isLoading}
                        startIcon={changePasswordTemporary.isLoading && (
                          <CircularProgress
                            size={20}
                            variant='indeterminate'
                            color='inherit'
                          />
                        )}
                      >
                        Enviar
                      </Button>
                    </Box>
                  </form>
                )
              }}
            />
          </Box>
        </ModalContainer>
      </DialogModal>
    </>
  )
}

export default ResetPasswordModal
