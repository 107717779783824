import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const CardContainer = styled(Box)(() => ({
  background: '#ffffff',
  borderRadius: '16px',
  padding: '4px 16px 24px',

  '& .tags': {
    alignItems: 'center',
    display: 'flex',
    gap: '8px',
    justifyContent: 'flex-end'
  }
}))

export const StatusTag = styled('span')<any>(({ theme, status }) => {
  let color
  if (status === 'published') {
    color = theme.palette.secondary.main
  } else if (status === 'ready_to_publish') {
    color = theme.palette.primary.light
  } else {
    color = theme.palette.error.main
  }

  return {
    border: `1px solid ${color}`,
    borderRadius: '32px',
    color: color,
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '120%',
    padding: '4px 6px'
  }
})

export const NewBookTag = styled('span')(({ theme }) => ({
  background: theme.palette.error.main,
  borderRadius: '32px',
  color: '#ffffff',
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '120%',
  padding: '4px 6px'
}))

export const BookContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '8px',
  marginTop: '4px',

  '& .cover': {
    borderRadius: '8px',
    height: '160px',
    flex: '0 0 114px',
    overflow: 'hidden',
    width: '114px !important',

    '&_image': {
      height: '100%',
      width: '100% !important'
    },

    '&_image-default': {
      alignItems: 'center',
      background: '#f1f1f1',
      color: '#c9c9c9',
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
      fontSize: '20px',
      fontWeight: 600,
      justifyContent: 'center',
      width: '100%',
    }
  },

  '& .book': {
    flex: 1,
    overflow: 'hidden',

    '&_title': {
      color: theme.palette.text.primary,
      fontSize: '18px',
      fontWeight: 700,
      lineHeight: '120%',
      marginBottom: '4px',
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },

    '&_description': {
      color: '#9f9f9f',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '150%',
      marginBottom: '18px'
    },

    '&_data': {
      '& .last': {
        display: 'flex',
        justifyContent: 'flex-end'
      },

      '.data': {
        alignItems: 'center',
        display: 'flex',
        gap: '4px',
        width: 'fit-content',

        '& span': {
          color: '#9f9f9f',
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '120%',
        },

        '& .access, & .code': {
          color: theme.palette.primary.light,
          fontWeight: 700
        },

        '& .performance': {
          color: theme.palette.secondary.main,
          fontWeight: 700
        }
      }
    }
  }
}))
