import React from 'react'
import BooksAuthorsContainer from '../containers/BooksAuthorsContainer'

const BooksAuthorsPage = () => {
  return (
    <div>
      <BooksAuthorsContainer type='authors' />
    </div>
  )
}

export default BooksAuthorsPage
