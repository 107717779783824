import React, { useState } from 'react'

// Components
import { Box, Grid, Pagination, Typography } from '@mui/material'
import BooksCatalogTable from '../table/BooksCatalogTable'
import SimpleSelect from '../select/SimpleSelect'

// Utils
import { Books } from '../../models/IBooks'

// Icons and images
import { ReactComponent as NotebookSVG } from '../../assets/components/Books/notebook-icon.svg'
import { ReactComponent as PerformanceSVG } from '../../assets/components/activity/icon-performance.svg'
import { ReactComponent as StudentIcon } from '../../assets/components/Books/student-icon.svg'

// Styles
import { FiltersContainer, PaperStyled } from './styles/BooksCatalogView.styles'

interface IOption {
  id: number
  name: string
}

interface IBooksCatalogViewProps {
  books: Books[]
  isFetching: boolean
  isError: boolean
  subjectOptions: IOption[]
  authorOptions: IOption[]
  pagination: {
    per: number,
    current: number,
    totalOfPages: number
    total: number
  }
  getBooks: (params: object) => void
}

const BooksCatalogView: React.FC<IBooksCatalogViewProps> = ({
  books,
  isFetching,
  isError,
  subjectOptions,
  authorOptions,
  pagination,
  getBooks
}) => {
  const [subject, setSubject] = useState<IOption | null>(null)
  const [author, setAuthor] = useState<IOption | null>(null)
  const [page, setPage] = useState(1)

  const handleSubjectFilter = (option: IOption) => {
    setSubject(option)
    if (!option) {
      getBooks({ education_subject_ids: null })
    } else {
      getBooks({ education_subject_ids: option.id })
    }
  }

  const handleAuthorFilter = (option: IOption) => {
    setAuthor(option)
    if (!option) {
      getBooks({ book_author_ids: null })
    } else {
      getBooks({ book_author_ids: option.id })
    }
  }

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
    getBooks({ page: value })
  }

  return (
    <>
      <PaperStyled>
        <Box display='flex' justifyContent='space-between' mb={2}>
          <Typography component='h2' sx={{ fontSize: 18, fontWeight: 600, mb: 2 }}>
            <PerformanceSVG style={{ marginRight: 16 }} />
            Catálogo de Livros Disponíveis
          </Typography>

          <FiltersContainer>
            <Box className='filter_container'>
              <NotebookSVG />
              <SimpleSelect
                className='subjects'
                placeholder='Disciplina...'
                isClearable
                options={subjectOptions}
                onChange={handleSubjectFilter}
                value={subject}
              />
            </Box>

            <Box className='filter_container'>
              <StudentIcon />
              <SimpleSelect
                className='units'
                placeholder='Autor...'
                isClearable
                options={authorOptions}
                onChange={handleAuthorFilter}
                value={author}
              />
            </Box>
          </FiltersContainer>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} sx={{ width: 520 }}>
            <BooksCatalogTable
              books={books}
              isFetching={isFetching}
              isError={isError}
            />
          </Grid>
        </Grid>
      </PaperStyled>

      {!isError && (
        <Box sx={{ marginTop: '32px' }}>
          <Pagination
            count={pagination.totalOfPages}
            size='small'
            color='primary'
            page={page}
            onChange={handleChangePage}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          />
        </Box>
      )}
    </>
  )
}

export default BooksCatalogView
