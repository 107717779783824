import React from 'react'
import Button from '../button/Button'
import { Link, Typography } from '@mui/material'
import { useTheme, styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import SuccessImage from '../../assets/components/Message/success-image.svg'

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '56px 0 40px',
  width: '100%',
  justifyContent: 'center',
  flexDirection: 'column',
  borderRadius: '8px',
  backgroundColor: '#fff'
})

interface SuccessMessageProps {
  type: 'questions' | 'contents' | 'activities' | 'books' | 'publish_book' | 'teachers' | 'students',
  path: string,
  isEdit?: boolean,
  isDraft?: boolean
}

const SuccessMessage: React.FC<SuccessMessageProps> = ({
  type,
  path,
  isEdit,
  isDraft,
}) => {
  const navigate = useNavigate()
  const theme = useTheme()

  const refreshPage = () => {
    if (type === 'publish_book') {
      navigate('/books/form')
    } else {
      type === 'books' && localStorage.removeItem('book')
      window.location.reload()
    }
  }

  let textEdit
  switch (type) {
    case 'activities':
      textEdit = ' atividade editada com sucesso!'
      break
    case 'books':
      textEdit = ' livro editado com sucesso!'
      break
    case 'contents':
      textEdit = ' conteúdo editado com sucesso!'
      break
    case 'questions':
      textEdit = ' questão editada com sucesso!'
      break
    case 'teachers':
      textEdit = ' professor editado com sucesso!'
      break
    case 'students':
      textEdit = ' aluno editado com sucesso!'
      break
    default:
      textEdit = null
  }

  let text
  switch (type) {
    case 'activities':
      text = ' atividade criada com sucesso!'
      break
    case 'books':
      text = ' livro criado com sucesso!'
      break
    case 'publish_book':
      text = ' livro publicado com sucesso!'
      break
    case 'contents':
      text = ' conteúdo criado com sucesso!'
      break
    case 'questions':
      text = ' questão criada com sucesso!'
      break
    case 'teachers':
      text = ' professor criado com sucesso!'
      break
    case 'students':
      text = ' aluno criado com sucesso!'
      break
    default:
      text = null
  }

  let textButton
  switch (type) {
    case 'activities':
      textButton = 'Adicionar nova atividade'
      break
    case 'contents':
      textButton = 'Adicionar novo conteúdo'
      break
    case 'questions':
      textButton = 'Adicionar nova questão'
      break
    case 'teachers':
      textButton = 'Criar novo professor'
      break
    case 'students':
      textButton = 'Criar novo aluno'
      break
    default:
      textButton = 'Criar novo livro'
  }

  let textLink
  switch (type) {
    case 'activities':
      textLink = 'lista de atividades'
      break
    case 'contents':
      textLink = 'lista de conteúdos'
      break
    case 'questions':
      textLink = 'lista de questões'
      break
    case 'teachers':
      textLink = 'lista de turmas'
      break
    case 'students':
      textLink = 'lista de turmas'
      break
    default:
      textLink = 'lista de livros'
  }

  return (
    <Container>
      <img
        src={SuccessImage}
        alt='imagem ilustrativa indicando sucesso ao realizar a operação'
        loading='lazy'
      />
      <Typography sx={{ fontSize: '18px', fontWeight: 600, mt: '32px' }}>
        <span style={{ fontWeight: 800 }}>Parabéns</span>,
        {!isEdit && !isDraft && text}
        {isEdit && !isDraft && textEdit}
        {isEdit && isDraft && ' rascunho editado com sucesso!'}
        {!isEdit && isDraft && ' rascunho criado com sucesso!'}
      </Typography>

      {!isEdit && (
        <Button
          type='button'
          variant='contained'
          onClick={() => refreshPage()}
          sx={{ fontWeight: 500, mt: '16px' }}
        >
          {textButton}
        </Button>
      )}

      <Typography sx={{ fontWeight: 600, mt: '16px' }}>
        {isEdit ? 'voltar para ' : 'ou voltar para '}
        <Link
          sx={{ cursor: 'pointer', color: theme.palette.primary.light }}
          onClick={() => navigate(`/${path}`)}
        >
          {textLink}
        </Link>
      </Typography>
    </Container>
  )
}

export default SuccessMessage
