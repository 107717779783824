/* eslint-disable @typescript-eslint/no-unused-vars */
import { createReducer } from '@reduxjs/toolkit'
import { AnyAction } from 'redux'
import { ActivityReport, IActivities, IActivity, IOverview, ParticipantStatus, ParticipantsDetails } from '../../models/IActivities'

import {
  activitiesItemsFetchRequest,
  activitiesItemsFetchSuccess,
  activitiesItemsFetchFailure,
  activitySaveRequest,
  activitySaveSuccess,
  activitySaveFailure,
  activityUpdateRequest,
  activityUpdateSuccess,
  activityUpdateFailure,
  activityReopenRequest,
  activityReopenSuccess,
  activityReopenFailure,
  activityItemByIdFetchRequest,
  activityItemByIdFetchSuccess,
  activityItemByIdFetchFailure,
  archiveActivityRequest,
  archiveActivitySuccess,
  archiveActivityFailure,
  activitiesResetFilters,
  activitiesOverviewFetchRequest,
  activitiesOverviewFetchSuccess,
  activitiesOverviewFetchFailure,
  clearActivityItem,
  activityParticipantsDetailsRequest,
  activityParticipantsDetailsSuccess,
  activityParticipantsDetailsFailure,
  activityReportFetchRequest,
  activityReportFetchSuccess,
  activityReportFetchFailure,
  changePagination,
  fetchActivityByIdRequest,
  fetchActivityByIdSuccess,
  fetchActivityByIdFailure,
  clearActivity,
} from './actions'

interface StateType {
  items: {month: string, activities: IActivities[]}[]
  item: IActivities
  activity: IActivity
  pagination: { totalOfPages: number, current: number, total: number }
  isFetching: boolean
  isError: boolean
  isArchiving: boolean
  isSavingOrUpdating: boolean,
  isSuccess: boolean,
  messageError: string | null
  participantStatus?: ParticipantStatus,
  needFeedback?: {
    students: {
      quantity: number
    }
  },
  filters: {
    page?: number
    per?: number
    title?: string
    order?: string
    start_from?: string
    start_until?: string
    statuses?: string
  }
  overview: IOverview
  activityReport?: ActivityReport[]
  details: {
    isFetching: boolean,
    isError: boolean,
    messageError?: string | null
    data: ParticipantsDetails
  }
}

const initialState: StateType = {
  items: [],
  item: {
    id: '',
    title: '',
    description: '',
    status: '',
    draft: false,
    created_by_user: { id: 0, name: '' },
    created_by_user_role: { name: '' },
    modules: [],
    content_items: [],
    education_subject_ids: [],
    lms_activity_participants: [],
    content_module_type: null,
    theme_ids: null,
    contents: { id: 0, name: '' },
    exam_type: { value: '', name: '' },
    participants: [],
    end_date: null,
    start_date: null,
    ids: null,
    education_grade_ids: null,
    questions: {
      modules: [],
      questions: {}
    },
  },
  activity: {} as IActivity,
  activityReport:[],
  participantStatus:{
    status:
      {
        pending: 0,
        in_progress: 0,
        finished: 0,
        unfinished: 0,
        timed_out: 0,
        need_feedback: {
          count: 0,
          questions: [
            {
              content_module_id: 0,
              content_module_item_id: 0,
              content_question_id: 0
            }
          ]
        }
      }
  },
  pagination: {
    totalOfPages: 0,
    current: 0,
    total: 0,
  },
  isFetching: false,
  isError: false,
  isArchiving: false,
  isSavingOrUpdating: false,
  isSuccess: false,
  messageError: '',
  filters: {
    per: 10
  },
  overview: {} as IOverview,
  details: {
    isFetching: false,
    isError: false,
    messageError: null,
    data: {} as ParticipantsDetails
  }
}

export const activitiesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(activitiesItemsFetchRequest, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      isFetching: true,
      isError: false,
      isArchiving: false,
      messageError: '',
      filters: { ...state.filters, ...action.payload }
    }))
    .addCase(activitiesItemsFetchSuccess, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      isFetching: false,
      isError: false,
      isArchiving: false,
      isSuccess: false,
      messageError: '',
      items: action.payload.items,
      pagination: action.payload.pagination
    }))
    .addCase(activitiesItemsFetchFailure, (state: StateType): StateType => ({
      ...state,
      isFetching: false,
      isError: true,
      isArchiving: false,
      // messageError: action.payload,
    }))
    .addCase(activitySaveRequest, (state: StateType): StateType => ({
      ...state,
      isSavingOrUpdating: true,
      isSuccess: false
    }))
    .addCase(activitySaveSuccess, (state: StateType): StateType => ({
      ...state,
      isSavingOrUpdating: false,
      isSuccess: true
    }))
    .addCase(activitySaveFailure, (state: StateType): StateType => ({
      ...state,
      isSavingOrUpdating: false,
      isSuccess: false
    }))
    .addCase(activityUpdateRequest, (state: StateType): StateType => ({
      ...state,
      isSavingOrUpdating: true,
      isSuccess: false
    }))
    .addCase(activityUpdateSuccess, (state: StateType): StateType => ({
      ...state,
      isSavingOrUpdating: false,
      isSuccess: true
    }))
    .addCase(activityUpdateFailure, (state: StateType): StateType => ({
      ...state,
      isSavingOrUpdating: false,
      isSuccess: false
    }))
    .addCase(activityReopenRequest, (state: StateType): StateType => ({
      ...state,
      details:{
        ...state.details,
        isFetching: true,
      }
    }))
    .addCase(activityReopenSuccess, (state: StateType): StateType => ({
      ...state,
      details:{
        ...state.details,
        isFetching: false,
        isError: false,
      }
    }))
    .addCase(activityReopenFailure, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      details:{
        ...state.details,
        isFetching: false,
        isError: true,
        messageError: action.payload,
      }
    }))
    .addCase(archiveActivityRequest, (state: StateType): StateType => ({
      ...state,
      isFetching: true,
      isError: false,
      isArchiving: true,
      messageError: '',
    }))
    .addCase(archiveActivitySuccess, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      isFetching: false,
      isError: false,
      isArchiving: false,
      messageError: ''
    }))
    .addCase(archiveActivityFailure, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      isFetching: false,
      isError: true,
      isArchiving: false,
      messageError: action.payload
    }))
    .addCase(activitiesResetFilters, (state: StateType): StateType => ({
      ...state,
      filters: { ... initialState.filters }
    }))
    .addCase(activitiesOverviewFetchRequest, (state: StateType): StateType => ({
      ...state,
      isFetching: true,
      isError: false,
      isArchiving: false,
      messageError: ''
    }))
    .addCase(activitiesOverviewFetchSuccess, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      isFetching: false,
      isError: false,
      isArchiving: false,
      messageError: '',
      items: action.payload,
      overview: action.payload.items
    }))
    .addCase(activitiesOverviewFetchFailure, (state: StateType): StateType => ({
      ...state,
      isFetching: false,
      isError: true,
      isArchiving: false,
      // messageError: action.payload,
    }))
    .addCase(activityItemByIdFetchRequest, (state: StateType): StateType => ({
      ...state,
      isFetching: true,
      isError: false,
      messageError: null

    }))
    .addCase(activityItemByIdFetchSuccess, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      item: action.payload.item,
      participantStatus: action.payload.participantStatus,
      needFeedback: action.payload.needFeedback,
      isFetching: false,
      isError: false,
      messageError: null
    }))
    .addCase(activityItemByIdFetchFailure, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      isFetching: false,
      isError: true,
      messageError: action.payload
    }))
    .addCase(clearActivityItem, (state: StateType): StateType => ({
      ...state,
      item: initialState.item,
      participantStatus: initialState.participantStatus,
      needFeedback: initialState.needFeedback,
      overview: initialState.overview
    }))
    .addCase(fetchActivityByIdRequest, (state: StateType) => ({
      ...state,
      isFetching: true,
      isError: false,
      messageError: null
    }))
    .addCase(fetchActivityByIdSuccess, (state: StateType, action: AnyAction) => ({
      ...state,
      isFetching: false,
      isError: false,
      messageError: null,
      activity: action.payload
    }))
    .addCase(fetchActivityByIdFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      isFetching: false,
      isError: true,
      messageError: action.payload
    }))
    .addCase(clearActivity, (state: StateType) => ({
      ...state,
      activity: initialState.activity
    }))
    .addCase(activityParticipantsDetailsRequest, (state: StateType): StateType => ({
      ...state,
      details: {
        ...state.details,
        isFetching: true,
        isError: false
      }
    }))
    .addCase(activityParticipantsDetailsSuccess, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      details: {
        ...state.details,
        isFetching: false,
        isError: false,
        data: action.payload
      },
    }))
    .addCase(activityParticipantsDetailsFailure, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      details: {
        ...state.details,
        isFetching: false,
        isError: true,
        messageError: action.payload
      }
    }))
    .addCase(activityReportFetchRequest, (state: StateType): StateType => ({
      ...state,
      isFetching: true,
      isError: false,
      messageError: null
    }))
    .addCase(activityReportFetchSuccess, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      activityReport: action.payload,
      isFetching: false,
      isError: false,
      messageError: null

    }))
    .addCase(activityReportFetchFailure, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      isFetching: false,
      isError: true,
      messageError: action.payload
    }))
    .addCase(changePagination, (state: StateType, action: AnyAction) => ({
      ...state,
      pagination: {
        ...state.pagination,
        current: action.payload.current
      }
    }))
})

export default activitiesReducer
