import { Box, Button } from '@mui/material'
import { styled } from '@mui/material/styles'

export const AcademicFormContainer = styled(Box)(({ theme }) => ({
  '& .datafield': {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',

    '& > label': {
      color: '#9f9f9f',
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: '0.14px',
    },

    '& .classroom_field': {
      alignItems: 'center',
      display: 'flex',
      gap: '16px'
    }
  },

  '& .btn_remove': {
    background: theme.palette.error.main,
    height: '25px',
    transition: 'background 0.3s ease-in-out',
    width: '25px',

    '& .icon': {
      color: '#fff',
      fontSize: '20px'
    },

    '&:hover': {
      background: '#e95c5c'
    }
  },

  '& .btn_container': {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '24px',
    // paddingRight: '41px',

    '& .btn_add': {
      background: theme.palette.primary.light,
      borderRadius: '8px',
      padding: '10px 24px',
      textTransform: 'none',
      transition: 'background 0.3s ease-in-out',

      '&:hover': {
        background: '#a782ec',
      }
    }
  }
}))

export const AddButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.light,
  fontSze: '14px',
  fontWeight: 600,
  lineHeight: '120%',
  textTransform: 'none',
  textDecoration: 'underline',
}))
