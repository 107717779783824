import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const ModalContainer = styled(Box)(({ theme }) => ({
  padding: '0 16px 16px',

  '& .title': {
    color: theme.palette.text.primary,
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '120%',
    marginBottom: '16px',
    textAlign: 'center',
  },

  '& .field': {
    alignItems: 'center',
    display: 'flex',
    gap: '8px',
    marginBottom: '16px',

    '& .label': {
      color: theme.palette.text.primary,
      fontSize: '14px',
      fontWeight: 600,
    },

    '& .value': {
      color: theme.palette.text.primary,
      fontSize: '14px',
      fontWeight: 400,
    }
  },

  '& .btn_container': {
    alignItems: 'center',
    display: 'flex',
    gap: '16px',
    justifyContent: 'center',
    marginTop: '32px',

    '& .btn_submit, & .btn_cancel': {
      borderRadius: '8px',
      fontSize: '14px',
      fontWeight: 600,
      textTransform: 'capitalize',
      width: '115px',
    },

    '& .btn_submit': {
      background: theme.palette.secondary.main,
      transition: 'background 0.3s ease-in-out',

      '&:hover': {
        background: '#02798c',
      }
    },

    '& .btn_cancel': {
      background: theme.palette.error.main,
      transition: 'background 0.3s ease-in-out',

      '&:hover': {
        background: '#ac6e6e'
      }
    }
  }
}))
