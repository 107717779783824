import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../store/hooks'

// Components
import TeacherForm from '../components/Management/form/TeacherForm'
import { convertDate, cpfFormatter } from '../utils/functions'
import { LinearProgress } from '@mui/material'
import { getTeacherByIdRequest } from '../store/teachers/actions'

function TeacherFormEditContainer() {
  const [formValues, setFormValues] = useState({} as any)
  const { id } = useParams()
  const { currentItem } = useAppSelector((state) => state.teachers)
  const { selectedUnit } = useAppSelector((state) => state.persistable)
  const dispatch = useDispatch()
  const teacher = currentItem?.item?.user

  const classrooms = teacher?.classrooms?.reduce((acc: any, curr) => {
    const existing = acc.find((item: any) =>
      item.classroom.id === curr.classroom.id
    )

    if (existing) {
      existing.subject_ids.push(curr.subject_ids)
    } else {
      acc.push({
        subject_ids: [curr.subject_ids],
        classroom: curr.classroom
      })
    }

    return acc
  }, [])

  const initialValues = {
    id: id,
    name: teacher?.name,
    birthday: teacher?.birthday && convertDate(teacher?.birthday),
    email: teacher?.email,
    phone: teacher?.phone,
    genre: { name: teacher?.genre === 'male' ? 'Masculino' : 'Feminino', value: teacher?.genre },
    taxpayer_number: teacher?.taxpayer_number && cpfFormatter(teacher?.taxpayer_number),
    street: teacher?.additional_data?.address?.street,
    zipcode: teacher?.additional_data?.address?.zipcode,
    neighborhood: teacher?.additional_data?.address?.neighborhood,
    complement: teacher?.additional_data?.address?.complement,
    number: teacher?.additional_data?.address?.number,
    classrooms
  }

  useEffect(() => {
    if (id) {
      dispatch(getTeacherByIdRequest({ id: Number(id), schoolUnitId: selectedUnit?.id }))
    }
  }, [])

  useEffect(() => {
    setFormValues({
      ...initialValues
    })
  }, [teacher])

  return (
    <>
      {currentItem?.isFetching
        ? (
          <LinearProgress color='secondary' />
        ) : (
          <TeacherForm initialValues={formValues} />
        )
      }
    </>
  )
}

export default TeacherFormEditContainer
