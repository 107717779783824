import React, { useState } from 'react'

// Components
import { Box, Collapse, IconButton, Typography } from '@mui/material'

// Utils
import { Chapter } from '../../../models/IBooks'

// Icons and images
import BarCodeIcon from '../../../assets/components/Books/barcode-icon.svg'
import ChapterIcon from '../../../assets/components/Books/chapter-icon.svg'
import ChapterOrderIcon from '../../../assets/components/Books/order-icon.svg'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

// Styles
import { ChapterContainer } from './styles/ChapterViewCollapse.styles'

interface IChapterViewCollapseProps {
  chapterData: Chapter
}

const ChapterViewCollapse: React.FC<IChapterViewCollapseProps> = ({ chapterData }) => {
  const [open, setOpen] = useState(false)

  const handleOpenCollapse = () => setOpen(prevState => !prevState)

  return (
    <ChapterContainer>
      <Box className='chapter_container'>
        <Box className='chapter'>
          <Box className='wrapper'>
            <img src={ChapterIcon} />
            <Typography className='chapter_number'>
              Capítulo {chapterData?.chapter_order} -
            </Typography>

            <Typography className='chapter_name'>
              {chapterData?.name}
            </Typography>
          </Box>

          <Box className='chapter_order'>
            <img src={ChapterOrderIcon} />
            <Box component='span'>{chapterData?.chapter_order}</Box>
          </Box>
        </Box>

        <Box className='chapter_description'>
          <Typography className='description'>
            {chapterData?.description}
          </Typography>
        </Box>

        <Box className='collapse' onClick={handleOpenCollapse}>
          <Box className='collapse_label'>
            <Box component='span'>Módulos</Box>
            <Box component='span'>({chapterData?.modules?.length})</Box>
          </Box>

          <IconButton type='button' className='collapse_btn'>
            <KeyboardArrowDownIcon
              fontSize='small'
              sx={{ transform: open ? 'rotate(180deg)' : 'rotate(0deg)' }}
            />
          </IconButton>
        </Box>

        <Collapse in={open}>
          <Box className='modules_container'>
            {chapterData?.modules?.map((module) => {
              return (
                <Box key={module?.id} className='module'>
                  <Box className='module_name'>
                    <img src={BarCodeIcon} />
                    <Box component='span'>{module?.id}</Box>
                    <Box component='span'>{module?.name}</Box>
                  </Box>

                  <Box className='module_counts'>
                    <Box component='span'>Questões</Box>
                    <Box component='span'>
                      {module?.module_items_count < 10 ? (
                        `0${module?.module_items_count}`
                      ) : (
                        module?.module_items_count
                      )}
                    </Box>
                  </Box>
                </Box>
              )
            })}
          </Box>
        </Collapse>
      </Box>
    </ChapterContainer>
  )
}

export default ChapterViewCollapse
