import { Box, Paper } from '@mui/material'
import { styled } from '@mui/material/styles'

export const PaperStyled = styled(Paper)(() => ({
  borderRadius: '16px',
  marginTop: '32px',
  padding: '24px'
}))

export const FiltersContainer = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  gap: '4px',

  '& .filter_container': {
    alignItems: 'center',
    display: 'flex'
  }
}))
