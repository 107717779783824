import React from 'react'
import { FieldRenderProps } from 'react-final-form'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

type Props = FieldRenderProps<string, any> & {
  maxCharacters?: number;
};

const TextAreaStyled = styled('textarea')(({ theme }) => ({
  borderRadius: 8,
  border: `1px solid ${theme.palette.text.secondary}`,
  fontSize: 14,
  fontFamily: 'Montserrat, sans-serif',
  color: theme.palette.text.primary,
  padding: '12px 14px',
  '&::placeholder': {
    color: theme.palette.text.secondary,
  },
  '&:focus-visible': {
    outlineColor: theme.palette.primary.main,
  },
  '&:disabled': {
    backgroundColor: '#F0F3F4',
  },
}))

const BoxStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  '& .label': {
    color: '#9F9F9F',
    marginBottom: '8px',
    fontSize: 14,
    fontWeight: 500,

    '& > span': {
      color: theme.palette.error.main,
    }
  }
}))

const TextAreaInput: React.FC<Props> = ({
  input,
  meta: { touched, error },
  label,
  maxCharacters,
  ...rest
}: Props) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target
    if (maxCharacters && value.length > maxCharacters) {
      return
    }
    input.onChange(value)
  }

  return (
    <BoxStyled>
      {label && (
        <Typography
          className='label'
          variant='body2'
          component='label'
        >
          {label}
        </Typography>
      )}
      <TextAreaStyled
        id={`textarea-${input.name}`}
        {...input}
        {...rest}
        onChange={handleInputChange}
        maxLength={maxCharacters}
      />
      {touched && error && (
        <Typography
          align='left'
          component='p'
          variant='caption'
          sx={{
            color: '#F69E9E',
          }}
        >
          {error}
        </Typography>
      )}
      {maxCharacters && input.value && input.value.length >= maxCharacters && (
        <Typography
          align='left'
          component='p'
          variant='caption'
          sx={{
            color: '#F69E9E',
          }}
        >
          máx. {maxCharacters} caracteres.
        </Typography>
      )}
    </BoxStyled>
  )
}

export default TextAreaInput
