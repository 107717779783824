import React, { useState } from 'react'

// Components
import {
  Avatar,
  Box,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  Pagination,
  Typography
} from '@mui/material'
import BookDetailsCard from '../card/BookDetailsCard'
import ErrorMessage from '../../message/ErrorMessage'

// Redux
import { fetchBooksByAuthor, fetchBooksByCoauthor } from '../../../store/books/actions'
import { useAppDispatch } from '../../../store/hooks'

// Utils
import { stringAvatar } from '../../../utils/functions'
import { IAuthorInfo, IAuthorOverview } from '../../../models/IBooks'

// Icons and images
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

// Styles
import { Container, TableCollapse } from './styles/AuthorsCollapse.styles'

interface IAuthorsCollapseProps {
  authorData: IAuthorInfo
  school?: { id: number, name: string } | null
  type?: 'authors' | 'coauthors'
}

type IPagination = {
  per?: number
  page?: number
  totalOfPages?: number
  total?: number
}

const AuthorsCollapse: React.FC<IAuthorsCollapseProps> = ({ authorData, school, type }) => {
  const [open, setOpen] = useState(false)
  const [books, setBooks] = useState<IAuthorOverview[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [pagination, setPagination] = useState<IPagination>({
    per: 6,
    page: 1,
    totalOfPages: 0,
    total: 0
  })
  const dispatch = useAppDispatch()

  const getBooksByAuthor = async (params?: object) => {
    setIsLoading(true)
    try {
      const response: any = await dispatch(fetchBooksByAuthor.request({
        authorId: authorData.id,
        params: {
          ...params,
          school_unit_id: school?.id,
          status: 'published',
          desc: true,
          per: 6
        }
      }))
      setBooks(response.data)
      setPagination(prevState => ({
        ...prevState,
        ...params,
        totalOfPages: Number(response.headers['x-total-pages']),
        total: Number(response.headers['x-total-count']),

      }))
    } catch (error) {
      setIsError(true)
    }
    setIsLoading(false)
  }

  const getBooksByCoauthor = async (params?: object) => {
    setIsLoading(true)
    try {
      const response: any = await dispatch(fetchBooksByCoauthor.request({
        coauthorId: authorData.id,
        params: {
          ...params,
          school_unit_id: school?.id,
          status: 'published',
          desc: true,
          per: 6
        }
      }))
      setBooks(response.data)
      setPagination(prevState => ({
        ...prevState,
        ...params,
        totalOfPages: Number(response.headers['x-total-pages']),
        total: Number(response.headers['x-total-count']),

      }))
    } catch (error) {
      setIsError(true)
    }
    setIsLoading(false)
  }

  const handleOpenCollapse = () => {
    setOpen(prevState => !prevState)
    type === 'authors' && !open && getBooksByAuthor({ page: 1 })
    type === 'coauthors' && !open && getBooksByCoauthor({ page: 1 })
    !open && setPagination({
      per: 6,
      page: 1,
      totalOfPages: 0,
      total: 0
    })
  }

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    type === 'authors' && getBooksByAuthor({ page: value })
    type === 'coauthors' && getBooksByAuthor({ page: value })
  }

  return (
    <Container>
      <TableCollapse
        container
        columnSpacing={1}
        onClick={handleOpenCollapse}
      >
        <Grid item xs={3} className='table_cell'>
          <Box className='author'>
            <Avatar
              alt={authorData.user.name}
              variant='rounded'
              {...stringAvatar(authorData.user.name, 40, 40, 14)}
            />

            <Typography
              className='author_name'
              title={authorData.user.name}
            >
              {authorData.user.name}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={2} className='table_cell'>
          <Typography
            className='schools'
            title={authorData.unit.name}
          >
            {authorData.unit.name}
          </Typography>
        </Grid>

        <Grid item xs={3} className='table_cell'>
          <Typography
            className='subjects'
            title={authorData.subjects.map((subject) => subject).join(' / ')}
          >
            {authorData.subjects.map((subject) => subject).join(' / ')}
          </Typography>
        </Grid>

        <Grid item xs={2} className='table_cell'>
          <Typography>
            {authorData.books}
          </Typography>
        </Grid>

        <Grid item xs={1} className='table_cell'>
          <Typography>
            <span>{authorData.access}</span> / {authorData.students}
          </Typography>
        </Grid>

        <Grid item xs={1} className='table_cell'>
          <IconButton
            onClick={() => setOpen(prevState => !prevState)}
            sx={{ width: '18px', height: '18px' }}
          >
            <KeyboardArrowDownIcon
              fontSize='small'
              sx={{ transform: open ? 'rotate(180deg)' : 'rotate(0deg)' }}
            />
          </IconButton>
        </Grid>
      </TableCollapse>

      <Collapse in={open}>
        <Box sx={{ padding: '8px 16px 32px' }}>

          {isLoading && (
            <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', height: '300px' }}>
              <CircularProgress size={32} />
            </Box>
          )}

          {!isLoading && !isError && (
            <Grid container columnSpacing={1} rowSpacing={1}>
              {books?.map((book) => {
                return (
                  <Grid item key={book.book.id} xs={12} xl={6}>
                    <BookDetailsCard bookData={book} school={school?.name} />
                  </Grid>
                )
              })}
            </Grid>
          )}

          {isError && <ErrorMessage />}

          {(!!pagination?.totalOfPages && pagination?.totalOfPages > 1) && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
              <Pagination
                count={pagination?.totalOfPages}
                size='small'
                color='primary'
                page={pagination?.page}
                onChange={handleChangePage}
              />
            </Box>
          )}
        </Box>
      </Collapse>
    </Container>
  )
}

export default AuthorsCollapse
