
import { createReducer } from '@reduxjs/toolkit'

import * as actions from './actions'
import { AnyAction } from 'redux-saga'
import { ITeacherItem, IUserTeacher } from '../../models/ITeacher'

interface StateType {
  items: IUserTeacher[]
  currentItem: {
    item: ITeacherItem,
    isFetching: boolean,
    isError: boolean
    messageError: string | null
  }
  isFetching: boolean
  isError: boolean
  isSaving: boolean
  isSuccess: boolean
  messageError: string | null
  pagination: {
    current: number,
    totalOfPages: number,
    total: number
  }
}

const initialState: StateType = {
  items: [],
  currentItem: {
    item: {} as ITeacherItem,
    isFetching: false,
    isError: false,
    messageError: '',
  },
  isFetching: false,
  isError: false,
  isSaving: false,
  isSuccess: false,
  messageError: '',
  pagination: {
    current: 1,
    totalOfPages: 0,
    total: 0
  }
}

const teachersReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.createTeacherRequest, (state: StateType) => ({
      ...state,
      isFetching: false,
      isError: false,
      isSuccess: false,
      isSaving: true,
      messageError: null,
    }))
    .addCase(actions.createTeacherSuccess, (state: StateType) => ({
      ...state,
      isFetching: false,
      isError: false,
      isSaving: false,
      isSuccess: true,
      messageError: null,
    }))
    .addCase(actions.createTeacherFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      isFetching: false,
      isSaving: false,
      isSuccess: false,
      isError: true,
      messageError: action.payload
    }))
    .addCase(actions.updateTeacherRequest, (state: StateType) => ({
      ...state,
      isFetching: false,
      isSaving: true,
      isSuccess: false,
      isError: false,
      messageError: null,
    }))
    .addCase(actions.updateTeacherSuccess, (state: StateType) => ({
      ...state,
      isFetching: false,
      isSaving: false,
      isSuccess: true,
      isError: false,
      messageError: null,
    }))
    .addCase(actions.updateTeacherFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      isFetching: false,
      isSaving: false,
      isSuccess: false,
      isError: true,
      messageError: action.payload
    }))
    .addCase(actions.getAllTeachersRequest, (state: StateType) => ({
      ...state,
      isFetching: true,
      isError: false,
      messageError: '',
    }))
    .addCase(actions.getAllTeachersSuccess, (state: StateType, action: AnyAction) => ({
      ...state,
      isFetching: false,
      isError: false,
      messageError: '',
      items: action.payload
    }))
    .addCase(actions.getAllTeachersFailure, (state: StateType) => ({
      ...state,
      isFetching: false,
      isError: true,
      messageError: 'Erro ao solicitar professores.'
    }))
    .addCase(actions.getTeacherByIdRequest, (state: StateType) => ({
      ...state,
      currentItem: {
        ...state.currentItem,
        isFetching: true,
        isError: false,
        messageError: '',
      }
    }))
    .addCase(actions.getTeacherByIdSuccess, (state: StateType, action: AnyAction) => ({
      ...state,
      currentItem: {
        ...state.currentItem,
        item: action.payload,
        isFetching: false,
        isError: false,
        messageError: '',
      }
    }))
    .addCase(actions.getTeacherByIdFailure, (state: StateType) => ({
      ...state,
      currentItem: {
        ...state.currentItem,
        isFetching: false,
        isError: true,
        messageError: 'Erro ao solicitar professor.'
      }
    }))
    .addCase(actions.teachersChangePagination, (state: StateType, action: AnyAction) => ({
      ...state,
      pagination: {
        ...state.pagination,
        total: action.payload.total,
        totalOfPages: action.payload.totalOfPages,
        current: action.payload.current
      }
    }))
    .addCase(actions.resetAllTeachers, () => ({
      ...initialState
    }))
    .addCase(actions.resetTeacher, (state: StateType) => ({
      ...state,
      currentItem: initialState.currentItem
    }))
})

export default teachersReducer
