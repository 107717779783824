import React from 'react'
import StudentFormTwo from '../components/Management/form/StudentFormTwo'

function StudentFormCreateContainer() {
  return (
    <>
      <StudentFormTwo />
    </>
  )
}

export default StudentFormCreateContainer
