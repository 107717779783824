// React
import React, { useEffect, useState } from 'react'

// Components
import {
  Box,
  Collapse,
  IconButton,
  Typography
} from '@mui/material'
import ClassroomItem from './ClassroomItem'
import SimpleDatePicker from '../../input/SimpleDatePicker'

// Utils
import { format } from 'date-fns'
import { Dates, IClassroomChapters } from '../../../models/IBooks'

// Icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

// Styles
import { CollapseContent, FormBox, SchoolNameBox } from './styles/SchoolChapterItem.styles'

interface ISchoolChapterItemProps {
  school: {
    id: number
    name: string
    numberOfClassrooms: number
    schoolData: IClassroomChapters[]
  }
  chapterIndex: number
  chapterId: number
  dates: Dates[]
  addDates: (x: any) => void
  changeChapterDate: (chapterId: number, classroomChapterId: number, key: any, value: Date) => void
  changeModuleDate: (chapterId: number, classroomChapterId: number, moduleId: number, key: any, value: Date) => void
  disabledSchoolDateSelector: boolean
  isEdit: boolean
}

const SchoolChapterItem: React.FC<ISchoolChapterItemProps> = ({
  school,
  chapterIndex,
  chapterId,
  dates,
  addDates,
  changeChapterDate,
  changeModuleDate,
  disabledSchoolDateSelector,
  isEdit
}) => {
  const [openSchool, setOpenSchool] = useState(false)
  const [date, setDate] = useState({ start_date: undefined, end_date: undefined })

  const classroomChapterIds = school?.schoolData?.map((item) => item.id)

  const handleChangeClassroomsDates = (
    chapterId: number,
    classroomChaptersIds: number[],
    key: string,
    value: Date,
  ) => {
    setDate({ ...date, [key]: value })
    const chapterIndex = dates.findIndex((item) => item.chapterId === chapterId)
    if (chapterIndex === -1) {
      addDates((prevState: any) => [
        ...prevState,
        {
          chapterId,
          classroom_chapters: classroomChaptersIds.map((item) => ({
            id: item,
            [key]: key === 'start_date' ? `${format(value, 'dd/MM/yyyy')} 00:00` : `${format(value, 'dd/MM/yyyy')} 23:59`,
            classroom_modules: []
          }))
        }
      ])
    } else {
      const update = [...dates]
      const classroomChapterIndex = dates?.[chapterIndex]?.classroom_chapters?.findIndex((item) => classroomChaptersIds?.includes(item.id))
      if (classroomChapterIndex === -1) {
        const classroomChapter = classroomChaptersIds.reduce((acc: any, item) => {
          acc.id = item
          acc[key] = key === 'start_date' ? `${format(value, 'dd/MM/yyyy')} 00:00` : `${format(value, 'dd/MM/yyyy')} 23:59`
          acc.classroom_modules = []
          return acc
        }, {})

        update[chapterIndex] = {
          ...update[chapterIndex],
          classroom_chapters: [
            ...update[chapterIndex].classroom_chapters,
            classroomChapter
          ]
        }
      } else {
        classroomChaptersIds.forEach((item) => {
          const index = update[chapterIndex].classroom_chapters.findIndex(i => i.id === item)
          update[chapterIndex].classroom_chapters[index] = {
            ...update[chapterIndex].classroom_chapters[index],
            [key]: key === 'start_date' ? `${format(value, 'dd/MM/yyyy')} 00:00` : `${format(value, 'dd/MM/yyyy')} 23:59`
          }
        })
      }
      addDates(update)
    }
  }

  const filterRangeStartDate = (time: any) => {
    const selectedDate = String(date.end_date)
    const selectDateTime = new Date(selectedDate)
    return time < selectDateTime.getTime()
  }

  const filterRangeEndDate = (time: any) => {
    const selectedDate = String(date.start_date)
    const selectedDateTime = new Date(selectedDate)
    return time > selectedDateTime.getTime()
  }

  useEffect(() => {
    chapterIndex === 0 && setOpenSchool(true)
  }, [])

  return (
    <Box>
      <SchoolNameBox onClick={() => setOpenSchool(prevState => !prevState)}>
        <Box
          className='school_name'
          component='span'
        >
          {school?.name}
        </Box>

        <Box
          className='school_numberOfClassrooms'
          component='span'
        >
          ({school?.numberOfClassrooms})
        </Box>

        <IconButton className='school_btn'>
          <KeyboardArrowDownIcon
            fontSize='small'
            sx={{ transform: openSchool ? 'rotate(180deg)' : 'rotate(0deg)' }}
          />
        </IconButton>
      </SchoolNameBox>

      <Collapse in={openSchool}>
        <CollapseContent>
          <Box className='classroom'>
            <Typography className='label'>
              Quer agendar a publicação deste capítulo na escola toda?
            </Typography>

            <FormBox>
              <Box className='field'>
                <Typography className='field_label'>Início:</Typography>

                <Box className='field_datepicker'>
                  <SimpleDatePicker
                    name='start_date'
                    placeholder='dd/mm/aaaa'
                    autoComplete='off'
                    locale='pt-BR'
                    selectsStart
                    startDate={date?.start_date}
                    endDate={date?.end_date}
                    maxDate={date?.end_date}
                    changeDate={(value: any) =>
                      handleChangeClassroomsDates(
                        chapterId,
                        classroomChapterIds,
                        'start_date',
                        value
                      )}
                    filterTime={date?.end_date && filterRangeStartDate}
                    value={date?.start_date}
                    disabled={disabledSchoolDateSelector}
                    readOnly
                  />
                </Box>
              </Box>

              <Box className='field'>
                <Typography className='field_label'>
                  Fim:
                </Typography>

                <Box className='field_datepicker'>
                  <SimpleDatePicker
                    name='end_date'
                    placeholder='dd/mm/aaaa'
                    autoComplete='off'
                    locale='pt-BR'
                    selectsEnd
                    startDate={date?.start_date}
                    endDate={date?.end_date}
                    minDate={date?.start_date}
                    value={date?.end_date}
                    changeDate={(value: any) =>
                      handleChangeClassroomsDates(
                        chapterId,
                        classroomChapterIds,
                        'end_date',
                        value
                      )}
                    filterTime={filterRangeEndDate}
                    disabled={disabledSchoolDateSelector}
                    readOnly
                  />
                </Box>
              </Box>
            </FormBox>
          </Box>

          <Box className='classroom_items'>
            {school?.schoolData?.sort((a: any, b: any) => a.id - b.id)?.map((classroom) => {
              return (
                <ClassroomItem
                  key={classroom.school_classroom.id}
                  data={classroom}
                  chapterIndex={chapterIndex}
                  chapterId={chapterId}
                  dates={dates}
                  changeChapterDate={changeChapterDate}
                  changeModuleDate={changeModuleDate}
                  isEdit={isEdit}
                />
              )
            })}
          </Box>
        </CollapseContent>
      </Collapse>
    </Box>
  )
}

export default SchoolChapterItem
