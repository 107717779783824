import { takeLatest, call, put, all } from 'redux-saga/effects'
import * as actions from './actions'
import * as services from './services'
import { AxiosResponse } from 'axios'
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions'
import { showAlertMessage } from '../alert'

export function* fetchClassroom({ payload }: any) {
  try {
    const params = {
      basic: true,
      ...payload
    }
    const { data } = yield call(services.fetchClassroom, params)
    yield put(actions.classroomFetchSuccess(data))
  } catch (error: any) {
    yield put(actions.classroomFetchFailure())
  }
}
export function* fetchClassroomWithStudents({ payload }: any) {
  try {
    const params = {
      school_unit_ids: payload.school_unit_ids
    }
    const response: AxiosResponse = yield call(services.fetchClassroomWithStudents, params)
    if (response.status === 200) {
      yield put(actions.fetchClassroomWithStudentsSuccess(response.data))
    }
  } catch (error: any) {
    yield put(actions.fetchClassroomWithStudentsFailure())
  }
}
export function* fetchClassroomWithStudentsCoordinator({ payload }: any) {
  try {
    const params = {
      school_unit_ids: payload.school_unit_ids,
      page: payload.page
    }
    const response: AxiosResponse = yield call(services.fetchManagerClassroomWithStudents, params)
    const pagination = {
      totalOfPages: Number(response.headers['x-total-pages']),
      current: Number(response.headers['x-page']),
      total: Number(response.headers['x-total-count'])
    }
    if (response.status === 200) {
      yield put(actions.fetchClassroomWithStudentsCoordinatorSuccess(response.data))
      yield put(actions.classroomWithStudentsChangePagination(pagination))
    }
  } catch (error) {
    yield put(actions.clearClassroomWithStudents())
    yield put(actions.fetchClassroomWithStudentsCoordinatorFailure())
  }
}

function* getClassroomsWithStudents(action: any) {
  try {
    const params = { ...action?.payload }
    const response: AxiosResponse = yield call(services.getClassroomsWithStudents, params)

    resolvePromiseAction(action, response)
    yield put(actions.fetchClassroomsWithStudents.success(response))
  } catch (error) {
    rejectPromiseAction(action, error)
    yield put(actions.fetchClassroomsWithStudents.failure(error))
  }
}

function* migrateStudents({ payload }: any) {
  try {
    const response: AxiosResponse = yield call(services.postMigrateStudents, payload)
    if (response.status === 200 || response.status === 201) {
      yield put(actions.migrateStudentsSuccess())
      yield put(showAlertMessage({
        message: 'Migração realizada com sucesso.',
        severity: 'success',
        duration: 6000
      }))
    }
  } catch (error) {
    yield put(actions.migrateStudentsFailure('Erro ao realizar a migração!'))
    yield put(showAlertMessage({
      message: 'Erro ao realizar a migração.',
      severity: 'error',
      duration: 6000
    }))
  }
}

export function* watchFetchClassroom() {
  yield takeLatest(actions.classroomFetchRequest, fetchClassroom)
}

export function* watchFetchClassroomWithStudents() {
  yield takeLatest(actions.fetchClassroomWithStudentsRequest, fetchClassroomWithStudents)
}

export function* watchFetchClassroomWithStudentsCoordinator() {
  yield takeLatest(actions.fetchClassroomWithStudentsCoordinatorRequest, fetchClassroomWithStudentsCoordinator)
}

function* watchFetchClassroomsWithStudents() {
  yield takeLatest(actions.fetchClassroomsWithStudents.request, getClassroomsWithStudents)
}

function* watchMigrateStudents() {
  yield takeLatest(actions.migrateStudentsRequest.type, migrateStudents)
}

export default function* classroomSaga() {
  yield all([
    watchFetchClassroom(),
    watchFetchClassroomWithStudents(),
    watchFetchClassroomWithStudentsCoordinator(),
    watchFetchClassroomsWithStudents(),
    watchMigrateStudents()
  ])
}
