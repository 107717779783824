
import { createReducer } from '@reduxjs/toolkit'
import {
  GetRolesSuccessPayload,
  GetUserSuccessPayload,
  SetUserTokenSuccessPayload,
  forceUserTempPasswordRejected,
  forceUserTempPasswordRequest,
  forceUserTempPasswordSuccess,
  getRoleRejected,
  getRoleRequest,
  getRoleSuccess,
  getUserDataRejected,
  getUserDataRequest,
  getUserDataSuccess,
  resetTempPasswordFields,
  setUserTokenRejected,
  setUserTokenRequest,
  setUserTokenSuccess,
} from './actions'

interface UserState {
  user: object
  isFetching: boolean
  isError: boolean
  messageError: string | null
  roles: object
  credentials: object,
  changePasswordTemporary: {
    isLoading: boolean,
    isSuccess: boolean
    isError: boolean
  }
}

const initialState: UserState = {
  user: {},
  isFetching: false,
  isError: false,
  messageError: '',
  roles: [],
  credentials: {},
  changePasswordTemporary: {
    isLoading: false,
    isSuccess: false,
    isError: false
  }
}

const userReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setUserTokenRequest, (state: UserState): UserState => ({
      ...state,
      isFetching: true,
      isError: false,
      messageError: ''
    }))
    .addCase(setUserTokenSuccess, (state: UserState, action: { payload: SetUserTokenSuccessPayload }): UserState => ({
      ...state,
      isFetching: true,
      isError: false,
      messageError: '',
      credentials: action.payload.credentials
    }))
    .addCase(setUserTokenRejected, (state: UserState): UserState => ({
      ...state,
      isFetching: false,
      isError: true,
      messageError: ''
    }))
    .addCase(getUserDataRequest, (state: UserState): UserState => ({
      ...state,
      isFetching: true,
      isError: false,
      messageError: ''
    }))
    .addCase(getUserDataSuccess, (state: UserState, action: { payload: GetUserSuccessPayload }): UserState => ({
      ...state,
      isFetching: false,
      isError: false,
      messageError: '',
      user: action.payload
    }))
    .addCase(getUserDataRejected, (state: UserState): UserState => ({
      ...state,
      isFetching: false,
      isError: true,
      messageError: ''
    }))
    .addCase(getRoleRequest, (state: UserState): UserState => ({
      ...state,
      isFetching: true,
      isError: false,
      messageError: ''
    }))
    .addCase(getRoleSuccess, (state: UserState, action: { payload: GetRolesSuccessPayload }): UserState => ({
      ...state,
      isFetching: false,
      isError: false,
      messageError: '',
      roles: action.payload,
    }))
    .addCase(getRoleRejected, (state: UserState): UserState => ({
      ...state,
      isFetching: false,
      isError: true,
      messageError: ''
    }))
    .addCase(forceUserTempPasswordRequest, (state: UserState) => ({
      ...state,
      changePasswordTemporary: {
        ...state.changePasswordTemporary,
        isLoading: true,
        isSuccess: false,
        isError: false,
      }
    }))
    .addCase(forceUserTempPasswordSuccess, (state: UserState) => ({
      ...state,
      changePasswordTemporary: {
        ...state.changePasswordTemporary,
        isLoading: false,
        isSuccess: true,
        isError: false
      }
    }))
    .addCase(forceUserTempPasswordRejected, (state: UserState) => ({
      ...state,
      changePasswordTemporary: {
        ...state.changePasswordTemporary,
        isLoading: false,
        isSuccess: false,
        isError: true
      }
    }))
    .addCase(resetTempPasswordFields, (state: UserState) => ({
      ...state,
      changePasswordTemporary: {
        ...initialState.changePasswordTemporary
      }
    }))
})

export default userReducer
