import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Components
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Skeleton,
  ToggleButton,
  Typography,
  styled
} from '@mui/material'
import { Form, Field } from 'react-final-form'
import AsyncSelectInput from '../../select/AsyncSelectInput'
import Button from '../../button/Button'
import Input from '../../input/Input'
import TextAreaInput from '../../input/TextAreaInput'
import InputImage from '../../input/InputImage'
import DialogModal from '../../Modal/DialogModal'
import Loading from '../../Loading/Loading'

// Redux
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import {
  createBookRequest,
  fetchBookByIdRequest,
  getCoverBooksRequest,
  getTeachersWithSubjectsRequest,
  updateBookRequest
} from '../../../store/books/actions'

// Icons
import { ICover } from '../../../models/IBooks'
import CheckIcon from '@mui/icons-material/Check'
import NoOptionsImage from '../../../assets/components/Books/noOptions-image.svg'

const PaperContainer = styled(Paper)(() => ({
  borderRadius: '16px',
  padding: '32px 24px 16px',

  '& .info_title': {
    color: '#9f9f9f',
    fontSize: '16px',
    fontWeight: 500,
    letterSpacing: '0.16px',
    marginBottom: '24px'
  },

  '& .form_container': {
    display: 'flex',
    gap: '24px',

    '& .form': {
      display: 'flex',
      flex: 1.4,
      flexDirection: 'column',
      gap: '16px'
    },

    '& .upload_input': {
      display: 'flex',
      flex: 0.45,
      flexDirection: 'column',
      maxWidth: '300px',

      '& .gallery': {
        marginTop: '8px',
        textAlign: 'center'
      }
    }
  },

  '& .required': {
    color: '#f69e9e',
    fontSize: '14px',
    fontStyle: 'italic',
    marginTop: '20px',
    textAlign: 'left',
  }

}))

const ButtonGallery = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.light,
  fontSize: '14px',
  fontWeight: 500,
  height: 'fit-content',
  letterSpacing: '0.14px',
  minWidth: 'auto',
  padding: '0px',
  textDecoration: 'underline',
  '&:active': {
    background: 'transparent'
  }
}))

const BookCoverItem = styled(Box)(() => ({
  textAlign: 'center',

  '& .toggle_button': {
    padding: '6px',
    position: 'relative',
    marginBottom: '4px',
    'img': {
      width: '100px'
    },

    '& .no_selected': {
      display: 'none'
    },

    '& .selected': {
      alignItems: 'center',
      background: 'rgba(0, 0, 0, 0.6)',
      borderRadius: '4px',
      bottom: 0,
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      left: 0,
      position: 'absolute',
      right: 0,
      top: 0,
      width: '100%',
    }
  },

  '& .name': {
    fontSize: '12px',
    fontWeight: 500,
    maxWidth: '114px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}))

const ContainerButtons = styled(Box)(() => ({
  background: '#fff',
  display: 'flex',
  gap: '16px',
  justifyContent: 'center',
  padding: '20px 8px',
  width: '100%',
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 10,

  '& .button': {
    height: '45px',
    width: '120px'
  }
}))

const NoContentContainer = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',

  '& img': {
    width: '200px'
  },

  '& h3': {
    fontSize: '18px'
  }
}))

interface BookSettingsForm {
  isTeacher?: any
  userId?: number
  activeTab?: number
  nextTab: () => void
  bookId?: string
}

interface BookSettingsValues {
  subjects: { id: number, name: string }[]
  name: string
  description: string
  coauthors: { id: number, name: string }[]
  photo: any
}

const BookSettingsForm: React.FC<BookSettingsForm> = ({
  isTeacher,
  userId,
  activeTab,
  nextTab,
  bookId
}) => {
  const [initialValues, setInitialValues] = useState({} as BookSettingsValues)
  const [openGallery, setOpenGallery] = useState(false)
  const [selectedImage, setSelectedImage] = useState<any>(null)
  const [selectedFile, setSelectedFile] = useState<any>(null)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { isSaving, currentItem, teachers, covers } = useAppSelector(state => state.books)
  const { selectedUnit } = useAppSelector(state => state.persistable)
  const bookStorage = localStorage.getItem('book')
  const book = bookStorage && JSON.parse(bookStorage)

  const professors = teachers.items.map(item => ({
    id: item.user.id,
    label: item.user.name
  }))

  const bookSettingsSubmit = (values: BookSettingsValues) => {
    book?.id || bookId
      ? dispatch(updateBookRequest({
        ...values,
        photo: values?.photo?.length === 0 ? [] : values?.photo,
        id: book?.id || bookId,
        nextTab: nextTab,
        status: 'draft'
      }))
      : dispatch(createBookRequest({
        ...values,
        nextTab: nextTab
      }))
  }

  const validate = (values: BookSettingsValues) => {
    const errors: any = {}

    if (!values?.subjects) {
      errors.subjects = 'Campo obrigatório'
    }

    if (!values?.name) {
      errors.name = 'Campo obrigatório'
    }

    if (!values?.description) {
      errors.description = 'Campo obrigatório'
    }

    return errors
  }

  const handleImageSelected = (image: ICover) => {
    setSelectedImage(image)
  }

  useEffect(() => {
    if (book?.id || bookId) {
      dispatch(fetchBookByIdRequest({ id: book?.id || bookId }))
    }
    dispatch(getTeachersWithSubjectsRequest({ schoolUnitId: selectedUnit?.id }))
  }, [book?.id, bookId])

  useEffect(() => {
    openGallery && dispatch(getCoverBooksRequest())
  }, [openGallery])

  useEffect(() => {
    const savedValues = {
      subjects: currentItem.item.subjects,
      name: currentItem.item.name,
      description: currentItem.item.description,
      coauthors: currentItem.item.co_authors?.map((coauthor) => ({
        id: coauthor?.user?.id,
        name: coauthor?.user?.name
      })),
      photo: currentItem.item.photo_url
    }
    setInitialValues({ ...savedValues })
  }, [currentItem])

  if (activeTab !== 1) {
    return null
  }

  if (currentItem.isFetching || teachers.isFetching) {
    return (
      <Box>
        <Paper sx={{ borderRadius: '16px', padding: '32px 24px' }}>
          <Box sx={{ marginBottom: '24px' }}>
            <Skeleton animation='wave' variant='text' sx={{ fontSize: '16px', width: '200px' }} />
          </Box>

          <Box sx={{ display: 'flex', gap: '16px' }}>
            <Box sx={{ display: 'flex', flex: 1.4, flexDirection: 'column', gap: '16px' }}>
              <Box>
                <Skeleton animation='wave' variant='text' sx={{ fontSize: '16px', width: '90px' }} />
                <Skeleton animation='wave' variant='rectangular' height='40px' />
              </Box>

              <Box>
                <Skeleton animation='wave' variant='text' sx={{ fontSize: '16px', width: '90px' }} />
                <Skeleton animation='wave' variant='rectangular' height='136px' />
              </Box>

              <Box>
                <Skeleton animation='wave' variant='text' sx={{ fontSize: '16px', width: '90px' }} />
                <Skeleton animation='wave' variant='rectangular' height='40px' />
              </Box>

              <Box>
                <Skeleton animation='wave' variant='text' sx={{ fontSize: '16px', width: '90px' }} />
                <Skeleton animation='wave' variant='rectangular' height='40px' />
              </Box>
            </Box>

            <Box sx={{ flex: 0.45, display: 'flex', flexDirection: 'column', gap: '8px', maxWidth: '300px' }}>
              <Skeleton animation='wave' variant='text' sx={{ fontSize: '16px', width: '180px' }} />
              <Skeleton animation='wave' variant='rectangular' width='100%' height='100%' />
            </Box>
          </Box>
        </Paper>

        <Box sx={{ display: 'flex', gap: '16px', justifyContent: 'flex-end', marginTop: '32px' }}>
          <Skeleton animation='wave' variant='rectangular' sx={{ borderRadius: '8px', height: '42px', width: '145px' }} />
          <Skeleton animation='wave' variant='rectangular' sx={{ borderRadius: '8px', height: '42px', width: '145px' }} />
        </Box>
      </Box>
    )
  }

  return (
    <div>
      <Form
        onSubmit={bookSettingsSubmit}
        initialValues={initialValues}
        validate={validate}
        render={({ handleSubmit, values, form }) => {
          return (
            <form onSubmit={handleSubmit}>
              <PaperContainer>
                <Typography className='info_title'>
                  Informações gerais
                </Typography>

                <Box className='form_container'>
                  <Box className='form'>
                    <Box>
                      <Field
                        name='name'
                        component={Input}
                        label={<>Título do livro <span>*</span></>}
                        placeholder='Digite o nome do livro'
                      />
                    </Box>

                    <Box>
                      <Field
                        name='description'
                        component={TextAreaInput}
                        label={<>Descrição <span>*</span></>}
                        minRows={10}
                        placeholder='Descreva as principais informações...'
                        style={{ width: '100%', height: '136px', resize: 'none' }}
                      />
                    </Box>

                    <Box>
                      <Field
                        name='subjects'
                        component={AsyncSelectInput}
                        label={<>Disciplina <span>*</span></>}
                        placeholder='Selecione a disciplina...'
                        isMulti
                        request={{
                          path: '/subjects',
                          params: {
                            basic: true,
                            with_knowledge_area: false,
                            teacher_user_id: isTeacher ? userId : null
                          }
                        }}
                      />
                    </Box>

                    <Box>
                      <Field
                        name='coauthors'
                        component={AsyncSelectInput}
                        label='Adicionar coautores'
                        placeholder='Selecione coautores a obra...'
                        options={professors}
                        isMulti
                      />
                    </Box>
                  </Box>

                  <Box className='upload_input'>
                    <Field
                      name='photo'
                      component={InputImage}
                      label='Adicione uma foto de capa'
                      photoObject={selectedFile ?? values.photo}
                      change={form.change}
                      clearFile={() => {
                        setSelectedFile(null)
                        setSelectedImage(null)
                      }}
                    />

                    <Box className='gallery'>
                      <ButtonGallery
                        type='button'
                        variant='text'
                        onClick={() => setOpenGallery(true)}
                      >
                        Escolher da galeria
                      </ButtonGallery>
                    </Box>
                  </Box>
                </Box>

                <Typography className='required'>* Campo obrigatório</Typography>
              </PaperContainer>

              <Box sx={{ display: 'flex', gap: '16px', justifyContent: 'flex-end', marginTop: '32px' }}>
                <Button
                  type='button'
                  variant='outlined'
                  onClick={() => navigate('/books')}
                >
                  Voltar
                </Button>
                <Button
                  variant='contained'
                  type='submit'
                  disabled={isSaving}
                  startIcon={
                    isSaving
                      ? <CircularProgress size={20} variant='indeterminate' color='inherit' />
                      : undefined
                  }
                >
                  Próximo
                </Button>
              </Box>

              <DialogModal
                open={openGallery}
                handleClose={() => {
                  setSelectedImage(null)
                  setOpenGallery(false)
                }}
                title={!covers.isFetching && 'Selecione uma capa'}
                width='700px'
                height='600px'
                sx={{ position: 'relative' }}
              >
                {covers.isFetching && (
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '16px',
                      height: 'calc(100% - 45px)',
                      justifyContent: 'center',
                    }}
                  >
                    <Box
                      component='span'
                      sx={{
                        fontSize: '22px',
                        fontWeight: 600
                      }}
                    >
                      Carregando imagens
                    </Box>
                    <Loading />
                  </Box>
                )}

                {!covers.isFetching && covers.isSuccess && covers.items?.length > 0 && (
                  <>
                    <Box
                      sx={{
                        marginInline: 'auto',
                        maxWidth: '550px',
                        overflowY: 'auto',
                        paddingBottom: '80px'
                      }}
                    >
                      <Grid
                        container
                        rowSpacing={2}
                        columnSpacing={2}
                      >
                        {covers.items?.map(book => (
                          <Grid xs={3} item key={book?.id}>
                            <BookCoverItem>
                              <ToggleButton
                                className='toggle_button'
                                value={book.id}
                                selected={selectedImage?.id === book.id}
                                onClick={() => handleImageSelected(book)}
                                title={book?.name?.split('Capa')?.[1]}
                              >
                                <img src={book?.photo_url} alt='capa do livro' />
                                <Box className={`${selectedImage?.id === book.id ? 'selected' : 'no_selected'}`}>
                                  <CheckIcon
                                    sx={{
                                      color: '#00FF00',
                                      fontSize: '50px'
                                    }}
                                  />
                                </Box>
                              </ToggleButton>
                              <Typography
                                className='name'
                                title={book?.name?.split('Capa')?.[1]}
                              >
                                {book?.name?.split('Capa')?.[1]}
                              </Typography>
                            </BookCoverItem>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>

                    <ContainerButtons>
                      <Button
                        type='button'
                        variant='outlined'
                        className='button'
                        onClick={() => {
                          setSelectedImage(null)
                          setOpenGallery(false)
                        }}
                      >
                        Cancelar
                      </Button>

                      <Button
                        type='button'
                        className='button'
                        disabled={!selectedImage}
                        onClick={() => {
                          setSelectedFile(selectedImage?.photo_url)
                          form.change('photo', selectedImage?.id)
                          setOpenGallery(false)
                        }}
                      >
                        Adicionar
                      </Button>
                    </ContainerButtons>
                  </>
                )}

                {!covers.isFetching && !covers.isError && covers.items?.length === 0 && (
                  <NoContentContainer>
                    <img src={NoOptionsImage} />
                    <h3>Não há imagens disponíveis.</h3>
                  </NoContentContainer>
                )}

                {covers.isError && (
                  <NoContentContainer>
                    <img src={NoOptionsImage} />
                    <h3>Falha ao carregar imagens!</h3>
                  </NoContentContainer>
                )}
              </DialogModal>
            </form >
          )
        }}
      />
    </div >
  )
}

export default BookSettingsForm
