/* eslint-disable no-constant-condition */
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

// Components
import { Box, Skeleton, Grid } from '@mui/material'
import BooksCatalogView from '../components/view/BooksCatalogView'
import BooksCoauthorsView from '../components/view/BookCoauthorsView'
import BooksCreatorsRankingView from '../components/view/BooksCreatorsRankingView'
import BooksStudentsPerformanceView from '../components/view/BookStudentsPerformanceView'
import BooksUsedView from '../components/view/BooksUsedView'
import Button from '../components/button/Button'
import Label from '../components/label/Label'

// Redux
import { useAppDispatch, useAppSelector } from '../store/hooks'
import {
  getBooksGeneralVisionRequest,
  getBooksRankingRequest,
  getBooksAuthorsRankingRequest,
  getBooksCoauthorsRankingRequest,
  getBooksStudentsPerformanceRequest,
  fetchBooksRequest,
  fetchTeachersAuthorsRequest
} from '../store/books/actions'
import { subjectsFetchRequest } from '../store/education'

// Icons and images
import AuthorSVG from '../assets/components/Books/authors-icon.svg'
import CreatedSVG from '../assets/components/Books/created-icon.svg'
import SketchSVG from '../assets/components/Books/sketch-icon.svg'
import PublishedSVG from '../assets/components/Books/published-icon.svg'
import { ReactComponent as BookSVG } from '../assets/components/Books/book-icon.svg'
import { ReactComponent as ExportSVG } from '../assets/components/Books/export-icon.svg'
import { HeaderReports, InfoCard, InfoCardContainer } from './styles/BooksReportsContainer.styles'

const BookReportsContainer = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { user: { units } } = useAppSelector(state => state.auth)
  const { selectedUnit: unit } = useAppSelector(state => state.persistable)
  const { subjects } = useAppSelector(state => state.education)
  const {
    items,
    isFetching,
    isError,
    pagination,
    reports: { general, ranking, authors, coAuthors, studentsPerformance },
    teachers
  } = useAppSelector(state => state.books)


  const unitOptions = units?.map(unit => ({ id: unit.id, name: unit.name }))

  const handleBackPage = () => {
    navigate('/books')
  }

  const bookAuthors = teachers && teachers.items
  const authorsOptions = bookAuthors ? bookAuthors.map((author: any) => ({ id: author.id, name: author.name })) : []

  const booksMostAccessed = ranking.most_accessed ? [...ranking.most_accessed].reverse() : []
  const booksLessAccessed = ranking.less_accessed ? [...ranking.less_accessed].reverse() : []

  const getBooks = (params?: object) => {
    dispatch(fetchBooksRequest({
      per: 10,
      page: 1,
      desc: true,
      belongs_to_unit_id: unit?.id,
      ...params
    }))
  }

  useEffect(() => {
    dispatch(subjectsFetchRequest())
    dispatch(getBooksGeneralVisionRequest({ school_unit_ids: unit?.id }))
    dispatch(getBooksRankingRequest())
    dispatch(getBooksAuthorsRankingRequest({ school_unit_id: unit?.id }))
    dispatch(getBooksCoauthorsRankingRequest({ school_unit_id: unit?.id }))
    dispatch(getBooksStudentsPerformanceRequest({ school_unit_ids: unit?.id }))
    getBooks()
    dispatch(fetchTeachersAuthorsRequest({ school_unit_ids: unit?.id }))
  }, [unit?.id])

  return (
    <div>
      <HeaderReports>
        <Label sx={{ display: 'flex' }}>
          <BookSVG /> Visão geral livros
        </Label>
        <div className='createReports'>
          <Button variant='contained'>
            <ExportSVG /> Exportar relatório completo
          </Button>
        </div>
      </HeaderReports>

      <InfoCardContainer>
        <Box className='info_container'>
          {general?.isFetching && (
            <Grid
              container
              columnSpacing={{ xs: 2, lg: 4 }}
              rowSpacing={{ xs: 2, lg: 4 }}
            >
              {Array(4).fill(0).map((_, index) => (
                <Grid item key={index} xs={6} md={3}>
                  <Skeleton
                    variant='rounded'
                    animation='wave'
                    sx={{
                      borderRadius: '8px',
                      height: '201px',
                      maxWidth: '247px',
                      width: '100%',
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          )}

          {!general?.isFetching && (
            <Grid
              container
              columnSpacing={{ xs: 2, lg: 4 }}
              rowSpacing={{ xs: 2, lg: 4 }}
            >
              <Grid item xs={6} md={3}>
                <InfoCard>
                  <img src={CreatedSVG} />
                  <p><span>{general?.created_books ?? '00'}</span> Livros criados</p>
                </InfoCard>
              </Grid>

              <Grid item xs={6} md={3}>
                <InfoCard>
                  <img src={PublishedSVG} />
                  <p><span>{general?.published_books ?? '00'}</span> Livros publicados</p>
                </InfoCard>
              </Grid>

              <Grid item xs={6} md={3}>
                <InfoCard>
                  <img src={SketchSVG} />
                  <p><span>{general?.drafted_books ?? '00'}</span> Rascunho(s)</p>
                </InfoCard>
              </Grid>

              <Grid item xs={6} md={3}>
                <InfoCard>
                  <img src={AuthorSVG} />
                  <p><span>{general?.authors ?? '00'}</span> Autores</p>
                </InfoCard>
              </Grid>
            </Grid>
          )}
        </Box>
      </InfoCardContainer>

      <Box sx={{ marginTop: '30px' }}>
        <Grid container spacing={2}>
          {ranking.isFetching && (
            <>
              {Array(2).fill(0).map((_, index) => (
                <Grid item key={index} xs={12} lg={6}>
                  <Skeleton
                    animation='wave'
                    variant='rounded'
                    sx={{
                      borderRadius: '16px',
                      height: '347px',
                      width: '100%'
                    }}
                  />
                </Grid>
              ))}
            </>
          )}

          {!ranking.isFetching && (
            <>
              <Grid item xs={12} lg={6}>
                <BooksUsedView
                  books={booksMostAccessed}
                  subjectOptions={subjects?.items ?? []}
                  unitOptions={unitOptions ?? []}
                  rankingType='mostUsed'
                  isError={ranking.isError}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <BooksUsedView
                  books={booksLessAccessed}
                  subjectOptions={subjects?.items ?? []}
                  unitOptions={unitOptions ?? []}
                  rankingType='lessUsed'
                  isError={ranking.isError}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Box>

      <BooksCreatorsRankingView
        authors={authors}
      />

      <BooksCoauthorsView
        coAuthors={coAuthors}
      />

      <BooksStudentsPerformanceView
        studentsPerformance={studentsPerformance}
      />

      <BooksCatalogView
        books={items}
        isFetching={isFetching}
        isError={isError}
        subjectOptions={subjects?.items ?? []}
        authorOptions={authorsOptions ?? []}
        pagination={pagination}
        getBooks={getBooks}
      />

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '32px' }}>
        <Button
          type='button'
          onClick={handleBackPage}
        >
          Voltar
        </Button>
      </Box>
    </div>
  )
}

export default BookReportsContainer
