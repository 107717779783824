import { all, call, takeLatest, put } from 'redux-saga/effects'
import { AnyAction } from 'redux'
import { AxiosResponse } from 'axios'

import { signOutRequest } from '../auth/actions'

import {
  setUserTokenRequest,
  setUserTokenSuccess,
  setUserTokenRejected,
  getUserDataRequest,
  getUserDataSuccess,
  getUserDataRejected,
  getRoleSuccess,
  getRoleRejected,
  forceUserTempPasswordRequest,
  forceUserTempPasswordRejected,
  forceUserTempPasswordSuccess,
} from './actions'
import { toast } from 'react-toastify'
import * as services from './services'

export function* setUserRequest({ payload: token }: AnyAction) {
  try {
    yield put(setUserTokenSuccess({ credentials: token }))

  } catch (error) {
    console.error(error)
    yield put(setUserTokenRejected())
  }
}

export function* getUserData() {
  try {
    const response: AxiosResponse<any> = yield call(services.getUserData)
    yield put(getUserDataSuccess(response?.data))
  } catch (error) {
    yield put(getUserDataRejected())
  }
}

export function* getRoles() {
  try {
    const params = {
      user_application_id: process.env.REACT_APP_PROJECT_ID || 18
    }
    const { data: { data } } = yield call(services.getRoles, params)
    if (!data.length) {
      yield put(signOutRequest())
    }
    yield put(getUserDataRequest())
    yield put(getRoleSuccess(data))

  } catch (error) {
    console.error(error)
    yield put(signOutRequest())
    yield put(getRoleRejected())
  }
}

function* updateUserTempPassword ({payload}: any) {
  const requestBody = {
    email: payload.email,
    password: payload.password
  }

  const notifyError = () => toast.error('Erro ao atualizar senha temporária.')
  const notifySuccess = () => toast.success('Senha temporária atualizada!')

  try {
    const response: AxiosResponse = yield call(services.updateUserTempPasswordService, requestBody)
    if (response.status === 200) {
      yield put(forceUserTempPasswordSuccess())
      notifySuccess()
    }
  } catch (error) {
    yield put(forceUserTempPasswordRejected())
    notifyError()
  }
}

export function* watchUserSagas() {
  yield takeLatest(setUserTokenRequest.type, setUserRequest)
}

function* watchChangeTempPassword() {
  yield takeLatest(forceUserTempPasswordRequest, updateUserTempPassword)
}

export default function* userSagas() {
  yield all([
    watchUserSagas(),
    watchChangeTempPassword()
  ])
}
