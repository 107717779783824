import React from 'react'
import BooksAuthorsContainer from '../containers/BooksAuthorsContainer'

const BooksCoauthorsPage = () => {
  return (
    <>
      <BooksAuthorsContainer type='coauthors' />
    </>
  )
}

export default BooksCoauthorsPage
