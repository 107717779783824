import React, { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Components
import {
  Avatar,
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material'
import MigrationModal from '../modal/MigrationModal'
import ResetPasswordModal from '../modal/ResetPasswordModal'
import StudentInfoModal from '../modal/StudentInfoModal'

// Redux
import { useAppDispatch } from '../../../store/hooks'

// Utils
import { stringAvatar, translatedShift } from '../../../utils/functions'
import { Class, ClassroomStudent } from '../../../models/IClassroom'

// Icons and images
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import EditIcon from '../../../assets/components/ManagersPage/pencil-icon.svg'
import LockIcon from '../../../assets/components/ManagersPage/lock-icon.svg'
import NoStudentsImage from '../../../assets/components/ManagersPage/no-students.png'
import ViewIcon from '../../../assets/components/ManagersPage/eye-icon.svg'
import { ReactComponent as CalendarIcon } from '../../../assets/components/ManagersPage/calendar-light-icon.svg'
import { ReactComponent as MigrationIcon } from '../../../assets/components/ManagersPage/migration-light-icon.svg'
import { ReactComponent as PlusIcon } from '../../../assets/components/ManagersPage/plus-circled.svg'
import { ReactComponent as ShiftIcon } from '../../../assets/components/ManagersPage/shift-icon.svg'
import { ReactComponent as StudentLightIcon } from '../../../assets/components/ManagersPage/students_light-icon.svg'

// Styles
import { Container, EmptyBox, GridContainer, StudentsContainer } from './styles/StudentCollapse.styles'

type IStudentTemp = {
  id: number
  name: string
  email: string
}

type IGeneric = {
  id: number
  name: string
}

interface IClassroomType {
  classroom: IGeneric,
  grade: IGeneric
}

interface IStudentCollapseProps {
  item: Class
  unit: IGeneric | null
}

const StudentCollapse: React.FC<IStudentCollapseProps> = ({ item, unit }) => {  // eslint-disable-line
  const [openCollapse, setOpenCollapse] = useState(false)
  const [openMigrateModal, setOpenMigrateModal] = useState(false)
  const [openViewStudentModal, setOpenViewStudentModal] = useState(false)
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false)
  const [studentData, setStudentData] = useState<ClassroomStudent>({} as ClassroomStudent)
  const [tempStudent, setTempStudent] = useState<IStudentTemp>({} as IStudentTemp)
  const [classroom, setClassroom] = useState<IGeneric>({} as IGeneric)

  const dispatch = useAppDispatch()  // eslint-disable-line
  const navigate = useNavigate()

  const handleOpenCollapse = () => setOpenCollapse(prevState => !prevState)

  const handleOpenViewStudentModal = (student: ClassroomStudent) => {
    setStudentData(student)
    setOpenViewStudentModal(true)
  }

  const handleCloseViewStudentModal = () => {
    setOpenViewStudentModal(false)
    setStudentData({} as ClassroomStudent)
  }

  const handleOpenResetPasswordModal = (student: IStudentTemp) => {
    setOpenResetPasswordModal(true)
    setTempStudent({
      id: student?.id,
      name: student?.name,
      email: student?.email,
    })
  }

  const handleCloseResetPasswordModal = () => setOpenResetPasswordModal(false)

  const handleOpenMigrateModal = () => {
    setClassroom({
      id: item.id,
      name: item.name
    })
    setOpenMigrateModal(true)
  }

  const handleCloseMigrateModal = () => setOpenMigrateModal(false)

  const handleToGoStudentEditPage = (id: number) => {
    navigate(`/manage-students/${id}/edit`)
  }

  const handleAddStudent = (classroom: IClassroomType) => {
    navigate('/manage-students/create', { state: { ...classroom } })
  }

  return (
    <Container>
      <GridContainer container onClick={handleOpenCollapse}>
        <Grid
          item
          xs={3}
          className='grid_item'
        >
          <Box className='classroom_name'>
            {item.name}
          </Box>
        </Grid>

        <Grid
          item
          xs={3}
          className='grid_item'
        >
          <Box className='school_year'>
            <CalendarIcon />
            {item?.education_school_term?.name}
          </Box>
        </Grid>

        <Grid
          item
          xs={3}
          className='grid_item'
        >
          <Box className='shift'>
            <ShiftIcon />
            {translatedShift(item?.shift)}
          </Box>
        </Grid>

        <Grid
          item
          xs={3}
          className='grid_item'
        >
          <Box className='students_qtde'>
            <StudentLightIcon />
            {item.students.length}
            {item.students.length > 1 ? ' alunos' : ' aluno'}
            <IconButton
              sx={{ width: '18px', height: '18px', marginLeft: '15px' }}
            >
              <KeyboardArrowDownIcon
                fontSize='small'
                sx={{ transform: openCollapse ? 'rotate(180deg)' : 'rotate(0deg)' }}
              />
            </IconButton>
          </Box>
        </Grid>
      </GridContainer>

      <Collapse in={openCollapse}>
        <StudentsContainer>
          <>
            <Box className='btn_container'>
              <Button
                type='button'
                variant='contained'
                className='btn_add'
                startIcon={<PlusIcon />}
                onClick={() => handleAddStudent({
                  classroom: { id: item.id, name: item.name },
                  grade: item.grade
                })}
              >
                Adicionar aluno(a)
              </Button>

              <Button
                type='button'
                variant='contained'
                className='btn_migration'
                startIcon={<MigrationIcon />}
                onClick={handleOpenMigrateModal}
              >
                Migração
              </Button>
            </Box>

            {item.students.length === 0 && (
              <EmptyBox>
                <Box className='image'>
                  <img src={NoStudentsImage} alt='' />
                </Box>

                <Typography className='message'>
                  Ainda não existem alunos cadastrados nessa turma
                </Typography>
              </EmptyBox>
            )}

            {item.students.length > 0 && (
              <>
                <Grid container className='table_header' columnSpacing={2}>
                  <Grid item xs={5} className='thead'>
                    <Box component='span' className='label'>
                      Alunos
                    </Box>
                  </Grid>

                  <Grid item xs={2} className='thead'>
                    <Box component='span' className='label'>
                      Contato
                    </Box>
                  </Grid>

                  <Grid item xs={3} className='thead'>
                    <Box component='span' className='label'>
                      E-mail
                    </Box>
                  </Grid>

                  <Grid item xs={2} className='thead'>
                    <Box component='span' className='label'>
                      Ações
                    </Box>
                  </Grid>
                </Grid>

                <Grid container className='table_body' rowSpacing={3} columnSpacing={2}>
                  {item?.students?.map((student) => {
                    return (
                      <Fragment key={student.id}>
                        <Grid item xs={5} className='tbody first'>
                          <Box className='teacher_name'>
                            <Avatar
                              alt={student.name}
                              variant='rounded'
                              {...stringAvatar(student?.name, 34, 34, 14)}
                            />
                            <Tooltip title={student?.name}>
                              <Typography>{student?.name}</Typography>
                            </Tooltip>
                          </Box>
                        </Grid>

                        <Grid item xs={2} className='tbody'>
                          <Tooltip title={student?.phone}>
                            <Box className='contact'>
                              {student?.phone}
                            </Box>
                          </Tooltip>
                        </Grid>

                        <Grid item xs={3} className='tbody'>
                          <Tooltip title={student?.email}>
                            <Box className='email'>
                              {student?.email}
                            </Box>
                          </Tooltip>
                        </Grid>

                        <Grid item xs={2} className='tbody last'>
                          <Box className='actions'>
                            <Tooltip title='Gerar senha temporária'>
                              <IconButton
                                type='button'
                                onClick={() => handleOpenResetPasswordModal({
                                  id: student?.id,
                                  name: student?.name,
                                  email: student?.email,
                                })}
                              >
                                <img src={LockIcon} />
                              </IconButton>
                            </Tooltip>

                            <Tooltip title='Visualizar'>
                              <IconButton
                                type='button'
                                onClick={() => handleOpenViewStudentModal(student)}
                              >
                                <img src={ViewIcon} />
                              </IconButton>
                            </Tooltip>

                            <Tooltip title='Editar'>
                              <IconButton
                                type='button'
                                onClick={() => handleToGoStudentEditPage(student?.id)}
                              >
                                <img src={EditIcon} />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Grid>
                      </Fragment>
                    )
                  })}
                </Grid>
              </>
            )}
          </>
        </StudentsContainer>
      </Collapse>

      {openResetPasswordModal && (
        <ResetPasswordModal
          openModal={openResetPasswordModal}
          closeModal={handleCloseResetPasswordModal}
          user={tempStudent}
        />
      )}

      {openViewStudentModal && (
        <StudentInfoModal
          openModal={openViewStudentModal}
          closeModal={handleCloseViewStudentModal}
          student={studentData}
        />
      )}

      {openMigrateModal && (
        <MigrationModal
          openModal={openMigrateModal}
          closeModal={handleCloseMigrateModal}
          classroom={classroom}
        />
      )}
    </Container>
  )
}

export default StudentCollapse
