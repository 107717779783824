import { Card } from '@mui/material'
import { styled } from '@mui/material/styles'

export const InfoCardContainer = styled('div')(({ theme }) => ({
  border: `1px dashed ${theme.palette.text.secondary}`,
  borderRadius: 16,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '24px',
  marginTop: 32,
  '& .info_container': {
    maxWidth: '1088px',
    width: '100%',

    [theme.breakpoints.down('md')]: {
      maxWidth: '512px'
    }
  }
}))

export const InfoCard = styled(Card)(({ theme }) => ({
  borderRadius: 8,
  height: 201,
  paddingTop: 28,
  textAlign: 'center',
  maxWidth: '247px',
  width: '100%',

  '& p': {
    fontSize: 14,
    '& span': {
      color: theme.palette.primary.light,
      fontWeight: 800
    }
  }
}))

export const HeaderReports = styled('div')(() => ({
  alignItems: 'center',
  display: 'grid',
  gridTemplateColumns: '2fr 1fr',
  columnGap: '32px',
  '.createReports': {
    justifySelf: 'end',
    'button': {
      backgroundColor: '#BC9BFA',
      'svg': {
        marginRight: '8px'
      }
    }
  },
}))
