import { all, call, put, takeLatest } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'
import {
  rejectPromiseAction,
  resolvePromiseAction
} from 'redux-saga-promise-actions'

import * as actions from './actions'
import * as services from './services'
import { showAlertMessage } from '../alert'
import { IUpdateTeacherPayload } from './types'
import { removeNonNumericCharacters } from '../../utils/functions'

function* createTeacherSaga({ payload }: any) {
  try {
    const users = {
      name: payload?.name,
      email: payload?.email,
      taxpayer_number: payload?.taxpayer_number,
      birthday: payload?.birthday,
      phone: payload?.phone,
      genre: payload?.genre?.value,
      additional_data: {
        address: {
          number: payload?.number,
          street: payload?.street,
          zipcode: payload?.zipcode,
          complement: payload?.complement,
          neighborhood: payload?.neighborhood
        }
      }
    }

    const teacherAttributes = payload?.classrooms?.flatMap((item: any) => item.subject_ids.map((elem: any) => ({
      education_subject_id: elem?.id,
      school_classroom_id: item?.classroom?.id,
      education_grade_id: item?.classroom?.grade?.id,
      school_unit_id: payload?.school_unit_id
    })))

    const data = {
      users,
      teachers_attributes: teacherAttributes,
      roles_users_attributes: {
        user_role_id: 5,
        user_application_id: payload?.app_id
      }
    }

    const response: AxiosResponse = yield call(services.createTeacherService, data)

    if (response.status === 201) {
      yield put(showAlertMessage({
        message: 'Professor criado com sucesso.',
        severity: 'success',
        duration: 5000
      }))
      yield put(actions.createTeacherSuccess())
    }
  } catch (error: any) {
    const errorMessageCpfAndEmail = 'A validação falhou: Email já está em uso, Taxpayer number já está em uso'
    const errorMessageCpf = 'A validação falhou: Taxpayer number já está em uso'
    const errorMessageEmail = 'A validação falhou: Email já está em uso'
    console.error(error)

    let message
    switch (error?.response?.data?.error) {
      case errorMessageCpfAndEmail:
        message = 'CPF e Email já estão em uso.'
        break
      case errorMessageCpf:
        message = 'CPF já está em uso.'
        break
      case errorMessageEmail:
        message = 'Email já está em uso'
        break
      default:
        message = 'Erro ao criar professor.'
        break
    }

    yield put(showAlertMessage({
      message,
      severity: 'error',
      duration: 5000
    }))
    yield put(actions.createTeacherFailure(message))
  }
}

function* updateTeacherSaga({ payload }: IUpdateTeacherPayload) {
  try {
    let requestBody
    if (payload?.radioClassroomId) {
      const teacherAttributes = payload?.teacher?.subjects?.map((subject) => {
        return {
          school_classroom_id: payload?.radioClassroomId,
          education_subject_id: subject?.id,
          school_unit_id: payload?.selectedUnit?.id,
          education_grade_id: payload.teacher?.grade?.id
        }
      })
      requestBody = {
        id: payload?.teacher?.user?.id,
        data: {
          users: {
            name: payload?.teacher?.user?.name,
            email: payload?.teacher?.user.email,
            taxpayer_number: payload?.teacher?.user?.taxpayer_number,
            birthday: payload?.teacher?.user?.birthday,
            genre: payload?.teacher?.user?.genre,
            phone: payload?.teacher?.user?.phone,
            additional_data: {
              address: {
                number: payload?.teacher?.user?.additional_data?.address?.number,
                street: payload?.teacher?.user?.additional_data?.address?.street,
                zipcode: payload?.teacher?.user?.additional_data?.address?.zipcode,
                complement: payload?.teacher?.user?.additional_data?.address?.complement,
                neighborhood: payload?.teacher?.user?.additional_data?.address?.neighborhood
              }
            }
          },
          teachers_attributes: teacherAttributes,
          roles_users_attributes: {
            user_role_id: 5
          }
        }

      }


    } else {
      const teacherAttributes = payload?.classrooms?.flatMap((item: any) => item.subject_ids.map((elem: any) => ({
        education_subject_id: elem.id,
        school_classroom_id: item.classroom.id,
        education_grade_id: item.classroom.grade.id,
        school_unit_id: payload?.school_unit_id
      })))

      requestBody = {
        id: payload.id,
        data: {
          users: {
            name: payload.name,
            email: payload.email,
            genre: payload.genre.value,
            taxpayer_number: payload.taxpayer_number && removeNonNumericCharacters(payload.taxpayer_number),
            birthday: payload.birthday,
            phone: payload.phone,
            additional_data: {
              address: {
                number: payload.number,
                street: payload.street,
                zipcode: payload.zipcode,
                complement: payload.complement,
                neighborhood: payload.neighborhood
              }
            }
          },
          teachers_attributes: teacherAttributes,
          roles_users_attributes: {
            user_role_id: 5
          }
        }
      }
    }

    const response: AxiosResponse = yield call(services.updateTeachersService, requestBody)
    if (response.status === 200) {
      yield put(showAlertMessage({
        message: 'Professor atualizado com sucesso.',
        severity: 'success',
        duration: 5000
      }))
      yield put(actions.updateTeacherSuccess())
    }
  } catch (error: any) {

    const errorMessageCpfAndEmail = 'A validação falhou: Email já está em uso, Taxpayer number já está em uso'
    const errorMessageCpf = 'A validação falhou: Taxpayer number já está em uso'
    const errorMessageEmail = 'A validação falhou: Email já está em uso'

    let message
    switch (error?.response?.data?.error) {
      case errorMessageCpfAndEmail:
        message = 'CPF e Email já estão em uso.'
        break
      case errorMessageCpf:
        message = 'CPF já está em uso.'
        break
      case errorMessageEmail:
        message = 'Email já está em uso'
        break
      default:
        message = 'Erro ao atualizar dados.'
        break
    }

    yield put(showAlertMessage({
      message,
      severity: 'error',
      duration: 5000
    }))
    yield put(actions.updateTeacherFailure(message))
  }
}

function* getAllTeachersSaga({ payload }: any) {
  try {
    const params = {
      ...payload
    }
    const response: AxiosResponse = yield call(services.getAllTeachersService, { params })
    const pagination = {
      totalOfPages: Number(response.headers['x-total-pages']),
      current: Number(response.headers['x-page']),
      total: Number(response.headers['x-total-count'])
    }

    if (response.status === 200) {
      yield put(actions.getAllTeachersSuccess(response.data.data))
      yield put(actions.teachersChangePagination(pagination))
    }
  } catch (error) {
    yield put(actions.getAllTeachersFailure())
  }
}

function* getTeacherByIdSaga({ payload }: any) {
  try {
    const response: AxiosResponse = yield call(services.getTeacherByIdService, payload)
    if (response.status === 200) {
      yield put(actions.getTeacherByIdSuccess(response.data))
    }
  } catch (error) {
    yield put(actions.getTeacherByIdFailure())
  }
}

function* getSearchTeacherByIdSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(services.getTeacherByIdService, action.payload)

    if (response.status === 200) {
      resolvePromiseAction(action, response)
      yield put(actions.getTeacherById.success(response))
    }
  } catch (error) {
    rejectPromiseAction(action, error)
    yield put(actions.getTeacherById.failure(error))
    console.error(error)
  }
}

function* watchCreateTeachersSaga() {
  yield takeLatest(actions.createTeacherRequest.type, createTeacherSaga)
}

function* watchUpdateTeachersSaga() {
  yield takeLatest(actions.updateTeacherRequest.type as any, updateTeacherSaga)
}

function* watchGetAllTeachersSaga() {
  yield takeLatest(actions.getAllTeachersRequest.type, getAllTeachersSaga)
}

function* watchGetTeacherByIdSaga() {
  yield takeLatest(actions.getTeacherByIdRequest.type, getTeacherByIdSaga)
}

function* watchGetSearchTeacherById() {
  yield takeLatest(actions.getTeacherById.request, getSearchTeacherByIdSaga)
}

export default function* teachersSagas() {
  yield all([
    watchCreateTeachersSaga(),
    watchUpdateTeachersSaga(),
    watchGetAllTeachersSaga(),
    watchGetTeacherByIdSaga(),
    watchGetSearchTeacherById()
  ])
}
