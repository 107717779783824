import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Container = styled(Box)(({ theme }) => ({
  '& .title': {
    alignItems: 'center',
    display: 'flex',
    gap: '16px',
    marginBottom: '18px',

    '& h2': {
      color: theme.palette.text.primary,
      fontSize: '18px',
      fontWeight: 600,
      letterSpacing: '0.18px'
    }
  },

  '& .form_container': {
    borderRadius: '16px',
    padding: '32px 16px',

    '& .required': {
      color: '#9f9f9f',
      fontSize: '14px',
      fontStyle: 'italic',
      marginTop: '20px',
      textAlign: 'right',
    }
  },

  '& .primary_title': {
    color: theme.palette.text.primary,
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '120%',
  },

  '& .secondary_title': {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '120%',
    marginBottom: '16px',
  },

  '& .student_form': {
    margin: '32px 0 56px',
  },

  '& .field': {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',

    '& > label': {
      color: '#9f9f9f',
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: '0.14px',
    }
  },

  '& .academic_form': {
    marginBottom: '56px',
  },

  '& .parent_form': {

    '& .title': {
      color: theme.palette.text.primary,
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '120%',
      marginBottom: '8px !important',
    },

    '& .form': {
      display: 'flex',
      flexDirection: 'column',
      gap: '24px',
    }
  }
}))

export const ParentFormContainer = styled(Box)(({ theme }) => ({
  background: '#f9f9f9',
  borderRadius: '8px',
  padding: '30px',
  position: 'relative',

  '& .btn_remove': {
    background: theme.palette.error.main,
    height: '18px',
    position: 'absolute',
    right: '10px',
    top: '10px',
    transition: 'background 0.3s ease-in-out',
    width: '18px',

    '& .icon': {
      color: '#fff',
      fontSize: '20px'
    },

    '&:hover': {
      background: '#e95c5c'
    }
  },

  '& .field': {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',

    '& > label': {
      color: '#9f9f9f',
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: '0.14px',
    },

    '& .MuiInputBase-root': {
      background: '#fff',
    }
  },

  '& .address_form': {
    marginTop: '24px'
  }
}))

export const BtnContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '24px',
  // paddingRight: '41px',

  '& .btn_add': {
    background: theme.palette.primary.light,
    borderRadius: '8px',
    padding: '10px 24px',
    textTransform: 'none',
    transition: 'background 0.3s ease-in-out',

    '&:hover': {
      background: '#a782ec',
    }
  }
}))

export const BtnBox = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  gap: '16px',
  justifyContent: 'flex-end',
  marginTop: '32px',

  '& .back_btn': {
    borderRadius: '8px',
    borderColor: '#b7b2c8',
    color: '#b7b2c8',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '120%',
    padding: '14.5px 47.5px',
    textTransform: 'capitalize'
  },

  '& .submit_btn': {
    borderRadius: '8px',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '120%',
    padding: '14.5px 39px',
    textTransform: 'capitalize'
  }
}))

