import { createAction } from '@reduxjs/toolkit'
import { withPayloadType } from '../../utils/functions'
import { ITeacherFormValues } from '../../models/ITeacher'
import { createPromiseAction } from 'redux-saga-promise-actions'

export interface GetAllTeachersRequestPayload {
  school_unit_id: any
  page?: number
  per?: number
}
export interface GetAllTeachersSuccessPayload {
  data: any
}

export interface GetTeacherByIdRequestPayload {
  id?: number
  schoolUnitId?: number
}
export interface GetTeacherByIdSuccessPayload {
  data: any

}

export interface ChangePaginationPayload {
  total: number
  totalOfPages: number
  current: number
}

export interface CreateUserTeacherRequestPayload extends ITeacherFormValues {
  id?: number
  school_unit_id?: number
  app_id?: number
}

export const createTeacherRequest = createAction('teachers/CREATE_TEACHER_REQUEST', withPayloadType<CreateUserTeacherRequestPayload>())
export const createTeacherSuccess = createAction('teachers/CREATE_TEACHER_SUCCESS')
export const createTeacherFailure = createAction<string>('teachers/CREATE_TEACHER_FAILURE')

export const updateTeacherRequest = createAction('teachers/UPDATE_TEACHER_REQUEST', withPayloadType<any>())
export const updateTeacherSuccess = createAction('teachers/UPDATE_TEACHER_SUCCESS')
export const updateTeacherFailure = createAction<string>('teachers/UPDATE_TEACHER_FAILURE')

export const getAllTeachersRequest = createAction('teachers/GET_ALL_TEACHERS_REQUEST', withPayloadType<GetAllTeachersRequestPayload>())
export const getAllTeachersSuccess = createAction('teachers/GET_ALL_TEACHERS_SUCCESS', withPayloadType<GetAllTeachersSuccessPayload>())
export const getAllTeachersFailure = createAction('teachers/GET_ALL_TEACHERS_FAILURE')

export const getTeacherByIdRequest = createAction('teachers/GET_TEACHER_BY_ID_REQUEST', withPayloadType<GetTeacherByIdRequestPayload>())
export const getTeacherByIdSuccess = createAction('teachers/GET_TEACHER_BY_ID_SUCCESS', withPayloadType<GetTeacherByIdSuccessPayload>())
export const getTeacherByIdFailure = createAction('teachers/GET_TEACHER_BY_ID_FAILURE')

export const getTeacherById = createPromiseAction('teachers:rspa/GET_TEACHER_BY_ID')()

export const teachersChangePagination = createAction('teachers:TEACHERS_CHANGE_PAGINATION', withPayloadType<ChangePaginationPayload>())

export const resetAllTeachers = createAction('teachers/TEACHERS_RESET_DATA')
export const resetTeacher = createAction('teachers/TEACHER_RESET_DATA')
