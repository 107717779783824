import React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { AsyncPaginate, LoadOptions } from 'react-select-async-paginate'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { DefaultOptionType } from '../../utils/types'
import http from '../../utils/http'
import { transformDataToOptions } from '../../utils/functions'

type Props = FieldRenderProps<string, any>

const BoxStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',

  '& .label': {
    color: '#9F9F9F',
    marginBottom: '8px',
    fontSize: 14,
    fontWeight: 500,

    '& > span': {
      color: theme.palette.error.main,
    }
  },

  '& > div': {
    fontSize: 14,
    '& .select__control': {
      cursor: 'pointer',
      borderRadius: 8,
      border: '1px solid #D9D9D9',
      minHeight: '40px',
      '& .select__placeholder': {
        color: '#D9D9D9',
        fontWeight: 400
      },
      '& .select__input-container': {
        color: theme.palette.text.primary
      },
      '& .select__dropdown-indicator > svg': {
        height: 16,
        width: 20
      },
      '& .select__multi-value': {
        background: '#FFFFFF',
        border: '0.75px solid #BC9BFA',
        borderRadius: 32,
        height: 20,
        '& .select__multi-value__label': {
          color: '#BC9BFA',
          fontSize: 12,
          fontWeight: 500,
          padding: '0 0 0 6px'
        },
        '& .select__multi-value__remove': {
          '&:hover': {
            background: 'none',
            '& svg': {
              backgroundColor: theme.palette.error.main
            }
          },
          '& svg': {
            fill: '#fff',
            borderRadius: '50%',
            backgroundColor: '#BC9BFA',
            height: 10,
            width: 10
          }
        }
      }
    },
    '& .select__control--is-focused': {
      borderColor: theme.palette.primary.main,
      boxShadow: `0 0 0 1px ${theme.palette.primary.main}`
    }
  }
}))

const AsyncSelectInput = ({
  request,
  options,
  searchParam,
  placeholder,
  input: { onChange, ...restInput },
  meta: { touched, error },
  label,
  helperText,
  ...rest
}: Props) => {
  const loadOptions: LoadOptions<any, any, { page: number }> = async (
    searchQuery,
    loadedOptions,
    page
  ) => {
    if (request?.path) {
      const hasSearchParam = searchParam ? { [searchParam]: searchQuery } : ''
      const requestParams = {
        ...hasSearchParam,
        ...request.params,
        'page': page?.page
      }
      const response = await http.get(request.path, { params: requestParams })
      const newPage = (page?.page && page.page) || 1
      return {
        options: transformDataToOptions(response.data.data || response.data),
        hasMore: response.headers['x-total-pages'] ? newPage < Number(response.headers['x-total-pages']) : false,
        additional: {
          page: newPage + 1
        }
      }
    } else {
      return {
        options: options,
        hasMore: false
      }
    }
  }

  const handleOnChange = (option: DefaultOptionType) => {
    onChange?.(option)
  }

  return (
    <BoxStyled>
      {label &&
        <Typography
          className='label'
          variant='body2'
          component='label'
        >
          {label}
        </Typography>}
      <AsyncPaginate
        classNamePrefix='select'
        key={`${JSON.stringify(restInput.name)}[${rest?.cacheUniqs}]`}
        debounceTimeout={500}
        defaultOptions={false}
        loadOptionsOnMenuOpen
        loadOptions={loadOptions}
        getOptionValue={(option) => option.name || option.label}
        getOptionLabel={(option) => option.name || option.label}
        onChange={handleOnChange}
        isClearable={false}
        isSearchable={true}
        placeholder={placeholder}
        components={{
          IndicatorSeparator: () => null,
        }}
        loadingMessage={() => 'Carregando...'}
        noOptionsMessage={() => 'Não encontrado'}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: 'rgba(217, 217, 217, 0.4)',
          },
        })}
        additional={{
          page: 1
        }}
        {...restInput}
        {...rest}
      />
      <Typography
        align='left'
        component='p'
        variant='caption'
        sx={{
          color: touched && error ? '#F69E9E' : '#D9D9D9'
        }}
      >
        {helperText}
        {touched && error && error}
      </Typography>
    </BoxStyled>
  )
}

export default AsyncSelectInput
