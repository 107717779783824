import { Box, Paper } from '@mui/material'
import { styled } from '@mui/material/styles'

export const PaperStyled = styled(Paper)({
  borderRadius: '16px',
  height: '90%',
  padding: '40px 24px 24px'
})

export const Header = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: '16px',

  '& h2': {
    color: theme.palette.text.primary,
    fontSize: '24px',
    fontWeight: 600,
    letterSpacing: '0.24px',
  }
}))

export const Content = styled(Box)(({ theme }) => ({
  marginTop: '24px',

  '& .description': {
    color: '#9f9f9f',
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '0.14px',
    marginBottom: '56px',
  },

  '& .box_subtitle': {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',

    '& .label': {
      color: theme.palette.text.primary,
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '120%',
    },

    '& .required_fields-message': {
      color: theme.palette.error.main,
      fontSize: '14px',
      fontStyle: 'italic',
    }
  },

  '& .box': {
    alignItems: 'flex-end',
    display: 'flex',
    gap: '24px',
    marginTop: '24px',
  },

  '& .filters_container': {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    gap: '24px',

    '& .filter': {
      display: 'flex',
      flexDirection: 'column',
      flex: 1.5,
      gap: '8px',

      '& label': {
        color: '#9f9f9f',
        fontSize: '14px',
        fontWeight: 500,
        letterSpacing: '0.14px',

        '& span': {
          color: theme.palette.error.main,
        }
      },

      '& .school': {
        '& .select__placeholder': {
          color: '#d9d9d9'
        }
      }
    }
  },

  '& .classroom_container': {
    alignItems: 'center',
    display: 'flex',
    flex: 0.5,
    gap: '24px',
    height: 'fit-content',
    paddingBottom: '9px',

    '& .new_classroom': {
      alignItems: 'center',
      display: 'flex',
      gap: '4px',

      '& > span:first-of-type': {
        color: '#9f9f9f',
        fontSize: '14px',
        fontWeight: 500,
        letterSpacing: '0.14px',
      },

      '& > span:last-of-type': {
        color: theme.palette.primary.light,
        fontSize: '14px',
        fontWeight: 700,
        letterSpacing: '0.14px',
      }
    }
  },

  '& .students_container': {
    background: '#f9f9f9',
    borderRadius: '16px',
    marginTop: '24px',
    maxHeight: '49vh',
    minHeight: '49vh',
    overflow: 'auto',
    padding: '30px',

    '&::-webkit-scrollbar': {
      width: '3px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(173, 184, 204, 1)',
      borderRadius: '4px',
      '&:hover': {
        backgroundColor: '#666',
      },
    },

    '& .title': {
      color: theme.palette.text.primary,
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '120%',
      marginBottom: '24px',
    },

    '& .select_all-box': {
      alignItems: 'center',
      display: 'flex',
      gap: '4px',
      marginBottom: '24px',

      '& .MuiCheckbox-root': {
        padding: 0,
      },

      '& > label': {
        color: theme.palette.text.primary,
        fontSize: '14px',
        fontWeight: 500,
      }
    },

    '& .students': {
      '& .column': {
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
      },

      '& .MuiCheckbox-root': {
        padding: 0,
      },

      '& .student': {
        alignItems: 'center',
        display: 'flex',
        gap: '8px',

        '& > label': {
          alignItems: 'center',
          color: theme.palette.text.primary,
          display: 'flex',
          fontSize: '14px',
          fontWeight: 500,
          gap: '8px',
          lineHeight: '120%',
        }
      }
    }
  },

  '& .btn_container': {
    alignItems: 'center',
    display: 'flex',
    gap: '16px',
    justifyContent: 'flex-end',
    marginTop: '16px',


    '& .btn_back, & .btn_migrate': {
      borderRadius: '8px',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '120%',
      padding: '14.5px 24px',
      textTransform: 'none',
    },

    '& .btn_back': {
      borderColor: '#b7b2c8',
      color: '#b7b2c8',
    }
  }
}))

export const EmptyBox = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  justifyContent: 'center',
  paddingTop: '40px',

  '& .image_container': {
    maxWidth: '350px',
    '& > img': {
      width: '100%'
    }
  },

  '& .description': {
    color: theme.palette.text.primary,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '120%',
  }
}))
