import * as React from 'react'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { useAppSelector } from '../../store/hooks'

import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'

import SuccessMessage from '../message/SuccessMessage'
import Label from '../label/Label'
import StepsIcon from '../../assets/components/activity/steps-icon.svg'
import InfoIcon from '../../assets/components/activity/info.svg'
import SettingsIcon from '../../assets/components/activity/settings-icon.svg'
import ActivitiesSettingsForm, { FormValues } from './form/ActivitiesSettingsForm'
import ActivitiesQuestionsForm from './form/ActivitiesQuestionsForm'
import Tabs from '../tabs/Tabs'
import Tab from '../tabs/Tab'
import Button from '../button/Button'

import { IQuestion } from '../../models/IQuestion'
import { IClassroom } from '../../models/IClassroom'
import { IContent } from '../../models/IContent'
import ActivitiesDestinationForm from './form/ActivitiesDestinationForm'

const NumberIcon = styled('span')(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  borderRadius: '50%',
  color: '#FFF',
  height: 23,
  padding: 3,
  width: 23
}))

const ImgStyle = styled('img')({
  marginRight: 16
})

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3 }}>
          <Typography component='div'>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

type Props = {
  activeTab: number,
  activeStep: number,
  setActiveStep: any
  changeOrder: (value: any, questionId: number) => void,
  formEditValues: FormValues,
  handleChange: (event: React.SyntheticEvent, newValue: number) => void,
  handleDraftSubmit: (values: any) => void,
  handleSubmit: (values: any) => void,
  isAllItemsSelected: (type: string) => boolean | undefined,
  isItemSelected: boolean,
  isClassroomSelected: boolean,
  previous: () => void,
  removeQuestion: (id: any) => void,
  selectAllItems: (type: string) => void,
  selectQuestion: (event: React.ChangeEvent<HTMLInputElement>, question: IQuestion) => void,
  selectContent: (event: React.ChangeEvent<HTMLInputElement>, content: IContent) => void,
  submitActivity: (isDraft?: boolean) => void,
  submitDraftForm: boolean,
  submitForm: boolean,
  toggleCheckboxAllStudents: (event: React.ChangeEvent<HTMLInputElement>, classroom: IClassroom) => void,
  toggleCheckboxClassroom: (event: React.ChangeEvent<HTMLInputElement>, classroom: IClassroom, student: any) => void,
  unitId?: number,
  filters?: any
}

const ActivitiesTab = ({
  activeTab,
  activeStep,
  setActiveStep,
  changeOrder,
  formEditValues,
  handleChange,
  handleDraftSubmit,
  handleSubmit,
  isAllItemsSelected,
  isItemSelected,
  isClassroomSelected,
  previous,
  removeQuestion,
  selectAllItems,
  selectQuestion,
  selectContent,
  submitActivity,
  submitDraftForm,
  submitForm,
  toggleCheckboxAllStudents,
  toggleCheckboxClassroom,
  unitId,
  filters,
}: Props) => {
  const {
    auth: { user },
    persistable: { selectedRole },
    activities,
    questions,
    contents,
    modules,
    exams
  } = useAppSelector(state => state)

  return (
    <Box sx={{ width: '100%' }}>
      {activities.isSuccess && !activities.isSavingOrUpdating && (
        <SuccessMessage
          type='activities'
          path='activities'
          isDraft={submitDraftForm}
          isEdit={Boolean(formEditValues?.id)}
        />
      )}
      {!activities.isSuccess && (
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ padding: '9px 0', width: 140 }}>
              <Label><ImgStyle src={StepsIcon} alt='icone das etapas' width={20} height={16} /> Etapas:</Label>
            </Box>
            <Tabs sx={{ width: 'calc(100% - 140px)' }} value={activeTab} variant='fullWidth' onChange={handleChange} aria-label='ant example'>
              <Tab icon={<NumberIcon>1</NumberIcon>} iconPosition='start' label='Configurações' />
              <Tab icon={<NumberIcon>2</NumberIcon>} iconPosition='start' disabled={activeTab < 1} label='Questões' />
              <Tab icon={<NumberIcon>3</NumberIcon>} iconPosition='start' disabled={activeTab < 2} label='Destinatários' />
            </Tabs>
          </Box>
          <Form
            initialValues={formEditValues}
            mutators={{ ...arrayMutators }}
            onSubmit={handleSubmit}
            render={({ handleSubmit, submitting, values, form, errors, invalid }) => {
              return (<Box sx={{ pt: 3 }}>
                {activeTab === 0 && <TabPanel value={activeTab} index={0}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%'
                      }}
                    >
                    <Label><ImgStyle src={InfoIcon} alt='icone de informações' width={20} height={20} />Informações iniciais:</Label>
                    </Box>
                    {activeStep === 2 && (
                      <Box
                        sx={{
                          display: 'flex',
                          gap: '16px'
                        }}
                      >
                        <Button
                          variant='outlined'
                          onClick={
                            activeStep === 2 ?
                              () => setActiveStep(1) : previous
                          }
                        >
                          Voltar
                        </Button>
                        <Button
                          onClick={handleSubmit}
                          disabled={invalid || !isItemSelected}
                        >
                          Avançar
                        </Button>
                      </Box>
                    )}
                  </Box>
                  <ActivitiesSettingsForm
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    isSchoolQuestions={questions.isSchoolQuestions}
                    questions={questions}
                    selectedRole={selectedRole}
                    userId={user?.id}
                    previous={previous}
                    handleSubmit={handleSubmit}
                    initialValues={formEditValues}
                    values={values}
                    selectedQuestions={questions.selectedQuestions}
                    selectContent={selectContent}
                    selectQuestion={selectQuestion}
                    selectAllItems={selectAllItems}
                    isAllItemsSelected={isAllItemsSelected}
                    isItemSelected={isItemSelected}
                    contents={contents}
                    modules={modules}
                    exams={exams}
                    filters={filters}
                    change={form.change}
                    errors={errors}
                    unitId={unitId}
                    invalid={invalid}
                  />
                </TabPanel>}
                {activeTab === 1 && <TabPanel value={activeTab} index={1}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <Label><ImgStyle src={InfoIcon} alt='icone de informações' width={20} height={20} />Seleção das questões</Label>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        paddingRight: '16px',
                        gap: '16px'
                      }}
                    >
                      <Button
                        variant='outlined'
                        onClick={previous}
                      >
                        Voltar
                      </Button>
                      <Button
                        onClick={handleSubmit}
                        disabled={invalid || !isItemSelected}
                      >
                        Avançar
                      </Button>
                    </Box>
                  </Box>
                  <ActivitiesQuestionsForm
                    removeQuestion={removeQuestion}
                    questions={questions}
                    selectedRole={selectedRole}
                    user={user}
                    previous={previous}
                    submitting={submitting}
                    handleSubmit={handleSubmit}
                    values={values}
                    changeOrder={changeOrder}
                    change={form.change}
                    initialValues={formEditValues}
                  />
                </TabPanel>}
                {activeTab === 2 && <TabPanel value={activeTab} index={2}>
                  <Box
                  sx={{width:'100%', display:'flex', justifyContent:'space-between'}}>
                  <Label><ImgStyle src={SettingsIcon} alt='icone de configurações' width={20} height={20} />Configurações</Label>
                  <Typography
                    sx={{
                      color:'#ff0000',
                      fontSize:'12px'
                    }}
                  >
                      Campos obrigatórios *
                  </Typography>
                  </Box>
                  <ActivitiesDestinationForm
                    questions={questions}
                    toggleCheckboxClassroom={toggleCheckboxClassroom}
                    toggleCheckboxAllStudents={toggleCheckboxAllStudents}
                    selectedClassrooms={questions.classrooms.selected}
                    submitActivity={submitActivity}
                    isSubmitDraft={submitDraftForm}
                    isSubmitForm={submitForm}
                    isClassroomSelected={isClassroomSelected}
                    handleDraftSubmit={handleDraftSubmit}
                    previous={previous}
                    handleSubmit={handleSubmit}
                    values={values}
                    unitId={unitId}
                    invalid={invalid}
                  />
                </TabPanel>}
              </Box>)
            }}
          />
        </Box>
      )}
    </Box>
  )
}

export default ActivitiesTab
