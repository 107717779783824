import React, { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Components
import {
  Avatar,
  Box,
  Checkbox,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material'
import DialogModal from '../../Modal/DialogModal'
import ErrorMessage from '../../message/ErrorMessage'
import ResetPasswordModal from '../modal/ResetPasswordModal'

// Redux
import { getTeacherByIdRequest, resetTeacher } from '../../../store/teachers/actions'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'


// Utils
import { cpfFormatter, stringAvatar } from '../../../utils/functions'
import { format } from 'date-fns'
import { IUserTeacher } from '../../../models/ITeacher'

// Icons and images
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import DataIcon from '../../../assets/components/ManagersPage/data-icon.svg'
import EditIcon from '../../../assets/components/ManagersPage/pencil-icon.svg'
import LockIcon from '../../../assets/components/ManagersPage/lock-icon.svg'
import ViewIcon from '../../../assets/components/ManagersPage/eye-icon.svg'
import { ReactComponent as ShiftIcon } from '../../../assets/components/ManagersPage/shift-icon.svg'
import { ReactComponent as StudentLightIcon } from '../../../assets/components/ManagersPage/students_light-icon.svg'

// Styles
import {
  BadgeBox,
  Container,
  GridContainer,
  InfoDataContainer,
  LoadingBox,
  TeachersContainer
} from './styles/TeacherCollapse.styles'

interface ITeacherData {
  id: number
  name: string
  email: string
}

interface ITeacherCollapseProps {
  item: IUserTeacher
}

const TeacherCollapse: React.FC<ITeacherCollapseProps> = ({ item }) => {
  const [open, setOpen] = useState(false)
  const [checkedAll, setCheckedAll] = useState(false)
  const [teacherData, setTeacherData] = useState<ITeacherData>({} as ITeacherData)
  const [openViewTeacherModal, setOpenViewTeacherModal] = useState(false)
  const [openResetTempPasswordModal, setOpenResetTempPasswordModal] = useState(false)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { selectedUnit } = useAppSelector(state => state.persistable)
  const { currentItem } = useAppSelector(state => state.teachers)

  const translatedShift = (shift?: string) => {
    let text = ''
    switch (shift) {
      case 'morning':
        text = 'Manhã'
        break
      case 'afternoon':
        text = 'Vespertino'
        break
      case 'night':
        text = 'Noturno'
        break
      case 'fulltime':
        text = 'Integral'
        break
      default:
        text = 'Sem turno'
        break
    }
    return text
  }

  const checkGenre = (genre?: string | null) => {
    if (genre === 'male') {
      return 'Masculino'
    } else if (genre === 'female') {
      return 'Feminino'
    } else {
      return '-'
    }
  }

  const handleOpenCollapse = () => setOpen(prevState => !prevState)

  const handleCheckAllTeachers = () => {
    setCheckedAll(prevState => !prevState)
  }

  const handleToEditPage = (id: number) => {
    navigate(`/manage-teachers/${id}/edit`)
  }

  const handleOpenTeacherModal = (id: number) => {
    setOpenViewTeacherModal(true)
    if (id) {
      dispatch(getTeacherByIdRequest({
        id,
        schoolUnitId: selectedUnit?.id
      }))
    }
  }

  const handleCloseTeacherModal = () => {
    setOpenViewTeacherModal(false)
    dispatch(resetTeacher())
  }

  const handleOpenResetTempPasswordModal = (teacher: ITeacherData) => {
    setOpenResetTempPasswordModal(true)
    setTeacherData(teacher)
  }

  const handleCloseResetTempPasswordModal = () => setOpenResetTempPasswordModal(false)

  return (
    <Container>
      <GridContainer container>
        <Grid
          item xs={3}
          className='grid_item'
          onClick={handleOpenCollapse}
        >
          <Box className='classroom_name'>
            {item?.name}
          </Box>
        </Grid>

        <Grid
          item xs={3}
          className='grid_item'
          onClick={handleOpenCollapse}
        >
          <Box className='shift'>
            <ShiftIcon />
            {translatedShift(item?.shift)}
          </Box>
        </Grid>

        <Grid
          item xs={3}
          className='grid_item'
        >
          <Box className='select_all'>
            <Checkbox
              onChange={handleCheckAllTeachers}
              checked={checkedAll}
              id='all'
            />
            <label htmlFor='all'>Selecionar todos</label>
          </Box>
        </Grid>

        <Grid
          item xs={3}
          className='grid_item'
          onClick={handleOpenCollapse}
        >
          <Box className='teachers_qtde'>
            <StudentLightIcon />
            {item.teachers.length}
            {item.teachers.length > 1 ? ' professores' : ' professor'}
            <IconButton
              sx={{ width: '18px', height: '18px', marginLeft: '15px' }}
            >
              <KeyboardArrowDownIcon
                fontSize='small'
                sx={{ transform: open ? 'rotate(180deg)' : 'rotate(0deg)' }}
              />
            </IconButton>
          </Box>
        </Grid>
      </GridContainer>

      <Collapse in={open}>
        <TeachersContainer>
          <Grid container className='table_header' columnSpacing={2}>
            <Grid item xs={5} className='thead'>
              <Box component='span' className='label'>
                Professores
              </Box>
            </Grid>

            <Grid item xs={2} className='thead'>
              <Box component='span' className='label'>
                Disciplina
              </Box>
            </Grid>

            <Grid item xs={3} className='thead'>
              <Box component='span' className='label'>
                E-mail
              </Box>
            </Grid>

            <Grid item xs={2} className='thead'>
              <Box component='span' className='label'>
                Ações
              </Box>
            </Grid>
          </Grid>

          <Grid container className='table_body' rowSpacing={3} columnSpacing={2}>
            {item?.teachers?.map((teacher) => (
              <Fragment key={teacher?.user?.id}>
                <Grid item xs={5} className='tbody first'>
                  <Box className='teacher_name'>
                    <Checkbox id={String(teacher?.user?.id)} />
                    <label htmlFor={String(teacher?.user?.id)}>
                      <Avatar
                        alt={teacher?.user?.name}
                        variant='rounded'
                        {...stringAvatar(teacher?.user?.name, 34, 34, 14)}
                      />
                      <Typography>{teacher?.user?.name}</Typography>
                    </label>
                  </Box>
                </Grid>

                <Grid item xs={2} className='tbody'>
                  {teacher?.subjects?.length > 2
                    ? (
                      teacher?.subjects?.slice(0, 2)?.map((subject, index) => {
                        if (index === 1) {
                          return (
                            <Tooltip
                              key={subject?.id}
                              title={teacher?.subjects?.map(subject => subject.name).join(', ')}
                            >
                              <Box className='subject'>
                                {subject?.name}, <BadgeBox>+{teacher?.subjects?.length - 2}</BadgeBox>
                              </Box>
                            </Tooltip>
                          )
                        }

                        return (
                          <Box key={subject?.id} className='subject'>
                            {subject?.name}
                          </Box>
                        )
                      })
                    ) : (
                      teacher?.subjects?.map(subject => (
                        <Box key={subject?.id} className='subject'>
                          {subject?.name}
                        </Box>
                      ))
                    )}
                </Grid>

                <Grid item xs={3} className='tbody'>
                  <Box className='email'>
                    {teacher?.user?.email}
                  </Box>
                </Grid>

                <Grid item xs={2} className='tbody last'>
                  <Box className='actions'>
                    <Tooltip title='Gerar senha temporária'>
                      <IconButton
                        type='button'
                        onClick={() => handleOpenResetTempPasswordModal({
                          id: teacher.user.id,
                          name: teacher.user.name,
                          email: teacher.user.email
                        })}
                      >
                        <img src={LockIcon} />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title='Visualizar'>
                      <IconButton
                        type='button'
                        onClick={() => handleOpenTeacherModal(teacher.user.id)}
                      >
                        <img src={ViewIcon} />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title='Editar'>
                      <IconButton
                        type='button'
                        onClick={() => handleToEditPage(teacher.user.id)}
                      >
                        <img src={EditIcon} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Grid>
              </Fragment>
            ))}
          </Grid>
        </TeachersContainer>
      </Collapse>

      {openResetTempPasswordModal && (
        <ResetPasswordModal
          openModal={openResetTempPasswordModal}
          closeModal={handleCloseResetTempPasswordModal}
          user={teacherData}
        />
      )}

      <DialogModal
        open={openViewTeacherModal}
        handleClose={handleCloseTeacherModal}
        height='800px'
        width='1200px'
      >
        {currentItem.isFetching && (
          <LoadingBox>
            <CircularProgress size={40} />
            <Typography>Carregando dados...</Typography>
          </LoadingBox>
        )}

        {!currentItem.isFetching && !currentItem.isError && (
          <InfoDataContainer>
            <Box className='primary_title'>
              <img src={DataIcon} />
              <Typography component='h3' className='title'>
                Dados cadastrais
              </Typography>
            </Box>

            <Typography
              component='h4'
              className='secondary_title'
            >
              Professor
            </Typography>

            <Typography className='subtitle'>Dados pessoais</Typography>

            <Grid container columnSpacing={4} rowSpacing={4}>
              <Grid item xs={3}>
                <Box className='item'>
                  <Typography className='label'>Nome do aluno</Typography>
                  <Typography
                    className='data name'
                    title={currentItem?.item?.user?.name}
                  >
                    {currentItem?.item?.user?.name}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={3}>
                <Box className='item'>
                  <Typography className='label'>CPF</Typography>
                  <Typography className='data'>
                    {currentItem?.item?.user?.taxpayer_number
                      ? cpfFormatter(currentItem?.item?.user?.taxpayer_number)
                      : '-'
                    }
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={3}>
                <Box className='item'>
                  <Typography className='label'>E-mail</Typography>
                  <Typography
                    className='data email'
                    title={currentItem?.item?.user?.email}
                  >
                    {currentItem?.item?.user?.email}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={3}>
                <Box className='item'>
                  <Typography className='label'>Contato</Typography>
                  <Typography className='data'>{currentItem?.item?.user?.phone ?? '-'}</Typography>
                </Box>
              </Grid>

              <Grid item xs={3}>
                <Box className='item'>
                  <Typography className='label'>Data de nascimento</Typography>
                  <Typography className='data'>
                    {currentItem?.item?.user?.birthday
                      ? format(new Date(currentItem?.item?.user?.birthday), 'dd/MM/yyyy')
                      : '--/--/----'
                    }
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={3}>
                <Box className='item'>
                  <Typography className='label'>Sexo biológico</Typography>
                  <Typography className='data'>{checkGenre(currentItem?.item?.user?.genre)}</Typography>
                </Box>
              </Grid>

              <Grid item xs={3}>
                <Box className='item'>
                  <Typography className='label'>Turma</Typography>
                  <Typography
                    className='data subject'
                    title={currentItem?.item?.user?.classrooms?.map((classroom) => (
                      classroom.classroom.name
                    ))?.join(' / ')}
                  >
                    {currentItem?.item?.user?.classrooms?.map((classroom) => (
                      classroom.classroom.name
                    ))?.join(' / ') ?? '-'}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={3}>
                <Box className='item'>
                  <Typography className='label'>Fractal ID</Typography>
                  <Typography className='data'>{currentItem?.item?.user?.fractal_id}</Typography>
                </Box>
              </Grid>
            </Grid>

            <Typography className='subtitle' sx={{ marginTop: '66px' }}>Endereço</Typography>

            <Grid container rowSpacing={4} columnSpacing={4}>
              <Grid item xs={3}>
                <Box className='item'>
                  <Typography className='label'>CEP</Typography>
                  <Typography className='data'>
                    {currentItem?.item?.user?.additional_data?.address?.zipcode ?? '-'}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={9}>
                <Box className='item'>
                  <Typography className='label'>Rua</Typography>
                  <Typography className='data'>
                    {currentItem?.item?.user?.additional_data?.address?.street ?? '-'}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={3}>
                <Box className='item'>
                  <Typography className='label'>Bairro</Typography>
                  <Typography className='data'>
                    {currentItem?.item?.user?.additional_data?.address?.neighborhood ?? '-'}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={3}>
                <Box className='item'>
                  <Typography className='label'>Número</Typography>
                  <Typography className='data'>
                    {currentItem?.item?.user?.additional_data?.address?.number ?? '-'}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={3}>
                <Box className='item'>
                  <Typography className='label'>Complemento</Typography>
                  <Typography className='data'>
                    {currentItem?.item?.user?.additional_data?.address?.complement ?? '-'}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </InfoDataContainer>
        )}

        {!currentItem.isFetching && currentItem.isError && (
          <InfoDataContainer>
            <ErrorMessage />
          </InfoDataContainer>
        )}
      </DialogModal>
    </Container>
  )
}

export default TeacherCollapse
