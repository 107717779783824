import React, { useState, useEffect, ChangeEvent } from 'react'
import { isEmpty } from 'lodash'
import { Box, CircularProgress, Menu, MenuItem, Pagination, Typography } from '@mui/material'
import MaterialButton from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import { ReactComponent as OrderByIcon } from '../../../assets/components/Books/orderby-icon.svg'
import SelectInput from '../../select/SelectInput'
import BookCard from '../card/BookCard'
import NoItemsCard from '../../card/NoItemsCard'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { clearBooksFilters, fetchBooksRequest } from '../../../store/books/actions'
import { Books } from '../../../models/IBooks'
import { scrollToTop } from '../../../utils/functions'
import { ReactComponent as NoOptionImage } from '../../../assets/components/Books/no_books-image.svg'

const OrderByBtn = styled(MaterialButton)(({ theme }) => ({
  border: `1px solid ${theme.palette.text.secondary}`,
  borderRadius: 8,
  color: theme.palette.text.primary,
  fontSize: 12,
  textTransform: 'none',
  '& svg': {
    marginRight: 8,
    '& path': {
      fill: theme.palette.secondary.main
    }
  },
  [theme.breakpoints.down(906)]: {
    border: 'none',
    minWidth: '35px',
    padding: 0,
    '& svg': {
      marginRight: 0,
      '& path': {
        fill: '#9F9F9F'
      }
    },
  }
}))

const MenuStyle = styled(Menu)({
  '& .MuiMenu-list': {
    border: '1px solid #D9D9D9',
    borderRadius: 8,
    width: 132,
    '& .MuiMenuItem-root': {
      fontSize: 12,
      '&:not(li:last-of-type)': {
        borderBottom: '1px solid #D9D9D9',
      }
    }
  }
})

const BookListHeader = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '24px',

  '& .books_listed': {
    color: theme.palette.primary.main,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '120%'
  },

  '& .filters_container': {
    alignItems: 'center',
    display: 'flex',
    gap: '32px',

    '& .books_perpage_filter': {
      alignItems: 'center',
      display: 'flex',
      gap: '8px',

      '& .label': {
        color: '#666666',
        fontSize: '14px',
        fontWeight: 500
      }
    }
  }
}))

const StatusContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '32px',

  '& .status': {
    alignItems: 'center',
    display: 'flex',
    gap: '8px',

    '&_color': {
      borderRadius: '100%',
      display: 'block',
      height: '14px',
      width: '14px',

      '&.published': {
        background: '#e6f7fa'
      },

      '&.draft': {
        background: '#fce6e6'
      },

      '&.not_published': {
        background: '#F5F0FE'
      }
    },

    '&_description': {
      color: theme.palette.text.primary,
      fontSize: '12px',
      fontWeight: 500,
      letterSpacing: '0.12px'
    }
  }
}))

const BooksListContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  marginTop: '16px'
}))

const LoadingContainer = styled(Box)(({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  padding: '16px'
}))

const PaginationContainer = styled(Box)(({
  marginTop: '32px',
  '& .pagination': {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}))

interface BooksListProps {
  perPage: number
  setPerPage: (value: number) => void
  page: number
  setPage: (value: number) => void
}

const BooksList: React.FC<BooksListProps> = ({ perPage, setPerPage, page, setPage }) => {
  const [anchorOrderByEl, setAnchorOrderByEl] = useState<HTMLElement | null>(null)
  const [order, setOrder] = useState<string>('desc')
  const openOrderBy = Boolean(anchorOrderByEl)
  const dispatch = useAppDispatch()
  const { items, isFetching, pagination, filters } = useAppSelector((state) => state.books)
  const { selectedUnit } = useAppSelector(state => state.persistable)

  useEffect(() => {
    scrollToTop()
    setPage(1)
    dispatch(fetchBooksRequest({
      per: perPage,
      page: 1,
      [order]: true,
      belongs_to_unit_id: selectedUnit?.id
    }))

    return () => {
      dispatch(clearBooksFilters())
    }
  }, [perPage, order, selectedUnit?.id])

  const handleOpenOrderBy = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorOrderByEl(event.currentTarget)
  }

  const handleCloseOrderBy = (event: any) => {
    const value = event.currentTarget.id
    setAnchorOrderByEl(null)
    value !== '' && setOrder(value)
  }

  const handleChangePage = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value)
    dispatch(fetchBooksRequest({ per: perPage, page: value }))
    scrollToTop()
  }

  const handleChangePerPage: React.ChangeEventHandler<HTMLInputElement> = event => {
    setPerPage(Number(event.target.value))
  }

  if (isFetching) {
    return (
      <LoadingContainer>
        <CircularProgress />
      </LoadingContainer>
    )
  }

  return (
    <>
      <Box>
        <BookListHeader>
          <Typography
            component='p'
            variant='body2'
            className='books_listed'
          >
            {pagination.total} livro(s) listado(s)
          </Typography>
          <Box className='filters_container'>
            <Box>
              <OrderByBtn
                id='orderBy'
                aria-controls={openOrderBy ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={openOrderBy ? 'true' : undefined}
                onClick={handleOpenOrderBy}
                variant='outlined'
              >
                <OrderByIcon />{' Ordenar por'}
              </OrderByBtn>
              <MenuStyle
                id='orderByMenu'
                anchorEl={anchorOrderByEl}
                open={openOrderBy}
                onClose={handleCloseOrderBy}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={handleCloseOrderBy} id='asc'>Crescente</MenuItem>
                <MenuItem onClick={handleCloseOrderBy} id='desc'>Decrescente</MenuItem>
              </MenuStyle>
            </Box>

            <Box className='books_perpage_filter'>
              <Typography
                component='span'
                variant='body2'
                className='label'
              >
                Exibir:
              </Typography>
              <SelectInput
                name='selectPage'
                defaultValue={perPage}
                onChange={handleChangePerPage}
                width='150px'
                options={[
                  { label: '1 por página', value: 1 },
                  { label: '5 por página', value: 5 },
                  { label: '10 por página', value: 10 },
                  { label: '15 por página', value: 15 },
                ]}
              />
            </Box>
          </Box>
        </BookListHeader>

        <StatusContainer>
          <Box className='status'>
            <span className='status_color published'></span>
            <span className='status_description published'>Publicado</span>
          </Box>

          <Box className='status'>
            <span className='status_color draft'></span>
            <span className='status_description'>Rascunho</span>
          </Box>

          <Box className='status'>
            <span className='status_color not_published'></span>
            <span className='status_description'>A publicar</span>
          </Box>
        </StatusContainer>
      </Box>

      <BooksListContainer>
        {items && !isEmpty(items)
          ? (
            items && items.length > 0 && items.map((book: Books) => {
              return (
                <Box key={book.id}>
                  <BookCard book={book} />
                </Box>
              )
            })
          )
          : items && isEmpty(items) && Object.keys(filters.query).length > 3
            ? (
              <NoItemsCard>
                <NoOptionImage />
                <h3>Nenhum livro encontrado.</h3>
              </NoItemsCard>
            )
            : (
              <NoItemsCard>
                <NoOptionImage />
                <h3>Ainda <span>não há livros</span> disponíveis para você.</h3>
              </NoItemsCard>
            )
        }
      </BooksListContainer>

      {!isFetching && pagination && pagination.totalOfPages > 1 && (
        <PaginationContainer>
          <Pagination
            count={pagination.totalOfPages}
            size='small'
            color='primary'
            page={page}
            onChange={handleChangePage}
            className='pagination'
          />
        </PaginationContainer>
      )}
    </>
  )
}

export default BooksList
