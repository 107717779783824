import React, { useState } from 'react'
import { Avatar, Box, Checkbox, Collapse, Grid, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Class, ClassroomStudent } from '../../../models/IClassroom'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { ReactComponent as PeopleIcon } from '../../../assets/components/activity/peopleIcon.svg'
import { stringAvatar } from '../../../utils/functions'

const Container = styled(Box)(() => ({
  background: '#fff',
  borderRadius: '8px',
  padding: '8px 20px 8px 32px',
}))

const CollapseButton = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',

  '& .first_item': {
    cursor: 'pointer',
  },

  '& .last_item': {
    alignItems: 'center',
    display: 'flex',
  },

  '& .classroom_name': {
    background: theme.palette.primary.light,
    borderRadius: '8px',
    color: '#f9f9f9',
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: '0.14px',
    padding: '5.5px 18px',
    width: 'fit-content',
  },

  '& .classroom_actions': {
    alignItems: 'center',
    display: 'flex',
    gap: '16px',
    width: '100%',

    '& .checkbox': {
      alignItems: 'center',
      display: 'flex',
      gap: '8px',
      flex: 1,

      '& .MuiCheckbox-root': {
        padding: 0,
      },

      '& label': {
        color: theme.palette.text.primary,
        cursor: 'pointer',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '120%',
      }
    },

    '& .number_of_students': {
      alignItems: 'center',
      color: theme.palette.text.primary,
      cursor: 'pointer',
      display: 'flex',
      fontSize: '14px',
      fontWeight: 500,
      gap: '4px',
      lineHeight: '120%',
    }
  }
}))

const CollapseContainer = styled(Box)(() => ({
  display: 'flex',
  gap: '8px',
  justifyContent: 'space-between',
  marginTop: '24px',
  paddingBottom: '27px',

  '& .students_container': {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  }
}))

const Student = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: '8px',

  '& .MuiCheckbox-root': {
    padding: 0,
  },

  '& .student_name': {
    alignItems: 'center',
    color: theme.palette.text.primary,
    display: 'flex',
    fontSize: '14px',
    fontWeight: 500,
    gap: '8px',
    lineHeight: '120%',
  }
}))

interface IClassroomCollapseProps {
  students: ClassroomStudent[][]
  classroom: Class
  toggleCheckboxClassroom: (
    event: React.ChangeEvent<HTMLInputElement>,
    classroom: Class,
    student: ClassroomStudent
  ) => void
  toggleCheckboxAllStudents: (
    event: React.ChangeEvent<HTMLInputElement>,
    classroom: Class
  ) => void
  verifyStudentChecked: (student: any, classroom: any) => boolean
}

const ClassroomCollapse: React.FC<IClassroomCollapseProps> = ({
  students,
  classroom,
  toggleCheckboxClassroom,
  toggleCheckboxAllStudents,
  verifyStudentChecked
}) => {
  const [open, setOpen] = useState(false)

  const handleOpenCollapse = () => {
    setOpen(prevState => !prevState)
  }

  return (
    <Container>
      <CollapseButton>
        <Grid container className='first_item'>
          <Grid item xs={8} onClick={handleOpenCollapse}>
            <Box className='classroom_name'>{classroom.full_name}</Box>
          </Grid>

          <Grid item xs={4} className='last_item'>
            <Box className='classroom_actions'>
              <Box className='checkbox'>
                <Checkbox
                  onChange={(e) => toggleCheckboxAllStudents(e, classroom)}
                  checked={classroom?.students.length > 0 && classroom?.students.every(student => {
                    return verifyStudentChecked(student, classroom)
                  }
                  )}
                  id={String(classroom.id)}
                />
                <label htmlFor={String(classroom.id)}>Selecionar toda turma</label>
              </Box>

              <Box className='number_of_students' onClick={handleOpenCollapse}>
                <PeopleIcon />
                {classroom.students.length} estudantes
              </Box>

              <IconButton
                onClick={handleOpenCollapse}
                sx={{ cursor: 'pointer', width: '18px', height: '18px' }}
              >
                <KeyboardArrowDownIcon
                  fontSize='small'
                  sx={{ transform: open ? 'rotate(180deg)' : 'rotate(0deg)' }}
                />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </CollapseButton>

      <Collapse in={open}>
        <CollapseContainer>
          {students.map((listOfStudents, index) => {
            return (
              <Box
                key={index}
                className='students_container'
              >
                {listOfStudents.map((student) => {
                  return (
                    <Student key={student.id}>
                      <Checkbox
                        id={String(student.id)}
                        onChange={(e) => toggleCheckboxClassroom(e, classroom, student)}
                        checked={verifyStudentChecked(student, classroom)}
                      />

                      <label htmlFor={String(student.id)} className='student_name'>
                        <Avatar
                          alt={student.name}
                          src={student.photo_url}
                          {...stringAvatar(student?.name, 34, 34, 14)}
                          variant='rounded'
                        />
                        {student.name}
                      </label>
                    </Student>
                  )
                })}
              </Box>
            )
          })}
        </CollapseContainer>
      </Collapse>
    </Container>
  )
}

export default ClassroomCollapse
