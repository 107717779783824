import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// Components
import { Box, LinearProgress, Tooltip, Typography } from '@mui/material'
import Button from '../components/button/Button'
import ErrorMessage from '../components/message/ErrorMessage'
import QuestionCard from '../components/Activities/QuestionCard'

// Redux
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { fetchActivityByIdRequest } from '../store/activities/actions'

// Utils
import { format } from 'date-fns'
import { scrollToTop } from '../utils/functions'

// Icons and images
import { ReactComponent as TitleIcon } from '../assets/components/activity/icon-title.svg'
import SeeMoreIcon from '../assets/components/activity/see-more-icon.svg'

// Styles
import { Container, ErrorMessageBox, Header, LoadingContainer } from './styles/ActivityViewContainer.styles'

interface IReadMoreComponentProps {
  principalName?: string
  allNames?: string
  className?: string
}

const ReadMoreComponent: React.FC<IReadMoreComponentProps> = ({ principalName, allNames, className }) => {
  return (
    <Tooltip title={allNames} arrow>
      <Box sx={{ alignItems: 'center', display: 'inline-flex', gap: '4px' }}>
        <Typography className={className}>{principalName}</Typography>
        <img src={SeeMoreIcon} />
      </Box>
    </Tooltip>
  )
}

interface IOption {
  id: number
  name: string
}

const ActivityViewContainer = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { activity, isFetching, isError } = useAppSelector(state => state.activities)

  const startDate = activity?.start_date ? format(new Date(activity?.start_date), 'dd/MM/yyyy') : '--/--/----'
  const endDate = activity?.end_date ? format(new Date(activity?.end_date), 'dd/MM/yyyy') : '--/--/----'

  const grades = activity?.participants ? (
    activity?.participants?.reduce((acc: IOption[], item) => {
      const index = acc.findIndex((elem: any) => elem.id === item.school_classroom.grade.id)
      if (index === -1) {
        acc.push(item.school_classroom.grade)
      }
      return acc
    }, [])
  ) : (
    activity?.school_participants?.flatMap((elem) => elem.school_classrooms.map(item => item.grade))
  )

  const classrooms = activity?.participants ? (
    activity?.participants?.reduce((acc: IOption[], item) => {
      const index = acc.findIndex((elem: any) => elem.id === item.school_classroom.id)
      if (index === -1) {
        acc.push({
          id: item.school_classroom.id,
          name: item.school_classroom.name
        })
      }
      return acc
    }, [])
  ) : (
    activity?.school_participants?.flatMap((elem) => elem?.school_classrooms?.map(item => ({
      id: item.id,
      name: item.name
    })))
  )

  const modules = activity.modules?.flatMap((item) => item?.module?.content_module_items)

  const handleBackToActivitiesList = () => navigate('/activities')

  useEffect(() => {
    scrollToTop()
    id && dispatch(fetchActivityByIdRequest({ id: Number(id) }))
  }, [])

  if (isFetching) {
    return (
      <LoadingContainer>
        <LinearProgress
          color='secondary'
          className='load'
        />
      </LoadingContainer>
    )
  }

  if (!isFetching && isError) {
    return (
      <ErrorMessageBox>
        <ErrorMessage />
      </ErrorMessageBox>
    )
  }

  return (
    <Box>
      <Header>
        <Box className='title'>
          <TitleIcon />
          <Typography component='h3'>{activity.title} / Visualizar atividade</Typography>
        </Box>

        <Button
          type='button'
          onClick={handleBackToActivitiesList}
        >
          Voltar
        </Button>
      </Header>

      <Container>
        <Box>
          <Typography className='label'>
            Título:
          </Typography>

          <Typography className='title'>
            {activity.title}
          </Typography>
        </Box>

        <Box className='info'>
          <Box className='info_description'>
            <Typography className='label'>Descrição:</Typography>
            <Typography className='description'>{activity.description}</Typography>
          </Box>

          <Box className='info_data'>
            <Box className='first_line'>
              <Typography className='label'>Disciplina:</Typography>
              {activity?.subjects?.length === 1 ? (
                <Typography className='subject'>{activity?.subjects?.[0]?.name ?? ''}</Typography>
              ) : (
                <ReadMoreComponent
                  allNames={activity?.subjects?.map((subject: { id: number, name: string }) => subject.name)?.join(' / ') ?? ''}
                  principalName={activity?.subjects?.[0]?.name ?? ''}
                  className='subject'
                />
              )}
            </Box>

            <Box className='second_line'>
              <Box className='subject_container'>
                <Typography className='label'>Disciplina:</Typography>
                {activity?.subjects?.length === 1 ? (
                  <Typography className='subject'>{activity?.subjects?.[0]?.name ?? ''}</Typography>
                ) : (
                  <ReadMoreComponent
                    allNames={activity?.subjects?.map((subject: { id: number, name: string }) => subject.name)?.join(' / ') ?? ''}
                    principalName={activity?.subjects?.[0]?.name ?? ''}
                    className='subject'
                  />
                )}
              </Box>

              <Box>
                <Typography className='label'>Série:</Typography>
                {grades?.length === 1 ? (
                  <Typography className='grade'>{grades?.[0]?.name ?? ''}</Typography>
                ) : (
                  <ReadMoreComponent
                    allNames={grades?.map((grade) => grade.name)?.join(' / ')}
                    principalName={grades?.[0]?.name}
                    className='grade'
                  />
                )}
              </Box>

              <Box>
                <Typography className='label'>Turma:</Typography>
                {classrooms?.length === 1 ? (
                  <Typography className='classroom'>{classrooms?.[0]?.name ?? ''}</Typography>
                ) : (
                  <ReadMoreComponent
                    allNames={classrooms?.map((classroom) => classroom.name).join(' / ')}
                    principalName={classrooms?.[0]?.name}
                    className='classroom'
                  />
                )}
              </Box>

              <Box>
                <Typography className='label'>Início:</Typography>
                <Typography className='start_date'>{startDate}</Typography>
              </Box>

              <Box>
                <Typography className='label'>Fim:</Typography>
                <Typography className='end_date'>{endDate}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className='questions_container'>
          {modules?.map((module, index) => {
            return (
              <QuestionCard
                key={module.content.id}
                data={module}
                questionNumber={index + 1}
                activityType={activity.activity_type}
              />
            )
          })}
        </Box>
      </Container>
    </Box>
  )
}

export default ActivityViewContainer
