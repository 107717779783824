import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const CollapseContent = styled(Box)(({ theme }) => ({
  marginTop: '26px',
  '& .classroom': {
    alignItems: 'center',
    display: 'flex',
    gap: '8px',
    justifyContent: 'space-between',
    paddingRight: '40px',

    '& .label': {
      color: theme.palette.primary.light,
      fontSize: '16px',
      fontWeight: 700
    },
  },

  '& .classroom_items': {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    marginTop: '16px'
  },

  [theme.breakpoints.down(1240)]: {
    '& .classroom': {
      alignItems: 'flex-start',
      flexDirection: 'column',
      gap: '16px'
    }
  }
}))

export const SchoolNameBox = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  width: 'fit-content',

  '& span': {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '150%'
  },

  '& .school_name': {
    color: theme.palette.text.primary,
    marginRight: '4px'
  },

  '& .school_numberOfClassrooms': {
    color: theme.palette.primary.light,
    marginRight: '12px'
  },

  '& .school_btn': {
    height: '18px',
    width: '18px'
  }
}))

export const FormBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '32px',

  '& .field': {
    alignItems: 'center',
    display: 'flex',
    gap: '8px',

    '&_label': {
      color: theme.palette.text.primary,
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: '0.14px'
    },

    '&_datepicker': {
      maxWidth: '170px',
    }
  }
}))
