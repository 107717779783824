import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const BookContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#f9f9f9',
  borderRadius: '16px',
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '8px',
  padding: '5px 16px 24px',

  '& .book': {
    display: 'flex',
    gap: '8px',

    '& .book_content': {
      flex: 1,

      '& .book_content-title': {
        color: theme.palette.text.primary,
        fontSize: '18px',
        fontWeight: 700,
        lineHeight: '120%',
        marginBottom: '4px',
      },

      '& .book_content-description': {
        color: '#9f9f9f',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '150%',
        marginBottom: '16px',
        maxWidth: '406px',

        [theme.breakpoints.down('lg')]: {
          maxWidth: '100%',
        },
      },

      '& .book_content-info': {
        '& .last_item': {
          display: 'flex',
          justifyContent: 'flex-end'
        },

        '& .book_info': {
          '&.school': {
            maxWidth: '100%',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
          },

          '& span': {
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '120%',

            '&:first-of-type': {
              color: '#9f9f9f',
              marginRight: '4px',
            },

            '&:last-of-type': {
              color: theme.palette.text.primary,
            },

            '&.violet': {
              color: theme.palette.primary.light,
              fontWeight: 700
            },

            '&.green': {
              color: theme.palette.secondary.main,
              fontWeight: 700
            },
          }
        }
      }
    }
  }
}))

export const TagsContainer = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  gap: '8px',
  justifyContent: 'flex-end',

  '& .icon': {
    height: '16px',
    width: '16px',
    '& img': {
      height: '100%',
      width: '100%',
    }
  },

  '& span': {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '120%',
  }
}))

export const PublishedBadge = styled('span')(() => ({
  border: '1px solid #04AEC9',
  borderRadius: '20px',
  color: '#04AEC9',
  fontSize: '12px',
  padding: '4px 6px'
}))

export const NewBadge = styled('span')(() => ({
  backgroundColor: '#F69E9E',
  color: '#ffffff !important',
  borderRadius: '20px',
  padding: '4px 6px'
}))

export const BookCover = styled(Box)(() => ({
  width: '100%',
  maxWidth: '114px',
  borderRadius: '8px',
  height: '180px',
  overflow: 'hidden',

  '& .book_without-cover': {
    height: '100%',
    width: '100%',
    fontSize: '20px',
    fontWeight: 600,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#f1f1f1',
    color: '#c9c9c9'
  }
}))
