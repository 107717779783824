import React from 'react'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import NoDataImage from '../../assets/components/Message/not_found-image.svg'

const ErrorContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',

  '& .image': {
    marginBottom: '8px',
    maxWidth: '200px',

    '& img': {
      width: '100%'
    }
  },

  '& .description': {
    '& p': {
      color: theme.palette.text.primary,
      fontSize: '14px',
      fontWeight: 500,
      maxWidth: '500px',
      textAlign: 'center'
    }
  }
}))

const ErrorMessage = () => {
  return (
    <ErrorContainer>
      <Box className='image'>
        <img
          src={NoDataImage}
          alt='imagem ilustrativa indicando erro ao realizar a operação'
          loading='lazy'
        />
      </Box>

      <Box className='description'>
        <Typography>
          Ops! Parece que houve um problema ao acessar os dados do servidor. Tente novamente em alguns instantes.
        </Typography>
      </Box>
    </ErrorContainer>
  )
}

export default ErrorMessage
