import { Box, Grid } from '@mui/material'
import { styled } from '@mui/material/styles'

export const TableHeader = styled(Grid)(() => ({
  paddingInline: '16px',
  marginBottom: '18px',

  '& .theader_cell': {
    color: '#9f9f9f',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '120%'
  }
}))

export const TableBody = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px'
}))

export const RowBookContainer = styled(Box)(() => ({
  background: '#f5f5f5',
  borderRadius: '8px',
}))

export const TableCell = styled(Grid)(({ theme }) => ({
  cursor: 'pointer',
  padding: '16px',

  '& .cell': {
    alignItems: 'center',
    display: 'flex',

    '&:last-of-type': {
      justifyContent: 'flex-end'
    }
  },

  '& .book_info': {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '120%',
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    '&.title': {
      fontWeight: 700,
    }
  },
}))

export const LoadingContainer = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  paddingBottom: '8px'
}))

export const UnitLabel = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '12px 16px 16px',

  '& .unit_name': {
    alignItems: 'center',
    display: 'flex',
    gap: '4px',

    '& span': {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '150%',

      '&:first-of-type': {
        color: theme.palette.text.primary,
      },

      '&:last-of-type': {
        color: theme.palette.primary.light,
      }
    }
  },

  '& .unit_performance': {
    alignItems: 'center',
    display: 'flex',
    gap: '4px',

    '& span': {
      color: theme.palette.text.primary,
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '120%',
    }
  }
}))

export const ClassroomsContainer = styled(Box)(({ theme }) => ({
  background: '#fff',
  borderRadius: '16px',
  margin: '0 16px 16px',
  padding: '24px 16px',

  '& .classroom_container': {
    display: 'flex',
    flexDirection: 'column',
    gap: '48px',

    '& .classroom': {
      '& .item': {
        alignItems: 'center',
        display: 'flex',

        '&:nth-of-type(2), &:nth-of-type(3)': {
          justifyContent: 'center'
        },

        '&:last-of-type': {
          justifyContent: 'flex-end'
        }
      },

      '& .classroom_name': {
        background: theme.palette.primary.light,
        borderRadius: '8px',
        color: '#f0f3f4',
        fontSize: '14px',
        fontWeight: 400,
        padding: '5.5px 18px',
        width: 'fit-content'
      },

      '& .classroom_students': {
        alignItems: 'center',
        display: 'flex',
        gap: '3px',

        '& span': {
          color: theme.palette.text.primary,
          fontWeight: 500,
          lineHeight: '120%',

          '&:first-of-type': {
            fontSize: '14px'
          },

          '&:last-of-type': {
            fontSize: '12px'
          }
        }
      },

      '& .classroom_performance': {
        alignItems: 'center',
        display: 'flex',
        gap: '4px',

        '& span': {
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '120%',

          '&:first-of-type': {
            color: '#9f9f9f'
          },

          '&:last-of-type': {
            color: theme.palette.text.primary
          }
        }
      },

      '& .classroom_actions': {
        '& > button': {
          color: theme.palette.primary.light,
          fontSize: '12px',
          fontWeight: 600,
          letterSpacing: '0.12px',
          textDecoration: 'underline',
          textTransform: 'capitalize',
        }
      }
    }
  }
}))
