import http from '../../utils/http'

const teachersEndpoint = '/user_teachers'

export const createTeacherService = (payload: object) => {
  return http.post(teachersEndpoint, payload)
}

export const updateTeachersService = (payload: any) => {
  return http.put(`${teachersEndpoint}/${payload?.id}`, payload.data)
}

export const getAllTeachersService = ({ params }: { params: object }) => {
  return http.get(`${teachersEndpoint}`, { params })
}

export const getTeacherByIdService = (payload: any) => {
  return http.get(`${teachersEndpoint}/${payload?.id}?school_unit_id=${payload?.schoolUnitId}`)
}
