import React from 'react'

import ManageStudentsContainer from '../containers/ManageStudentsContainer'

const ManageStudentsPage = () => (
  <div>
    <ManageStudentsContainer />
  </div>
)

export default ManageStudentsPage
