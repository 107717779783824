import { Box, Paper } from '@mui/material'
import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles'

export const BoxStyled = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  gap: '8px',
  justifyContent: 'center',
  position: 'relative',

  '& .main-slider-prev-mostUsed, .main-slider-next-mostUsed, .main-slider-prev-lessUsed, .main-slider-next-lessUsed': {
    width: 20,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#9f9f9f',
    borderRadius: '50%',
    outline: 'none',
    border: 'none',
    cursor: 'pointer',
    position: 'absolute',
    zIndex: 2,
  },

  '& .main-slider-prev-mostUsed, .main-slider-prev-lessUsed': {
    left: '-9px'
  },
  '& .main-slider-next-mostUsed, .main-slider-next-lessUsed': {
    right: '-9px'
  },

  '& .swiper-button-disabled': {
    opacity: 0.4,
  },
}))

export const PaperStyled = styled(Paper)(({ theme }) => ({
  background: '#fff',
  borderRadius: '16px',
  padding: '24px 14px 16px',

  '& .header': {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '4px',

    '& .header_label': {
      alignItems: 'center',
      display: 'flex',
      gap: '4px',

      '& > h3': {
        color: theme.palette.text.primary,
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '120%'
      }
    },

    '& .header_filters': {
      alignItems: 'center',
      display: 'flex',
      gap: '16px'
    }
  }
}))

export const FilterContainer = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
}))

export const LinkContainer = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  width: '100%'
}))

export const LinkStyled = styled(Link)(({ theme }) => ({
  textDecoration: 'underline',
  display: 'flex',
  alignItems: 'center',
  fontSize: '14px',
  fontWeight: '600',
  color: theme.palette.primary.light,
  'svg': {
    marginRight: '8px'
  }
}))

