import React, { useEffect } from 'react'

// Components
import { Box, Button, Grid, IconButton, Tooltip } from '@mui/material'
import { FieldArray } from 'react-final-form-arrays'
import { Field } from 'react-final-form'
import AsyncSelectInput from '../../select/AsyncSelectInput'
import Input from '../../input/Input'

// Utils
import { required } from '../../../utils/formUtils'

// Icons and images
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import ClearIcon from '@mui/icons-material/Clear'
import { ReactComponent as RoundedPlusIcon } from '../../../assets/components/ManagersPage/rounded-plus-icon.svg'

// Styles
import { AddButton, FieldContainer } from './styles/ClassroomForm.styles'

const ClassroomForm = ({ unitId, change, hasAttribution, values }: any) => {
  useEffect(() => {
      change?.('classrooms', [{}])
    }, [])

  return (
    <FieldContainer>
      <FieldArray name='classrooms'>
        {({ fields }) => {
          return (
            <>
              {!hasAttribution && (
                <AddButton
                  type='button'
                  variant='text'
                  onClick={() => fields.push({})}
                  startIcon={<RoundedPlusIcon />}
                >
                  Adicionar Turma
                </AddButton>
              )}

              {fields.map((name, index) => (
                <Grid container key={index} columnSpacing={3}>
                  <Grid item xs={6}>
                    <Box className='field'>
                      <label htmlFor={`${name}.classroom`}>Turma *</label>
                      <Field
                        id={`${name}.classroom`}
                        name={`${name}.classroom`}
                        hasBorder
                        component={AsyncSelectInput}
                        placeholder='Selecione a turma ...'
                        searchParam='name'
                        validate={required}
                        request={{
                          path: '/classrooms/with_students_for_coordinator',
                          params: { school_unit_id: unitId }
                        }}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <Box className='field'>
                      <label htmlFor={`${name}.grade`}>Série</label>

                      <Box className='grade_field'>
                        <Field
                          id={`${name}.grade`}
                          name={`${name}.grade`}
                          component={Input}
                          placeholder='Série'
                          width='100%'
                          initialValue={
                            values?.[index]?.classroom?.grade?.name
                          }
                          disabled
                        />

                        <Tooltip title='Remover atribuição'>
                          <IconButton
                            type='button'
                            className='btn_remove'
                            size='small'
                            onClick={() => fields.remove(index)}
                          >
                            <ClearIcon className='icon' />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              ))}

              {hasAttribution && (
                <Box className='btn_container'>
                  <Button
                    type='button'
                    variant='contained'
                    className='btn_add'
                    onClick={() => fields.push({})}
                    startIcon={
                      <AddCircleOutlineIcon className='icon' />
                    }
                  >
                    Vincular nova turma
                  </Button>
                </Box>
              )}
            </>
          )
        }}
      </FieldArray>

    </FieldContainer>
  )
}

export default ClassroomForm
