import React, { useEffect, useMemo, useState } from 'react'

// Components
import {
  Alert,
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  Grid,
  Snackbar,
  Typography
} from '@mui/material'
import SimpleAsyncSelect from '../../select/SimpleAsyncSelect'
import SimpleSelect from '../../select/SimpleSelect'

// Redux
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { clearMigrateStudents, fetchClassroomWithStudentsCoordinatorRequest, migrateStudentsRequest } from '../../../store/classroom/actions'

// Utils
import { Class } from '../../../models/IClassroom'
import { stringAvatar } from '../../../utils/functions'

// Icons and image
import { ReactComponent as MigrateDarkIcon } from '../../../assets/components/ManagersPage/migration-dark-icon.svg'
import { ReactComponent as MigrateLightIcon } from '../../../assets/components/ManagersPage/migration-light-icon.svg'
import { ReactComponent as ArrowRightIcon } from '../../../assets/components/ManagersPage/right-arrow-icon.svg'
import NoStudentsImage from '../../../assets/components/ManagersPage/no-students.png'

// Styles
import { Content, EmptyBox, Header, PaperStyled } from './styles/MigrationModal.styles'

interface IOption {
  id: number
  name: string
}

interface IMigrationModalProps {
  openModal: boolean
  closeModal: () => void
  classroom: { id: number, name: string }
  scroll?: any
}

const MigrationModal: React.FC<IMigrationModalProps> = ({
  openModal,
  closeModal,
  classroom
}) => {
  const [schoolOption, setSchoolOption] = useState<IOption | null>(null)
  const [schoolTermOption, setSchoolTermOption] = useState<IOption | null>(null)
  const [classroomOption, setClassroomOption] = useState<Class | null>(null)
  const [studentsId, setStudentsId] = useState<number[]>([])
  const [openMessage, setOpenMessage] = useState(false)
  const dispatch = useAppDispatch()
  const { user } = useAppSelector(state => state.auth)
  const { migrateStudents } = useAppSelector(state => state.classroom)
  const { selectedUnit } = useAppSelector(state => state.persistable)

  const units = useMemo(() => {
    const schoolUnits = user?.units?.map(unit => ({
      id: unit.id,
      name: unit.name
    }))
    return schoolUnits
  }, [user])

  const handleUnitsChange = (option: IOption) => {
    setSchoolOption(option)
    setSchoolTermOption(null)
    setClassroomOption(null)
  }

  const handleSchoolTermChange = (option: IOption) => {
    setSchoolTermOption(option)
    setClassroomOption(null)
  }

  const handleClassroomChange = (option: any) => {
    setClassroomOption(option)
  }

  const handleCheckAllStudents = (event: React.ChangeEvent<HTMLInputElement>) => {
    const ids = classroomOption?.students?.map(student => student.id)
    if (event.target.checked && ids && ids.length > 0) {
      setStudentsId(ids)
    } else {
      setStudentsId([])
    }
  }

  const handleCheckStudent = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    if (event.target.checked && !studentsId.includes(id)) {
      setStudentsId(prevState => [...prevState, id])
    } else {
      setStudentsId(prevState => prevState.filter(item => item !== id))
    }
  }

  const handleCloseMessage = () => {
    setOpenMessage(false)
  }

  const handleMigrateStudents = () => {
    if (studentsId && studentsId.length > 0) {
      dispatch(migrateStudentsRequest({
        classroomId: classroom.id,
        studentsId: String(studentsId)
      }))
    } else {
      setOpenMessage(true)
    }
  }

  useEffect(() => {
    migrateStudents.isSuccess && closeModal()

    return () => {
      migrateStudents.isSuccess && dispatch(fetchClassroomWithStudentsCoordinatorRequest({
        school_unit_ids: selectedUnit?.id,
      }))
      dispatch(clearMigrateStudents())
    }
  }, [migrateStudents.isSuccess])

  return (
    <Dialog
      open={openModal}
      onClose={closeModal}
      maxWidth='lg'
      fullWidth
      PaperComponent={PaperStyled}
      PaperProps={{ style: { overflowY: 'hidden' } }}
    >
      <>
        <Header>
          <MigrateDarkIcon />
          <Typography component='h2'>Migração</Typography>
        </Header>

        <Content>
          <Typography className='description'>
            Transfira estudantes entre turmas ou séries com praticidade. Otimize a gestão escolar com apenas alguns cliques
          </Typography>

          <Box className='box_subtitle'>
            <Typography component='h3' className='label'>
              De uma turma para outra
            </Typography>

            <Typography className='required_fields-message'>* Campo obrigatório</Typography>
          </Box>

          <Box className='box'>
            <Box className='filters_container'>
              <Box className='filter'>
                <label htmlFor='school'>
                  Escola
                  <span>*</span>
                </label>
                <SimpleSelect
                  className='school'
                  options={units ?? []}
                  value={schoolOption}
                  onChange={handleUnitsChange}
                  placeholder='Selecione...'
                  hasBorder
                  maxWidth='100%'
                />
              </Box>

              <Box className='filter'>
                <label htmlFor='year'>
                  Ano
                  <span>*</span>
                </label>
                <SimpleAsyncSelect
                  className='year'
                  placeholder='Selecione...'
                  onChange={handleSchoolTermChange}
                  value={schoolTermOption}
                  disabled={!schoolOption}
                  request={{
                    path: '/school_terms'
                  }}
                />
              </Box>

              <Box className='filter'>
                <label htmlFor='classroom'>
                  Turma
                  <span>*</span>
                </label>
                <SimpleAsyncSelect
                  className='classroom'
                  placeholder='Selecione...'
                  onChange={handleClassroomChange}
                  value={classroomOption}
                  disabled={!schoolTermOption}
                  request={{
                    path: '/classrooms/with_students_for_coordinator',
                    params: {
                      per: 10,
                      page: 1,
                      school_unit_ids: schoolOption?.id,
                      education_school_term_ids: schoolTermOption?.id
                    },
                  }}
                />
              </Box>
            </Box>

            <Box className='classroom_container'>
              <ArrowRightIcon />
              <Box className='new_classroom'>
                <Box component='span'>Turma Atual:</Box>
                <Box component='span'>{classroom?.name}</Box>
              </Box>
            </Box>
          </Box>

          <Box className='students_container'>
            {!classroomOption ? (
              <EmptyBox>
                <Box className='image_container'>
                  <img src={NoStudentsImage} />
                </Box>

                <Typography className='description'>
                  Preencha os filtros acima para realizar a migração dos alunos entre as turmas.
                </Typography>
              </EmptyBox>
            ) : (
              classroomOption && classroomOption?.students?.length > 0 ? (
                <>
                  <Typography component='h4' className='title'>
                    Selecione o(s) aluno(s) que deverão ser migrados
                  </Typography>

                  <Box className='select_all-box'>
                    <Checkbox
                      id='all'
                      onChange={handleCheckAllStudents}
                    />
                    <label htmlFor='all'>Selecionar todos os alunos</label>
                  </Box>

                  <Box className='students'>
                    <Grid container>
                      <Grid item xs={6}>
                        <Box className='column'>
                          {classroomOption?.students?.filter((_, index, array) => (index <= Math.floor(array?.length / 2)))
                            .map((student) => (
                              <Box key={student.id} className='student'>
                                <Checkbox
                                  id={String(student.id)}
                                  checked={studentsId.some(item => item === student.id)}
                                  onChange={(e) => handleCheckStudent(e, student.id)}
                                />
                                <label htmlFor={String(student.id)}>
                                  <Avatar
                                    alt={student.name}
                                    variant='rounded'
                                    {...stringAvatar(student?.name, 34, 34, 14)}
                                  />
                                  {student.name}
                                </label>
                              </Box>
                            ))}
                        </Box>
                      </Grid>

                      <Grid item xs={6}>
                        <Box className='column'>
                          {classroomOption?.students?.filter((_, index, array) => (index > Math.floor(array?.length / 2)))
                            .map((student) => (
                              <Box key={student.id} className='student'>
                                <Checkbox
                                  id={String(student.id)}
                                  checked={studentsId.some(item => item === student.id)}
                                  onChange={(e) => handleCheckStudent(e, student.id)}
                                />
                                <label htmlFor={String(student.id)}>
                                  <Avatar
                                    alt={student.name}
                                    variant='rounded'
                                    {...stringAvatar(student?.name, 34, 34, 14)}
                                  />
                                  {student.name}
                                </label>
                              </Box>
                            ))}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              ) : (
                <EmptyBox>
                  <Box className='image_container'>
                    <img src={NoStudentsImage} />
                  </Box>

                  <Typography className='description'>
                    Não há alunos nessa turma para realizar a migração.
                  </Typography>
                </EmptyBox>
              )
            )}
          </Box>

          <Box className='btn_container'>
            <Button
              type='button'
              variant='outlined'
              className='btn_back'
              onClick={closeModal}
              disabled={migrateStudents.isFetching}
            >
              Voltar
            </Button>

            <Button
              type='button'
              variant='contained'
              className='btn_migrate'
              startIcon={migrateStudents.isFetching
                ? (
                  <CircularProgress
                    size={20}
                    variant='indeterminate'
                    color='inherit'
                  />
                )
                : (
                  <MigrateLightIcon />
                )}
              disabled={migrateStudents.isFetching}
              onClick={handleMigrateStudents}
            >
              Migrar aluno(s)
            </Button>
          </Box>
        </Content>

        <Snackbar
          open={openMessage}
          autoHideDuration={6000}
          onClose={handleCloseMessage}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert variant='filled' severity='error'>
            Selecione pelo menos um aluno para realizar a migração para outra turma.
          </Alert>
        </Snackbar>
      </>
    </Dialog>
  )
}

export default MigrationModal
