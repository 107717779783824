import { Box, Grid, Menu, Paper } from '@mui/material'
import MaterialButton from '@mui/material/Button'
import { styled } from '@mui/material/styles'

export const Header = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',

  '& .label': {
    alignItems: 'center',
    display: 'flex',
    gap: '8px',

    '& > h2': {
      color: theme.palette.text.primary,
      fontSize: '18px',
      fontWeight: 600,
      letterSpacing: '0.18px'
    }
  }
}))

export const OrderByBtn = styled(MaterialButton)(({ theme }) => ({
  background: '#ffffff',
  border: `1px solid ${theme.palette.text.secondary}`,
  borderRadius: 8,
  color: theme.palette.text.primary,
  fontSize: 14,
  padding: '10px 34px',
  textTransform: 'none',
  '& svg': {
    marginRight: 8
  },

}))

export const MenuStyle = styled(Menu)({
  '& .MuiMenu-list': {
    border: '1px solid #D9D9D9',
    borderRadius: 8,
    width: 132,
    '& .MuiMenuItem-root': {
      fontSize: 12,
      '&:not(li:last-of-type)': {
        borderBottom: '1px solid #D9D9D9',
      }
    }
  }
})

export const TableHeader = styled(Grid)(() => ({
  '& .table_header': {
    color: '#9f9f9f',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '120%',

    '&:nth-of-type(4), &:nth-of-type(5)': {
      display: 'flex',
      justifyContent: 'center'
    }
  }
}))

export const PaperContainer = styled(Paper)(() => ({
  borderRadius: '16px',
  padding: '35px 16px',
  marginTop: '24px'
}))

export const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  marginTop: '27px'
}))

export const BtnContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '32px'
}))
