import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

// Components
import { Box, Button, Pagination, Skeleton, Typography } from '@mui/material'
import TeacherCollapse from '../components/Management/collapses/TeacherCollapse'

// Redux
import { getAllTeachersRequest } from '../store/teachers/actions'
import { useAppDispatch, useAppSelector } from '../store/hooks'

// Utils
import { scrollToTop } from '../utils/functions'

// Icons and images
import { ReactComponent as PlusCircledIcon } from '../assets/components/ManagersPage/plus-circled.svg'
import { ReactComponent as StudentsIcon } from '../assets/components/ManagersPage/students-icon.svg'
import NoClassroomsImage from '../assets/components/ManagersPage/no-classrooms.png'

// Styles
import { Content, Header, NoContentBox, PaginationBox } from './styles/ManageTeachersContainer.styles'

const ManageTeachersContainerTwo = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { items, isError, isFetching, pagination } = useAppSelector(state => state.teachers)
  const { selectedUnit } = useAppSelector(state => state.persistable)

  const handleToTeacherForm = () => {
    navigate('/manage-teachers/create')
  }

  const getAllTeachers = (params: object) => {
    dispatch(getAllTeachersRequest({
      school_unit_id: selectedUnit?.id,
      ...params
    }))
  }

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    getAllTeachers({ page: value })
    scrollToTop()
  }

  useEffect(() => {
    getAllTeachers({ page: 1 })
  }, [selectedUnit])

  return (
    <Box>
      <Header>
        <Box className='label'>
          <StudentsIcon />
          <Typography
            component='h2'
            className='title'
          >
            Selecione uma turma
          </Typography>
        </Box>

        {items && items.length > 0 && (
          <Button
            type='button'
            className='btn_teacher-form'
            onClick={handleToTeacherForm}
            startIcon={<PlusCircledIcon />}
            variant='contained'
          >
            Adicionar professor(a)
          </Button>
        )}
      </Header>

      <Content>
        {isFetching && (
          Array(10).fill(0).map((_, index) => (
            <Skeleton
              key={index}
              variant='rounded'
              animation='wave'
              sx={{
                borderRadius: '8px',
                height: '50px',
                width: '100%'
              }}
            />
          ))
        )}

        {!isFetching && !isError && (
          <>
            {items && items.length > 0 && items?.map((item) => (
              <TeacherCollapse
                key={item.id}
                item={item}
              />
            ))}

            {items && items.length === 0 && (
              <NoContentBox>
                <Box className='image'>
                  <img src={NoClassroomsImage} alt='imagem' />
                </Box>

                <Typography className='message'>
                  Você <span>não tem turmas</span> cadastradas ainda
                </Typography>
              </NoContentBox>
            )}
          </>
        )}
      </Content>

      {!isError && pagination?.totalOfPages > 1 && (
        <PaginationBox>
          <Pagination
            count={pagination?.totalOfPages}
            size='small'
            color='primary'
            page={pagination.current}
            onChange={handleChangePage}
          />
        </PaginationBox>
      )}
    </Box>
  )
}

export default ManageTeachersContainerTwo
