import React, { useEffect } from 'react'
import { Box, Button, Grid, IconButton, Tooltip } from '@mui/material'
import { FieldArray } from 'react-final-form-arrays'
import { Field } from 'react-final-form'
import AsyncSelectInput from '../../select/AsyncSelectInput'
import { required } from '../../../utils/formUtils'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import ClearIcon from '@mui/icons-material/Clear'
import { ReactComponent as RoundedPlusIcon } from '../../../assets/components/ManagersPage/rounded-plus-icon.svg'
import { AcademicFormContainer, AddButton } from './styles/AcademicAtribForm.styles'

const AcademicAtribForm = ({ unitId, change, hasAttribution }: any) => {
  useEffect(() => {
    change?.('classrooms', [{}])
  }, [])

  return (
    <AcademicFormContainer>
      <FieldArray name='classrooms'>
        {({ fields }) => {
          return (
            <>
              {!hasAttribution && (
                <AddButton
                  type='button'
                  variant='text'
                  onClick={() => fields.push({})}
                  startIcon={<RoundedPlusIcon />}
                >
                  Disciplina e Turma
                </AddButton>
              )}

              {fields.map((name, index) => (
                <Grid container key={index} columnSpacing={3} mt={2}>
                  <Grid item xs={9}>
                    <Box className='datafield'>
                      <label htmlFor='subject_ids'>Disciplina(s) *</label>
                      <Field
                        name={`${name}.subject_ids`}
                        hasBorder
                        component={AsyncSelectInput}
                        placeholder='Escolha as disciplinas'
                        searchParam='name'
                        isMulti
                        request={{
                          path: '/subjects'
                        }}
                        validate={required}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={3}>
                    <Box className='datafield'>
                      <label htmlFor={`${name}.classroom`}>Turma</label>
                      <Box className='classroom_field'>
                        <Field
                          id={`${name}.classroom`}
                          name={`${name}.classroom`}
                          component={AsyncSelectInput}
                          placeholder='Selecione a turma'
                          request={{
                            path: '/classrooms/with_students_for_coordinator',
                            params: {
                              school_unit_ids: unitId,
                            }
                          }}
                        />

                        <Tooltip title='Remover atribuição'>
                          <IconButton
                            type='button'
                            className='btn_remove'
                            size='small'
                            onClick={() => fields.remove(index)}
                          >
                            <ClearIcon className='icon' />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              ))}

              {hasAttribution && (
                <Box className='btn_container'>
                  <Button
                    type='button'
                    variant='contained'
                    className='btn_add'
                    onClick={() => fields.push({})}
                    startIcon={
                      <AddCircleOutlineIcon className='icon' />
                    }
                  >
                    Adicionar nova atribuição
                  </Button>
                </Box>
              )}
            </>
          )
        }}
      </FieldArray>
    </AcademicFormContainer>
  )
}

export default AcademicAtribForm
