import React from 'react'
import { AsyncPaginate, LoadOptions } from 'react-select-async-paginate'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import http from '../../utils/http'

const BoxStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  '& > div': {
    fontSize: 14,
    '& .select__control': {
      cursor: 'pointer',
      borderRadius: 8,
      border: '1px solid #D9D9D9',
      minHeight: '40px',
      '& .select__placeholder': {
        color: '#D9D9D9',
        fontWeight: 400
      },
      '& .select__input-container': {
        color: theme.palette.text.primary
      },
      '& .select__dropdown-indicator > svg': {
        height: 16,
        width: 20
      },
      '& .select__multi-value': {
        background: '#FFFFFF',
        border: '0.75px solid #BC9BFA',
        borderRadius: 32,
        height: 20,
        '& .select__multi-value__label': {
          color: '#BC9BFA',
          fontSize: 12,
          fontWeight: 500,
          padding: '0 0 0 6px'
        },
        '& .select__multi-value__remove': {
          '&:hover': {
            background: 'none',
            '& svg': {
              backgroundColor: theme.palette.error.main
            }
          },
          '& svg': {
            fill: '#fff',
            borderRadius: '50%',
            backgroundColor: '#BC9BFA',
            height: 10,
            width: 10
          }
        }
      }
    },
    '& .select__control--is-focused': {
      borderColor: theme.palette.primary.main,
      boxShadow: `0 0 0 1px ${theme.palette.primary.main}`
    }
  }
}))

const SimpleAsyncSelect = ({
  request,
  searchParam,
  options,
  onChange,
  value,
  disabled,
  ...rest
}: any) => {

  const loadOptions: LoadOptions<any, any, { page: number }> = async (
    searchQuery,
    loadedOptions,
    page
  ) => {
    if (request?.path) {
      const hasSearchParam = searchParam ? { [searchParam]: searchQuery } : ''
      const requestParams = {
        ...hasSearchParam,
        ...request.params,
        'page': page?.page
      }
      const response = await http.get(request.path, { params: requestParams })
      const newPage = (page?.page && page.page) || 1
      return {
        options: response.data.data || response.data,
        hasMore: response.headers['x-total-pages'] ? newPage < Number(response.headers['x-total-pages']) : false,
        additional: {
          page: newPage + 1
        }
      }
    } else {
      return {
        options: options,
        hasMore: false
      }
    }
  }

  return (
    <BoxStyled>
      <AsyncPaginate
        {...rest}
        classNamePrefix='select'
        loadOptionsOnMenuOpen
        cacheUniqs={[request]}
        isDisabled={disabled}
        loadOptions={loadOptions}
        onChange={onChange}
        value={value}
        getOptionValue={(option: any) => option?.id || option?.id}
        getOptionLabel={(option: any) => option?.name || option?.label}
        components={{
          IndicatorSeparator: () => null,
        }}
        loadingMessage={() => 'Carregando...'}
        noOptionsMessage={() => 'Não encontrado'}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: 'rgba(217, 217, 217, 0.4)',
          },
        })}
        additional={{
          page: 1
        }}
      />
    </BoxStyled>
  )
}

export default SimpleAsyncSelect
