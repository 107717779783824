import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// Components
import { Box } from '@mui/system'
import {
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material'
import arrayMutators from 'final-form-arrays'
import { Field, Form } from 'react-final-form'
import AcademicAtribForm from './AcademicAtribForm'
import AddressForm from './AddressForm'
import AutoCompleteSelect from '../../select/AutoCompleteInputSelect'
import DateInput from '../input/DataPickerInput'
import Input from '../../input/Input'
import PhoneInput from '../../input/PhoneInput'
import SelectField from '../../select/SelectField'
import SuccessMessage from '../../message/SuccessMessage'

// Redux
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import {
  createTeacherRequest,
  getAllTeachersRequest,
  getTeacherById,
  resetAllTeachers,
  resetTeacher,
  updateTeacherRequest
} from '../../../store/teachers/actions'
import { getSchoolAppIdRequest } from '../../../store/school_app_id/actions'

// Utils
import { validateEmail } from '../../../utils/formUtils'
import { convertDate, cpfFormatter, removeNonNumericCharacters } from '../../../utils/functions'
import { ITeacherFormValues, IUserTeacher } from '../../../models/ITeacher'

// Icons and Images
import { ReactComponent as DataIcon } from '../../../assets/components/ManagersPage/data-icon.svg'

// Styles
import { BtnContainer, PaperFormContainer, Title } from './styles/TeacherForm.styles'

interface ICustomInitialValues extends Partial<ITeacherFormValues> {
  id?: number
  school_unit_id?: number
  app_id?: number
}

const TeacherForm = ({ initialValues }: any) => {
  const [taxpayerNumber, setTaxpayerNumber] = useState('')
  const [customInitialValues, setCustomInitialValues] = useState<ICustomInitialValues | null>(null)
  const { selectedUnit } = useAppSelector(state => state.persistable)
  const { items, isSaving, isSuccess, currentItem } = useAppSelector(state => state.teachers)
  const { items: appIds } = useAppSelector(state => state.schoolAppId)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { state } = useLocation()
  const teacher = state?.teacher

  const taxpayerOptions = items.map(item => item?.teachers).flat().map((option) => option?.user?.taxpayer_number)

  const handleBackToPage = () => navigate('/manage-teachers')

  const validate = (values: ITeacherFormValues) => {
    const errors: any = {}

    if (!values?.taxpayer_number) {
      errors.taxpayer_number = 'Campo obrigatório'
    }
    if (!values?.birthday) {
      errors.birthday = 'Campo obrigatório'
    }
    if (values?.birthday?.length < 10) {
      errors.birthday = 'Data inválida'
    }
    if (!values?.name) {
      errors.name = 'Campo obrigatório'
    }
    if (!values?.phone) {
      errors.phone = 'Campo obrigatório'
    }
    if (values?.phone?.length < 14) {
      errors.phone = 'Telefone inválido'
    }
    if (!values?.email) {
      errors.email = 'Campo obrigatório'
    }
    if (values?.email) {
      errors.email = validateEmail(values?.email)
    }
    if (!values?.genre) {
      errors.genre = 'Campo obrigatório'
    }

    return errors
  }

  const handleSubmitForm = (values: ITeacherFormValues) => {
    const cpfWithoutFormatting = values?.taxpayer_number?.replace(/\D/g, '')
    const appId = appIds?.find((item) => item.school_unit.id === selectedUnit?.id)

    const teacherDataToSend = {
      ...values,
      taxpayer_number: cpfWithoutFormatting,
      school_unit_id: selectedUnit?.id,
      id: initialValues?.id ?? currentItem?.item?.user?.id,
      app_id: appId?.id,
    }

    if (initialValues?.id || customInitialValues?.id) {
      dispatch(updateTeacherRequest(teacherDataToSend))
    } else {
      dispatch(createTeacherRequest(teacherDataToSend))
    }
  }

  const findTeacherWithCPF = (items: IUserTeacher[], taxpayerNumber: string) => {
    const teacher = items
      .map(item => item.teachers)
      .flat()
      .find(teacher => removeNonNumericCharacters(teacher.user.taxpayer_number) === removeNonNumericCharacters(taxpayerNumber) && !!taxpayerNumber)

    return teacher
  }

  const getSearchTeacher = async (id: number) => {
    try {
      const response: any = await dispatch(getTeacherById.request({
        id,
        schoolUnitId: selectedUnit?.id
      }))

      const teacher = response.data
      const item = {
        birthday: teacher?.user?.birthday && convertDate(teacher?.user?.birthday),
        complement: teacher?.user?.additional_data?.address?.complement,
        email: teacher?.user?.email,
        genre: teacher?.user?.genre === 'female'
          ? { name: 'Feminino', value: 'female' }
          : teacher?.user?.genre === 'male'
            ? { name: 'Masculino', value: 'male' }
            : undefined,
        name: teacher?.user?.name,
        neighborhood: teacher?.user?.additional_data?.address?.neighborhood,
        number: teacher?.user?.additional_data?.address?.number,
        phone: teacher?.user?.phone,
        street: teacher?.user?.additional_data?.address?.street,
        taxpayer_number: teacher?.user?.taxpayer_number && cpfFormatter(teacher?.user?.taxpayer_number),
        zipcode: teacher?.user?.additional_data?.address?.zipcode
      }

      const classrooms = teacher?.user?.classrooms?.reduce((acc: any, curr: any) => {
        const existing = acc.find((item: any) =>
          item.classroom.id === curr?.classroom.id
        )

        if (existing) {
          existing.subject_ids.push(curr?.subject_ids)
        } else {
          acc.push({
            subject_ids: [curr?.subject_ids],
            classroom: curr?.classroom
          })
        }

        return acc
      }, [])

      !initialValues?.id && setCustomInitialValues({
        ...item,
        classrooms
      })

    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    dispatch(getAllTeachersRequest({ school_unit_id: selectedUnit?.id, per: 999 }))
    dispatch(getSchoolAppIdRequest())

    return () => {
      dispatch(resetTeacher())
      dispatch(resetAllTeachers())
    }
  }, [selectedUnit])

  useEffect(() => {
    const teacher = findTeacherWithCPF(items, taxpayerNumber)

    if (teacher && teacher?.user?.id) {
      getSearchTeacher(teacher?.user?.id)
    } else if (!teacher && !taxpayerNumber) {
      setCustomInitialValues(null)
    } else if (taxpayerNumber?.length < 11) {
      setCustomInitialValues({
        taxpayer_number: cpfFormatter(taxpayerNumber)
      })
    }
  }, [taxpayerNumber])

  return (
    <Box>
      {isSuccess ? (
        <SuccessMessage
          type='teachers'
          path='manage-teachers'
          isEdit={Boolean(initialValues?.id)}
        />
      ) : (
        <>
          <Title>
            <DataIcon />
            <Typography component='h2'>Dados Cadastrais</Typography>
          </Title>

          <Form
            onSubmit={handleSubmitForm}
            validate={validate}
            mutators={{ ...arrayMutators }}
            initialValues={
              initialValues && Object?.keys(initialValues)?.length > 0
                ? initialValues
                : customInitialValues
            }
            render={({ handleSubmit, values, form }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <PaperFormContainer>
                    <Box>
                      <Typography
                        component='h3'
                        className='primary_title'
                      >
                        Professor(a)
                      </Typography>

                      <Typography
                        component='h4'
                        className='secondary_title'
                      >
                        Dados pessoais
                      </Typography>

                      <Box className='personal_form'>
                        <Grid container columnSpacing={3} rowSpacing={3}>
                          <Grid item xs={4} xl={3}>
                            <Box className='datafield'>
                              <label htmlFor='taxpayer_number'>CPF *</label>
                              <Field
                                id='taxpayer_number'
                                name='taxpayer_number'
                                component={AutoCompleteSelect}
                                placeholder='000.000.000-00'
                                options={taxpayerOptions ?? []}
                                setTaxpayerNumber={setTaxpayerNumber}
                                disabled={Boolean(initialValues?.id)}
                                disableClearable
                              />
                            </Box>
                          </Grid>

                          <Grid item xs={4} xl={6}>
                            <Box className='datafield'>
                              <label htmlFor='name'>Nome do professor(a) *</label>
                              <Field
                                id='name'
                                name='name'
                                component={Input}
                                placeholder='Insira o nome completo'
                              />
                            </Box>
                          </Grid>

                          <Grid item xs={4} xl={3}>
                            <Box className='datafield'>
                              <label htmlFor='birthday'>Nascimento *</label>
                              <Field
                                id='birthday'
                                name='birthday'
                                component={DateInput}
                                placeholder='00/00/0000'
                              />
                            </Box>
                          </Grid>

                          <Grid item xs={4} xl={3}>
                            <Box className='datafield'>
                              <label htmlFor='phone'>Contato *</label>
                              <Field
                                id='phone'
                                name='phone'
                                component={PhoneInput}
                                placeholder='(00) 00000-0000'
                              />
                            </Box>
                          </Grid>

                          <Grid item xs={4} xl={6}>
                            <Box className='datafield'>
                              <label htmlFor='email'>E-mail *</label>
                              <Field
                                id='email'
                                name='email'
                                component={Input}
                                placeholder='nome@exemplo.com'
                              />
                            </Box>
                          </Grid>

                          <Grid item xs={4} xl={3}>
                            <Box className='datafield'>
                              <label htmlFor='genre'>Sexo biológico</label>
                              <Field
                                id='genre'
                                name='genre'
                                component={SelectField}
                                width='100%'
                                hasBorder
                                placeholder='Selecione...'
                                options={[
                                  { name: 'Masculino', value: 'male' },
                                  { name: 'Feminino', value: 'female' }
                                ]}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>

                      <Typography
                        component='h4'
                        className='secondary_title'
                      >
                        Atribuições acadêmicas
                      </Typography>

                      <Box className='academic_form'>
                        <AcademicAtribForm
                          unitId={selectedUnit?.id}
                          change={form.change}
                          hasAttribution={values?.classrooms?.length > 0}
                        />
                      </Box>

                      <AddressForm
                        defaultValue={teacher?.additional_data}
                        change={form.change}
                        values={values?.zipcode}
                      />
                    </Box>

                    <Typography className='required'>* Campo obrigatório</Typography>
                  </PaperFormContainer>

                  <BtnContainer>
                    <Button
                      type='button'
                      variant='outlined'
                      className='back_btn'
                      disabled={isSaving}
                      onClick={handleBackToPage}
                    >
                      Voltar
                    </Button>

                    <Button
                      type='submit'
                      variant='contained'
                      className='submit_btn'
                      disabled={isSaving}
                      startIcon={isSaving
                        ? (
                          <CircularProgress
                            size={20}
                            variant='indeterminate'
                            color='inherit'
                          />
                        ) : undefined}
                    >
                      Salvar
                    </Button>
                  </BtnContainer>
                </form>
              )
            }}
          />
        </>
      )}
    </Box>
  )
}

export default TeacherForm
