import React, { useEffect, useMemo } from 'react'

// Components
import { Box, Paper, Skeleton, Typography } from '@mui/material'
import BookInformations from '../components/Books/BookInformations'
import Button from '../components/button/Button'
import ChapterViewCollapse from '../components/Books/collapse/ChapterViewCollapse'
import ErrorMessage from '../components/message/ErrorMessage'

// Redux
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import {
  fetchBookDetailsRequest,
  clearBookDetails
} from '../store/books/actions'

// Icon and images
import { ReactComponent as CheckIcon } from '../assets/components/Books/new-check-icon.svg'

// Styles
import { Header, PaperContainer } from './styles/BooksDetailsContainer.styles'

const Loading = () => {
  return (
    <Box>
      <Header>
        <Skeleton
          animation='wave'
          variant='text'
          sx={{ fontSize: '18px', width: '300px' }}
        />

        <Skeleton
          animation='wave'
          variant='rounded'
          sx={{
            borderRadius: '8px',
            height: '44px',
            width: '148px',
          }}
        />
      </Header>

      <Paper sx={{ borderRadius: '16px', padding: '32px 24px' }}>
        <Skeleton
          animation='wave'
          variant='text'
          sx={{ fontSize: '18px', marginBottom: '16px', width: '250px' }}
        />

        <Box sx={{ display: 'flex', gap: '24px' }}>
          <Skeleton
            animation='wave'
            variant='rectangular'
            sx={{ borderRadius: '8px', height: '180px', width: '114px' }}
          />

          <Box sx={{ flex: 1 }}>
            <Skeleton
              animation='wave'
              variant='text'
              sx={{ fontSize: '24px', width: '200px' }}
            />

            <Box sx={{ display: 'flex', gap: '40px' }}>
              <Skeleton
                animation='wave'
                variant='text'
                sx={{ fontSize: '16px', width: '50px' }}
              />
              <Skeleton
                animation='wave'
                variant='circular'
                width={24}
                height={24}
              />
            </Box>

            <Skeleton
              animation='wave'
              variant='rounded'
              sx={{ height: '90px', marginTop: '16px' }}
            />
          </Box>
        </Box>

        <Box sx={{ alignItems: 'center', display: 'flex', gap: '32px', marginTop: '50px' }}>
          {Array(3).fill(0).map((_, index) => {
            return (
              <Skeleton
                key={index}
                animation='wave'
                variant='rounded'
                sx={{ width: '120px', height: '15px' }}
              />
            )
          })}
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            marginTop: '16px'
          }}
        >
          {Array(5).fill(0).map((_, index) => {
            return (
              <Skeleton
                key={index}
                animation='wave'
                variant='rounded'
                sx={{
                  borderRadius: '8px',
                  height: '68px',
                  width: '100%'
                }}
              />
            )
          })}
        </Box>
      </Paper>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '32px' }}>
        <Skeleton
          animation='wave'
          variant='rounded'
          sx={{
            borderRadius: '8px',
            height: '52px',
            width: '140px'
          }}
        />
      </Box>
    </Box>
  )
}

interface IBooksDetailsContainerProps {
  id: number
}

const BooksDetailsContainerTwo: React.FC<IBooksDetailsContainerProps> = ({ id }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { bookDetails: { isError, isFetching, item } } = useAppSelector(state => state.books)

  const chapters = useMemo(() => {
    const items = item?.chapters ? [ ...item.chapters ] : []
    const chaptersOrdered = items?.sort((a, b) => Number(a.number) - Number(b.number))
    return chaptersOrdered
  }, [item?.chapters])

  const handleBackPage = () => {
    navigate('/books')
  }

  useEffect(() => {
    id && dispatch(fetchBookDetailsRequest({ bookId: id }))

    return () => {
      dispatch(clearBookDetails())
    }
  }, [])

  if (isFetching) {
    return (
      <Loading />
    )
  }

  return (
    <Box>
      <Header>
        <Box className='title'>
          <CheckIcon />
          <Typography component='h2' className='primary_title'>
            {item?.title} / Visualizar livro
          </Typography>
        </Box>

        <Button
          type='button'
          variant='contained'
          onClick={handleBackPage}
        >
          Voltar
        </Button>
      </Header>

      {!isFetching && !isError && (
        <Paper sx={{ borderRadius: '16px', padding: '21px 28px' }}>
          <BookInformations
            bookAuthors={item?.authors}
            bookCover={item?.photo_url}
            bookCoauthors={item?.co_authors}
            bookDescription={item?.description}
            bookId={item?.id}
            bookProgress={undefined}
            bookSubjects={item?.subjects}
            bookTitle={item?.title}
          />

          <PaperContainer>
            {chapters?.map((chapter) => {
              return (
                <ChapterViewCollapse
                  key={chapter.id}
                  chapterData={chapter}
                />
              )
            })}
          </PaperContainer>
        </Paper>
      )}

      {isError && (
        <Paper sx={{ borderRadius: '16px', padding: '21px 28px' }}>
          <ErrorMessage />
        </Paper>
      )}
    </Box>
  )
}

export default BooksDetailsContainerTwo
