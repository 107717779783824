import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const ChapterItemContainer = styled(Box)(({ theme }) => ({
  background: '#ffffff',
  border: '1px dashed #b7b2c8',
  borderRadius: '16px',
  padding: '24px 16px',

  '& .chapter_item': {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',

    '&-title': {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      gap: '8px',

      '& span:first-of-type': {
        color: theme.palette.primary.main,
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '150%',
      },

      '& span:last-of-type': {
        color: theme.palette.text.primary,
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '150%',
      }
    },

    '&-actions': {
      alignItems: 'center',
      display: 'flex',
      gap: '24px',

      '& .order': {
        alignItems: 'center',
        display: 'flex',
        gap: '8px',

        '& span': {
          color: '#9f9f9f',
          fontSize: '14px',
          fontWeight: 700
        }
      },

      '& .btn': {
        height: '18px',
        width: '18px'
      }
    }
  },

  '& .chapter_description': {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '150%',
    marginTop: '16px',
  }
}))

export const CollapseContent = styled(Box)(({ theme }) => ({
  '& .title': {
    color: theme.palette.text.primary,
    fontSize: '16px',
    fontWeight: 700,
    marginTop: '32px'
  },

  '& .dates_container': {
    background: '#e4e9eb',
    borderRadius: '16px',
    margin: '16px 0 24px',
    padding: '24px 16px',

    '& .label': {
      color: theme.palette.text.primary,
      fontSize: '16px',
      fontWeight: 700,
      marginBottom: '8px'
    }
  },

  '& .school_items': {
    display: 'flex',
    flexDirection: 'column',
    gap: '40px'
  }
}))

export const FormBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '32px',

  '& .field': {
    alignItems: 'center',
    display: 'flex',
    gap: '8px',

    '&_label': {
      color: theme.palette.text.primary,
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: '0.14px'
    },

    '&_datepicker': {
      maxWidth: '170px',
    }
  }
}))
