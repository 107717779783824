import http from '../../utils/http'

const classroomEndpoints = '/classrooms'

export const fetchClassroom = (params: object) => {
  return http.get(classroomEndpoints, { params })

}

export const fetchClassroomWithStudents = (params: object) => {
  return http.get(`${classroomEndpoints}/with_students`, { params })
}

export const fetchManagerClassroomWithStudents = (params: object) => {
  return http.get(`${classroomEndpoints}/with_students_for_coordinator`, { params })
}

export const getClassroomsWithStudents = (params: object) => {
  return http.get(`${classroomEndpoints}/with_students_for_coordinator`, { params })
}

export const postMigrateStudents = ({ classroomId, studentsId }: { classroomId: number, studentsId: string }) => {
  return http.post(`${classroomEndpoints}/${classroomId}/migrate_students/?users_ids=${studentsId}`)
}
