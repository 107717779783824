import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const ModalContainer = styled(Box)(({ theme }) => ({
  padding: '0 24px 24px',

  '& .title': {
    alignItems: 'center',
    display: 'flex',
    gap: '16px',
    marginBottom: '32px',

    '& > h2': {
      color: theme.palette.text.primary,
      fontSize: '24px',
      fontWeight: 600,
      letterSpacing: '0.24px',
    }
  },

  '& .primary_label': {
    color: theme.palette.text.primary,
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '120%',
  },

  '& .secondary_label': {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '120%',
    marginBottom: '16px',
  },

  '& .data_item': {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',

    '& .label': {
      color: '#9f9f9f',
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: '0.14px',
    },

    '& .value': {
      color: theme.palette.text.primary,
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '120%',
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }
  },

  '& .form_data': {
    marginTop: '32px',
  },

  '& .additional_data-form': {
    marginTop: '66px',
  },

  '& .parents': {
    marginTop: '56px',

    '& .parents_container': {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
    }
  },

  '& .parent_form': {
    background: '#f9f9f9',
    borderRadius: '8px',
    marginTop: '8px',
    padding: '24px 16px',
  },

  '& .secondary_label-light': {
    color: '#9f9f9f',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '120%',
    margin: '32px 0 16px',
  },

  '& .data_item-light': {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',

    '& .label_light': {
      color: '#9f9f9f',
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: '0.14px',
    },

    '& .value_light': {
      color: '#9f9f9f',
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '120%',
    }
  }
}))
