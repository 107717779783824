import React, { useState } from 'react'

// Components
import {
  Box,
  CircularProgress,
  Grid,
  LinearProgress,
  Typography, Skeleton,
  Button
} from '@mui/material'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'

// Redux
import { fetchBookInfo } from '../../store/books/actions'
import { useAppDispatch } from '../../store/hooks'

// Utils
import { Books, IUnitsPerformance } from '../../models/IBooks'

// Icons and images
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { ReactComponent as ChartIcon } from '../../assets/components/Books/chart-icon.svg'
import { ReactComponent as PeopleIcon } from '../../assets/components/Books/people-icon.svg'
import { ReactComponent as ViewSVG } from '../../assets/components/Books/view-icon.svg'

// Styles
import {
  ClassroomsContainer,
  LoadingContainer,
  RowBookContainer,
  TableBody,
  TableCell,
  TableHeader,
  UnitLabel
} from './styles/BooksCatalogTable.styles'
import ErrorMessage from '../message/ErrorMessage'

interface IRowUnitProps {
  unit: IUnitsPerformance
}

const RowUnit: React.FC<IRowUnitProps> = ({ unit }) => {
  const [open, setOpen] = useState(false)

  const handleOpenUnitInfo = () => {
    unit?.school_classrooms?.length > 0 && setOpen(prevState => !prevState)
  }

  return (
    <>
      <UnitLabel onClick={handleOpenUnitInfo}>
        <Box className='unit_name'>
          <Box component='span'>{unit?.name}</Box>
          <Box component='span'>({unit?.school_classrooms?.length ?? 0})</Box>
          <IconButton
            aria-label='expand row'
            size='small'
            sx={{ padding: 0 }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </Box>

        <Box className='unit_performance'>
          <Box component='span'>Performance</Box>
          <LinearProgress
            variant='determinate'
            sx={{
              background: '#ccc',
              width: '200px',
              '& .MuiLinearProgress-bar': {
                background: unit?.total_performance < 50 ? '#F69E9E' : '#BC9BFA'
              }
            }}
            value={unit?.total_performance ?? 0}
          />
          <Box component='span'>
            {unit?.total_performance ?? 0}%
          </Box>
        </Box>
      </UnitLabel>

      <Collapse in={open}>
        <ClassroomsContainer>
          <Box className='classroom_container'>
            {unit?.school_classrooms?.map((classroom) => (
              <Grid
                container
                columnSpacing={1}
                key={classroom?.id}
                className='classroom'
              >
                <Grid item xs={3} className='item'>
                  <Box className='classroom_name'>
                    {classroom?.name}
                  </Box>
                </Grid>

                <Grid item xs={3} className='item'>
                  <Box className='classroom_students'>
                    <PeopleIcon />
                    <Box component='span'>{classroom?.students_with_performance} / </Box>
                    <Box component='span'>{classroom?.total_students}</Box>
                  </Box>
                </Grid>

                <Grid item xs={3} className='item'>
                  <Box className='classroom_performance'>
                    <ChartIcon />
                    <Box component='span'>Desempenho</Box>
                    <LinearProgress
                      variant='determinate'
                      color={classroom?.average_performance > 50 ? 'secondary' : 'error'}
                      sx={{ width: '48px' }}
                      value={classroom?.average_performance ?? 0}
                    />
                    <Box component='span'>{classroom?.average_performance ?? 0}%</Box>
                  </Box>
                </Grid>

                <Grid item xs={3} className='item'>
                  <Box className='classroom_actions'>
                    <Button
                      type='button'
                      variant='text'
                      startIcon={<ViewSVG />}
                    >
                      Visualizar turma
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            ))}
          </Box>
        </ClassroomsContainer>
      </Collapse>
    </>
  )
}


interface IRowBookProps {
  row: Books
}

const RowBook: React.FC<IRowBookProps> = ({ row }) => {
  const dispatch = useAppDispatch()
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [units, setUnits] = useState<IUnitsPerformance[]>([])

  const getBookInfo = async (bookId: number) => {
    setIsLoading(true)
    setIsError(false)
    try {
      const response: any = await dispatch(fetchBookInfo.request({ id: bookId }))
      setUnits(response.data)
    } catch (error) {
      console.error(error)
      setIsError(true)
    }
    setIsLoading(false)
  }

  const handleBookInfo = () => {
    setOpen(prevState => !prevState)
    getBookInfo(row.id)
  }

  return (
    <RowBookContainer>
      <TableCell container columnSpacing={2} onClick={handleBookInfo}>
        <Grid item xs={3} className='cell'>
          <Typography className='book_info title'>{row.name}</Typography>
        </Grid>

        <Grid item xs={2} className='cell'>
          <Typography className='book_info'>
            {row?.units?.map(unit => unit?.name).join(', ')}
          </Typography>
        </Grid>

        <Grid item xs={3} className='cell'>
          <Typography className='book_info'>
            {row?.authors?.map(author => author?.name).join(', ')}
          </Typography>
        </Grid>

        <Grid item xs={2} className='cell'>
          <Typography className='book_info'>
            {row?.subjects?.map(subject => subject?.name).join(', ')}
          </Typography>
        </Grid>

        <Grid item xs={1} className='cell'>
          <Typography className='book_info'>
            {row?.access ?? 0}
          </Typography>
        </Grid>

        <Grid item xs={1} className='cell'>
          <IconButton
            aria-label='expand row'
            size='small'
            sx={{ padding: 0 }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </Grid>
      </TableCell>

      <Collapse in={open}>
        {isLoading && (
          <LoadingContainer>
            <CircularProgress size={20} />
          </LoadingContainer>
        )}

        {isError && (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '16px' }}>
            <Typography>Não foi possível carregar os dados para este livro.</Typography>
          </Box>
        )}

        {!isLoading && !isError && (
          <Box>
            {units?.map((unit) => {
              return (
                <RowUnit
                  key={unit.id}
                  unit={unit}
                />
              )
            })}
          </Box>
        )}
      </Collapse>
    </RowBookContainer>
  )
}

interface IBooksCatalogTableProps {
  books: Books[]
  isFetching: boolean
  isError: boolean
}

const BooksCatalogTable: React.FC<IBooksCatalogTableProps> = ({ books, isFetching, isError }) => {
  return (
    <>
      {!isError && (
        <TableHeader container columnSpacing={2}>
          <Grid item xs={3} className='theader_cell'>Título</Grid>
          <Grid item xs={2} className='theader_cell'>Escola</Grid>
          <Grid item xs={3} className='theader_cell'>Autor</Grid>
          <Grid item xs={2} className='theader_cell'>Disciplina</Grid>
          <Grid item xs={1} className='theader_cell'>Acessos</Grid>
          <Grid item xs={1} className='theader_cell'></Grid>
        </TableHeader>
      )}

      {isFetching && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', width: '100%' }}>
          {Array(10).fill(0).map((_, index) => (
            <Skeleton
              key={index}
              animation='wave'
              variant='rounded'
              sx={{
                borderRadius: '8px',
                height: '56px',
                width: '100%'
              }}
            />
          ))}
        </Box>
      )}

      {!isFetching && !isError && (
        <TableBody>
          {books?.map((book) => (
            <RowBook key={book.id} row={book} />
          ))}
        </TableBody>
      )}

      {isError && <ErrorMessage />}
    </>
  )
}

export default BooksCatalogTable
