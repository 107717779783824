// React
import React, { useEffect, useState } from 'react'

// Components
import {
  Box,
  Collapse,
  IconButton,
  Typography
} from '@mui/material'
import SchoolChapterItem from './SchoolChapterItem'
import SimpleDatePicker from '../../input/SimpleDatePicker'

// Utils
import { format } from 'date-fns'
import { Dates, IChapterToPublish } from '../../../models/IBooks'

// Icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { ReactComponent as OrderIcon } from '../../../assets/components/Books/order-icon.svg'
import ChapterIcon from '../../../assets/components/Books/chapter-icon.svg'

// Styles
import { ChapterItemContainer, CollapseContent, FormBox } from './styles/ChapterItem.styles'

interface ChapterItemProps {
  chapterData: IChapterToPublish
  chapterIndex: number
  dates: Dates[]
  addDates: (x: any) => void
  changeChapterDate: (chapterId: number, classroomChapterId: number, key: any, value: Date) => void
  changeModuleDate: (chapterId: number, classroomChapterId: number, moduleId: number, key: any, value: Date) => void
  isEdit: boolean
}

const ChapterItem: React.FC<ChapterItemProps> = ({
  chapterData,
  chapterIndex,
  dates,
  addDates,
  changeChapterDate,
  changeModuleDate,
  isEdit
}) => {
  const [openChapter, setOpenChapter] = useState(false)
  const [date, setDate] = useState<{ start_date?: string, end_date?: string }>({ start_date: undefined, end_date: undefined })

  const schoolsFiltered = chapterData?.classroom_chapters?.map((item) => {
    return item.school_classroom.school_unit
  }).filter((item: any, index: number, self: any) => (
    index === self.findIndex((t: any) => t.id === item.id)
  ))

  const schools = schoolsFiltered?.map((item) => {
    return {
      id: item.id,
      name: item.name,
      numberOfClassrooms: chapterData?.classroom_chapters?.filter((i) => i.school_classroom.school_unit.id === item.id)?.length,
      schoolData: chapterData?.classroom_chapters.filter((x) => x.school_classroom.school_unit.id === item.id)
    }
  })

  const classroomChaptersIds = chapterData?.classroom_chapters?.map((item) => item.id)

  const handleChangeAllSchoolsDates = (
    chapterId: number,
    classroomChapterIds: number[],
    key: string,
    value: Date
  ) => {
    setDate({ ...date, [key]: value })
    const chapterIndex = dates.findIndex((item) => item.chapterId === chapterId)
    if (chapterIndex === -1) {
      addDates((prevState: any) => [
        ...prevState,
        {
          chapterId,
          classroom_chapters: classroomChapterIds.map((item) => ({
            id: item,
            [key]: key === 'start_date' ? `${format(value, 'dd/MM/yyyy')} 00:00` : `${format(value, 'dd/MM/yyyy')} 23:59`,
            classroom_modules: []
          }))
        }
      ])
    } else {
      const update = [...dates]
      update[chapterIndex] = {
        ...update[chapterIndex],
        classroom_chapters: classroomChapterIds.map((_, index) => ({
          ...update[chapterIndex].classroom_chapters[index],
          [key]: key === 'start_date' ? `${format(value, 'dd/MM/yyyy')} 00:00` : `${format(value, 'dd/MM/yyyy')} 23:59`,
          classroom_modules: []
        }))
      }
      addDates(update)
    }
  }

  const filterRangeStartDate = (time: any) => {
    const selectedDate = String(date.end_date)
    const selectDateTime = new Date(selectedDate)
    return time < selectDateTime.getTime()
  }

  const filterRangeEndDate = (time: any) => {
    const selectedDate = String(date.start_date)
    const selectedDateTime = new Date(selectedDate)
    return time > selectedDateTime.getTime()
  }

  useEffect(() => {
    chapterIndex === 0 && setOpenChapter(true)
  }, [])

  return (
    <ChapterItemContainer>
      <Box>
        <Box className='chapter_item'>
          <Box
            className='chapter_item-title'
            onClick={() => setOpenChapter(prevState => !prevState)}
          >
            <img src={ChapterIcon} />
            <span>Capítulo {chapterData?.chapter_order} - </span>
            <span>{chapterData?.name}</span>
          </Box>

          <Box className='chapter_item-actions'>
            <Box className='order'>
              <OrderIcon width='16px' height='12.5px' />
              <Box component='span'>
                {chapterData?.chapter_order}
              </Box>
            </Box>

            <IconButton
              onClick={() => setOpenChapter(prevState => !prevState)}
              className='btn'
            >
              <KeyboardArrowDownIcon
                fontSize='small'
                sx={{ transform: openChapter ? 'rotate(180deg)' : 'rotate(0deg)' }}
              />
            </IconButton>
          </Box>
        </Box>

        <Typography className='chapter_description'>
          {chapterData?.description}
        </Typography>

        <Collapse in={openChapter}>
          <CollapseContent>
            <Typography className='title'>
              Defina as datas de início e fim desse capítulo
            </Typography>

            <Box className='dates_container'>
              <Typography className='label'>
                Quer definir uma data única para publicar este capítulo em todas as escolas?
              </Typography>

              <FormBox>
                <Box className='field'>
                  <Typography className='field_label'>Início:</Typography>

                  <Box className='field_datepicker'>
                    <SimpleDatePicker
                      name='start_date'
                      placeholder='dd/mm/aaaa'
                      autoComplete='off'
                      locale='pt-BR'
                      selectsStart
                      startDate={date?.start_date}
                      endDate={date?.end_date}
                      maxDate={date?.end_date}
                      changeDate={(value: any) =>
                        handleChangeAllSchoolsDates(
                          chapterData.id,
                          classroomChaptersIds,
                          'start_date',
                          value
                        )}
                      filterTime={date.end_date && filterRangeStartDate}
                      value={date?.start_date}
                      readOnly
                    />
                  </Box>
                </Box>

                <Box className='field'>
                  <Typography className='field_label'>Fim:</Typography>

                  <Box className='field_datepicker'>
                    <SimpleDatePicker
                      name='end_date'
                      placeholder='dd/mm/aaaa'
                      autoComplete='off'
                      locale='pt-BR'
                      selectsEnd
                      startDate={date?.start_date}
                      endDate={date?.end_date}
                      minDate={date?.start_date}
                      changeDate={(value: any) =>
                        handleChangeAllSchoolsDates(
                          chapterData.id,
                          classroomChaptersIds,
                          'end_date',
                          value
                        )}
                      filterTime={filterRangeEndDate}
                      value={date?.end_date}
                      readOnly
                    />
                  </Box>
                </Box>
              </FormBox>
            </Box>

            <Box className='school_items'>
              {schools && schools?.length > 0 && schools?.map((school) => {
                return (
                  <SchoolChapterItem
                    key={school.id}
                    school={school}
                    chapterId={chapterData.id}
                    chapterIndex={chapterIndex}
                    dates={dates}
                    addDates={addDates}
                    changeChapterDate={changeChapterDate}
                    changeModuleDate={changeModuleDate}
                    disabledSchoolDateSelector={!!date?.start_date || !!date?.end_date}
                    isEdit={isEdit}
                  />
                )
              })}
            </Box>
          </CollapseContent>
        </Collapse>
      </Box>
    </ChapterItemContainer>
  )
}

export default ChapterItem
