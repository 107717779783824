/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { LinearProgress } from '@mui/material'
import StudentFormTwo from '../components/Management/form/StudentFormTwo'
import { getStudentByIdRequest } from '../store/students/actions'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { convertDate, cpfFormatter } from '../utils/functions'

function StudentFormEditContainer() {
  const [formValues, setFormValues] = useState({} as any)
  const { id } = useParams()
  const { currentItem } = useAppSelector((state) => state.students)
  const dispatch = useAppDispatch()
  const student = currentItem.item

  const initialValues = {
    birthday: student?.user?.birthday && convertDate(student?.user?.birthday),
    classrooms: student?.classrooms?.map((classroom) => {
      return {
        classroom: {
          grade: classroom?.grade,
          id: classroom?.id,
          name: classroom?.name
        },
        grade: classroom?.grade?.name
      }
    }),
    complement: student?.user?.additional_data?.address?.complement,
    email: student?.user?.email,
    genre: student?.user?.genre === 'male'
      ? { name: 'Masculino', value: 'male' }
      : student?.user?.genre === 'female'
        ? { name: 'Feminino', value: 'female' }
        : undefined,
    name: student?.user?.name,
    neighborhood: student?.user?.additional_data?.address?.neighborhood,
    number: student?.user?.additional_data?.address?.number,
    parents: student?.parents?.map((parent) => {
      return {
        additional_data: parent?.additional_data,
        birthday: parent?.birthday && convertDate(parent?.birthday),
        email: parent?.email,
        name: parent?.name,
        phone: parent?.phone,
        taxpayer_number: parent?.taxpayer_number && cpfFormatter(parent?.taxpayer_number)
      }
    }),
    phone: student?.user?.phone,
    registration: student?.classroom_student?.registration,
    street: student?.user?.additional_data?.address?.street,
    taxpayer_number: student?.user?.taxpayer_number && cpfFormatter(student?.user?.taxpayer_number),
    zipcode: student?.user?.additional_data?.address?.zipcode,
    id
  }

  useEffect(() => {
    if (id) {
      dispatch(getStudentByIdRequest(id))
    }
  }, [])

  useEffect(() => {
    setFormValues({
      ...initialValues
    })
  }, [currentItem])

  return (
    <>
      {currentItem.isFetching ? (
        <LinearProgress color='secondary' />
      ) : (
        <StudentFormTwo initialValues={formValues} />
      )}
    </>
  )
}

export default StudentFormEditContainer
