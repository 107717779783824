import { Box, Grid } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Container = styled(Box)(() => ({
  background: '#fff',
  borderRadius: '8px',
}))

export const GridContainer = styled(Grid)(({ theme }) => ({
  cursor: 'pointer',

  '& .grid_item': {
    alignItems: 'center',
    display: 'flex',

    '&:nth-of-type(1)': {
      padding: '11px 0 11px 20px'
    },

    '&:nth-of-type(2), &:nth-of-type(3)': {
      justifyContent: 'center',
      paddingBlock: '11px',
    },

    '&:last-of-type': {
      // cursor: 'pointer',
      justifyContent: 'flex-end',
      padding: '11px 20px 11px 0'
    }
  },

  '& .classroom_name': {
    background: theme.palette.primary.light,
    borderRadius: '8px',
    color: '#f9f9f9',
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: '0.14px',
    padding: '5.5px 18px',
    width: 'fit-content'
  },

  '& .shift, & .school_year': {
    alignItems: 'center',
    color: theme.palette.text.primary,
    display: 'flex',
    fontSize: '14px',
    fontWeight: 500,
    gap: '4px',
    lineHeight: '120%'
  },

  '& .students_qtde': {
    alignItems: 'center',
    color: theme.palette.text.primary,
    display: 'flex',
    fontSize: '14px',
    fontWeight: 500,
    gap: '4px',
    lineHeight: '120%',
  }
}))

export const StudentsContainer = styled(Box)(({ theme }) => ({
  padding: '0 20px 28px',

  '& .table_header': {
    marginBottom: '24px',

    '& .thead:last-of-type': {
      display: 'flex',
      justifyContent: 'flex-end'
    },

    '& .label': {
      color: theme.palette.text.primary,
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '120%'
    }
  },

  '& .table_body': {
    '& .tbody': {
      alignItems: 'center',
      display: 'flex',

      '&.last': {
        alignItems: 'flex-start',
        display: 'flex',
        justifyContent: 'flex-end'
      },

      '&.first': {
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }
    },

    '& .teacher_name': {
      alignItems: 'center',
      display: 'flex',
      gap: '8px',

      '& > p': {
        color: theme.palette.text.primary,
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '120%',
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }
    },

    '& .contact, & .email': {
      color: theme.palette.text.primary,
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '120%',
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },

    '& .email': {
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },

    '& .actions': {
      alignItems: 'center',
      display: 'flex',
    }
  },

  '& .btn_container': {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '16px',
    margin: '16px 0 32px',

    '& .btn_add': {
      borderRadius: '8px',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '120%',
      padding: '14.5px 24px',
      textTransform: 'none',
    },

    '& .btn_migration': {
      background: theme.palette.primary.light,
      borderRadius: '8px',
      color: '#fff',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '120%',
      padding: '14.5px 24px',
      textTransform: 'none',
      transition: 'background 0.3s ease-in-out',

      '&:hover': {
        background: '#a782ec',
      }
    }
  }
}))

export const EmptyBox = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  justifyContent: 'center',

  '& .image': {
    maxWidth: '350px',

    '& > img': {
      width: '100%',
    }
  },

  '& .message': {
    color: theme.palette.text.primary,
    fontSize: '18px',
    fontWeight: 500,
    letterSpacing: '0.18px'
  }
}))
