import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const ModuleItemContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  background: '#EFE7FF',
  borderRadius: '8px',
  cursor: 'pointer',
  display: 'flex',
  gap: '30px',
  padding: '6px 16px',

  [theme.breakpoints.down(1190)]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    padding: '16px'
  },

  '& .module': {
    display: 'flex',
    gap: '16px',

    '&_code': {
      alignItems: 'center',
      display: 'flex',

      '& img': {
        marginRight: '8px'
      },

      '& span': {
        fontSize: '14px',
        fontWeight: 600,

        '&:first-of-type': {
          color: theme.palette.text.primary,
          marginRight: '4px'
        },

        '&:last-of-type': {
          color: theme.palette.primary.light
        }
      }
    },

    '&_name': {
      alignItems: 'center',
      display: 'flex',

      '& span': {
        fontSize: '14px',
        fontWeight: 600,

        '&:first-of-type': {
          color: theme.palette.primary.main,
          marginRight: '4px'
        },

        '&:last-of-type': {
          color: theme.palette.text.primary,
          maxWidth: '350px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }
      }
    }
  }
}))

export const FormBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  gap: '32px',
  justifyContent: 'flex-end',

  '& .field': {
    alignItems: 'center',
    display: 'flex',
    gap: '8px',

    '&_label': {
      color: theme.palette.text.primary,
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: '0.14px'
    },

    '&_datepicker': {
      maxWidth: '170px',
    }
  }
}))
