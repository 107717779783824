import { Box, Grid } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Container = styled(Box)(() => ({
  background: '#fff',
  borderRadius: '8px',
}))

export const GridContainer = styled(Grid)(({ theme }) => ({
  '& .grid_item': {
    alignItems: 'center',
    display: 'flex',

    '&:nth-of-type(1)': {
      cursor: 'pointer',
      padding: '11px 0 11px 20px'
    },

    '&:nth-of-type(2)': {
      cursor: 'pointer'
    },

    '&:nth-of-type(2), &:nth-of-type(3)': {
      justifyContent: 'center',
      paddingBlock: '11px',
    },

    '&:last-of-type': {
      cursor: 'pointer',
      justifyContent: 'flex-end',
      padding: '11px 20px 11px 0'
    }
  },

  '& .classroom_name': {
    background: theme.palette.primary.light,
    borderRadius: '8px',
    color: '#f9f9f9',
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: '0.14px',
    padding: '5.5px 18px',
    width: 'fit-content'
  },

  '& .shift': {
    alignItems: 'center',
    color: theme.palette.text.primary,
    display: 'flex',
    fontSize: '14px',
    fontWeight: 500,
    gap: '4px',
    lineHeight: '120%'
  },

  '& .select_all': {
    alignItems: 'center',
    display: 'flex',
    gap: '4px',

    '& label': {
      color: theme.palette.text.primary,
      cursor: 'pointer',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '120%'
    },

    '& .MuiCheckbox-root': {
      padding: 0,
    }
  },

  '& .teachers_qtde': {
    alignItems: 'center',
    color: theme.palette.text.primary,
    display: 'flex',
    fontSize: '14px',
    fontWeight: 500,
    gap: '4px',
    lineHeight: '120%',
  }
}))

export const TeachersContainer = styled(Box)(({ theme }) => ({
  padding: '21px 20px 28px',

  '& .table_header': {
    marginBottom: '24px',

    '& .thead:last-of-type': {
      display: 'flex',
      justifyContent: 'flex-end'
    },

    '& .label': {
      color: theme.palette.text.primary,
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '120%'
    }
  },

  '& .table_body': {
    '& .tbody': {
      '&.last': {
        display: 'flex',
        justifyContent: 'flex-end'
      },

      '&.first': {
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }
    },

    '& .teacher_name': {
      alignItems: 'center',
      display: 'flex',
      gap: '8px',

      '& .MuiCheckbox-root': {
        padding: 0,
      },

      '& label': {
        alignItems: 'center',
        display: 'flex',
        gap: '8px',
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',

        '& > p': {
          color: theme.palette.text.primary,
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '120%',
          maxWidth: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }
      }
    },

    '& .subject, & .email': {
      color: theme.palette.text.primary,
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '120%'
    },

    '& .email': {
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },

    '& .actions': {
      alignItems: 'center',
      display: 'flex',
    }
  }
}))

export const InfoDataContainer = styled(Box)(({ theme }) => ({
  padding: '0 16px 36px',

  '& .primary_title': {
    alignItems: 'center',
    display: 'flex',
    gap: '16px',
    marginBottom: '32px',

    '& .title': {
      color: theme.palette.text.primary,
      fontSize: '24px',
      fontWeight: 600,
      letterSpacing: '0.24px'
    }
  },

  '& .secondary_title': {
    color: theme.palette.text.primary,
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '120%',
    marginBottom: '32px',
  },

  '& .subtitle': {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '120%',
    marginBottom: '16px'
  },

  '& .item': {
    display: 'flex',
    gap: '8px',
    flexDirection: 'column',

    '& .label': {
      color: '#9f9f9f',
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: '0.14px'
    },

    '& .data': {
      color: theme.palette.text.primary,
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '120%',

      '&.name, &.subject, &.email': {
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }
    }
  }
}))

export const LoadingBox = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: '16px',
  justifyContent: 'center',

  '& p': {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 600
  }
}))

export const BadgeBox = styled('span')(({ theme }) => ({
  background: theme.palette.primary.light,
  borderRadius: '100%',
  color: '#fff',
  fontSize: '12px',
  fontWeight: 600,
  padding: '4px',
}))
