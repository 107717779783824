import React, { useRef, useState } from 'react'
import Editor from '../../BundleEditor'
import { Editor as TinyMCEEditor } from 'tinymce'
import { FieldRenderProps } from 'react-final-form'
import Typography from '@mui/material/Typography'
import http from '../../utils/http'
import { Box } from '@mui/system'

type ISimpleEditorProps = FieldRenderProps<string, HTMLElement> & {
  input: {
    name: string
    onChange: (value: string) => void
    value: string
  }
  meta: {
    touched: boolean
    error: string
  }
  placeholder: string
}

const SimpleEditor: React.FC<ISimpleEditorProps> = ({ input, meta, placeholder }) => {
  const editorRef = useRef<TinyMCEEditor | null>(null)

  const [value, setValue] = useState<string>(input.value || '')

  const getBaseUrl = () => {
    return location.origin
  }

  const handleEditorChange = (content: string) => {
    setValue(content)
    input.onChange(content)
  }

  return (

    <Box
      sx={{
        '.tox-tinymce': {
          minHeight: '250px'
        }
      }}
    >
      <Editor
        // eslint-disable-next-line no-return-assign
        onInit={(_evt: any, editor: any) => editorRef.current = editor}
        onEditorChange={handleEditorChange}
        onBlur={(e: any) => input.onBlur(e.target.value)}
        value={value}
        init={{
          height: 250,
          menubar: false,
          placeholder: placeholder,
          browser_spellcheck: true,
          contextmenu: false,
          content_style:
            'body { font-family:Source Sans,sans-serif; overflow-y: auto !important; border-radius: 4px }',
          nonbreaking_force_tab: true,
          autoresize_bottom_margin: 1,
          branding: false,
          plugins: 'lists link image preview anchor code fullscreen charmap autolink advlist searchreplace visualblocks insertdatetime media table  autoresize nonbreaking',
          toolbar: 'undo redo | formatselect | charmap eqneditor ' +
            'bold italic underline backcolor | alignleft aligncenter ' +
            'alignright alignjustify forecolor  | bullist numlist outdent indent ' +
            'link image code | table | removeformat fullscreen',
          external_plugins: {
            eqneditor: `${getBaseUrl()}/tiny_mce_v4/plugins/eqneditor/eqneditor.js`
          },
          language: 'pt_BR',
          language_url: `${getBaseUrl()}/tiny_mce_v4/langs/pt_BR.js`,
          entity_encoding: 'raw',
          paste_as_text: true,
          paste_word_valid_elements: 'b,strong,i,em,h1,h2',
          paste_retain_style_properties:
            'color font-size font-style text-decoration font-weight',
          paste_enable_default_filters: false,
          paste_filter_drop: false,
          image_title: true,
          automatic_uploads: true,
          file_picker_types: 'image',
          file_picker_callback: (cb: any, value: any, meta: any) => { // eslint-disable-line
            const input = document.createElement('input')
            input.setAttribute('type', 'file')
            input.setAttribute('accept', 'image/*')

            input.onchange = () => {
              const file = input.files?.[0]
              if (!file) return
              const reader = new FileReader()
              reader.readAsDataURL(file)

              reader.onload = () => {
                const id = 'blobid' + new Date().getTime()
                const blobCache = editorRef.current?.editorUpload?.blobCache
                const base64Data = reader.result?.toString().split(',')[1]
                if (!base64Data) return
                const blobInfo = blobCache?.create(id, file, base64Data)
                const data = new FormData()
                if (!blobInfo) return
                data.append('attachment', blobInfo.blob())
                const config = {
                  headers: { 'content-type': 'multipart/form-data' }
                }
                http
                  .post('/images/upload', data, config)
                  .then(({ data: { data } }) => {
                    cb(data.url, { title: file.name, width: '100%', aspectRatio: 'auto' })
                  })
                  .catch(err => {
                    console.error(err)
                    cb('Erro ao carregar imagem') // eslint-disable-line
                  })
              }
            }
            input.click()
          }

        }}
      />
      {meta.touched && meta.error && (
        <Typography
          align='left'
          component='p'
          variant='caption'
          sx={{
            color: meta.touched && meta.error ? '#F69E9E' : '#D9D9D9'
          }}
        >
          {meta.error}
        </Typography>
      )}
    </Box>
  )
}

export default SimpleEditor
