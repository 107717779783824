
import { createReducer } from '@reduxjs/toolkit'
import * as actions from './actions'
import { AnyAction } from 'redux-saga'
import { ISchoolAppIds } from '../../models/IAppId'

interface StateType {
  items: ISchoolAppIds[]
  isFetching: boolean
  isError: boolean
  isSaving: boolean
  messageError: string | null
}

const initialState: StateType = {
  items: [],
  isFetching: false,
  isError: false,
  isSaving: false,
  messageError: null,
}

const schoolAppIdReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.getSchoolAppIdRequest, (state: StateType) => ({
      ...state,
      isFetching: true,
      isError: false,
      messageError: null,
    }))
    .addCase(actions.getSchoolAppIdSuccess, (state: StateType, action: AnyAction) => ({
      ...state,
      items: action.payload,
      isFetching: false,
      isError: false,
      messageError: null,
    }))
    .addCase(actions.getSchoolAppIdRejected, (state: StateType) => ({
      ...state,
      isFetching: false,
      isError: true,
      messageError: 'Erro ao solicitar APPs Ids.',
    }))

})

export default schoolAppIdReducer
