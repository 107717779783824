import React, { useEffect } from 'react'
import { Box, CircularProgress, Typography, Pagination } from '@mui/material'
import { styled } from '@mui/material/styles'
import { isEmpty } from 'lodash'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import SelectInput from '../../select/SelectInput'
import QuestionCard from '../../Questions/QuestionCard'
import CheckboxInput from '../../input/CheckboxInput'
import { scrollToTop } from '../../../utils/functions'
import { ReactComponent as NoOptionsImage } from '../../../assets/components/Questions/noOptions-image.svg'


import {
  questionItemInActivityFetch,
  schoolQuestionItemsFetchRequest,
} from '../../../store/questions/actions'
import NoItemsCard from '../../card/NoItemsCard'

const HeaderList = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '16px',
  '& p': {
    color: theme.palette.primary.main,
    fontWeight: 500
  },
  '& span': {
    fontSize: 14
  },
  '& .selectPage': {
    alignItems: 'center',
    display: 'flex',
    gap: 9
  }
}))

const QuestionsList = ({
  selectedQuestions,
  selectQuestion,
  selectAllItems,
  isSelectable,
  isAllItemsSelected,
  isSchoolQuestions,
  perPage,
  setPerPage,
  page,
  setPage,
  isToActivity
}: any) => {
  const dispatch = useAppDispatch()
  const { questions, persistable: { selectedUnit } } = useAppSelector(state => state)
  useEffect(() => {
    setPage(1)
    isSchoolQuestions
      ? dispatch(schoolQuestionItemsFetchRequest({ per: perPage, page: 1 }))
      : dispatch(questionItemInActivityFetch({ per: perPage, page: 1 }))
  }, [perPage, selectedUnit])

  const handleGetQuestionsItems = (params: object) => {
    isSchoolQuestions
      ? dispatch(schoolQuestionItemsFetchRequest(params))
      : dispatch(questionItemInActivityFetch(params))
  }


  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
    handleGetQuestionsItems({ page: value })
    scrollToTop()
  }

  const handleChangePerPage: React.ChangeEventHandler<HTMLInputElement> = event => {
    setPerPage(Number(event.target.value))
  }

  if (questions.isFetching) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '16px' }}>
        <CircularProgress />
      </Box>
    )
  }

  const questionsArray = (questions.items.entities.questions === null || questions.items.entities.questions === undefined) ? [] : questions.items.entities.questions
  const reversedQuestions = isSchoolQuestions ? Object.values(questionsArray).reverse() : Object.values(questionsArray)

  return (
    <>
      <HeaderList sx={{position:'relative'}}>
        <Typography component='p' variant='body2'>{questions.pagination.total} questõe(s) listada(s)</Typography>
        {isSelectable && <CheckboxInput
          name='select_question_all'
          label='Selecionar todas as questões'
          labelPlacement='end'
          onChange={selectAllItems('question')}
          checked={isAllItemsSelected('question')}
        />}
        <div className='selectPage'>
          <Typography component='p' variant='body2'>Exibir:</Typography>
          <SelectInput
            name='selectPage'
            defaultValue={perPage}
            onChange={handleChangePerPage}
            width='150px'
            options={[
              { label: '1 por página', value: 1 },
              { label: '5 por página', value: 5 },
              { label: '10 por página', value: 10 },
              { label: '15 por página', value: 15 },
            ]}
          />
        </div>
      </HeaderList>
      {isSelectable && (
        <Box>
        <Typography 
              sx={{
                color:'#f69e9e !important',
                fontSize:'12px',
                marginBottom: '16px'
              }}
            >
                Selecione pelo menos um item para avançar. *
            </Typography>
        </Box>
      )}
      {reversedQuestions && !isEmpty(reversedQuestions)
        ? Object.values(reversedQuestions).map((question: any) => {
          return (
            <Box key={question.id} sx={{ marginBottom: '16px', border: '1px solid #d9d9d9', borderRadius: '16px', overflow: 'hidden' }}>
              <QuestionCard
                question={questions.items.entities.questions[question.id]}
                isToActivity={isToActivity}
                showSettings
                selectQuestionCheck={isSelectable && <CheckboxInput
                  value={question?.content_item_id}
                  name='select_question'
                  label='Selecionar'
                  labelPlacement='end'
                  onChange={(event: any) => selectQuestion(event, questions.items.entities.questions[question.id])}
                  checked={!!selectedQuestions?.byId[question?.content_item_id]}
                />}
              />
            </Box>
          )
        })
        : questions?.items?.entities && isEmpty(questions.items.entities.questions) && Object.keys(questions.filter.query).length > 2
          ? (
            <NoItemsCard>
              <NoOptionsImage />
              <h3>Nenhuma questão encontrada.</h3>
            </NoItemsCard>
          ) : (
            <NoItemsCard>
              <NoOptionsImage />
              <h3>Você ainda não possui questões listadas</h3>
              <p>Para listar questões preencha os <span>filtros de pesquisa</span> no canto esquerdo superior</p>
            </NoItemsCard>
          )
      }
      {!questions.isFetching && questions.pagination &&
        questions.pagination?.totalPages > 1 && (
          <Pagination
            count={questions.pagination?.totalPages}
            size='small'
            color='primary'
            page={page}
            onChange={handleChangePage}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          />
        )}
    </>
  )
}

export default QuestionsList
