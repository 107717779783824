import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Components
import { Box, Button, Pagination, Skeleton, Typography } from '@mui/material'
import StudentCollapse from '../components/Management/collapses/StudentCollapse'

// Redux
import { clearClassroomStudents } from '../store/books/actions'
import { fetchClassroomWithStudentsCoordinatorRequest } from '../store/classroom/actions'
import { useAppDispatch, useAppSelector } from '../store/hooks'

// Icons and images
import { ReactComponent as PlusLightIcon } from '../assets/components/ManagersPage/plus-circled.svg'
import { ReactComponent as StudentsIcon } from '../assets/components/ManagersPage/students-icon.svg'
import NoClassroomsImage from '../assets/components/ManagersPage/no-classrooms.png'

// Styles
import { ClassroomsContainer, Header, NoContentBox, PaginationBox } from './styles/ManageStudentsContainer.styles'

const ManageStudentsContainer = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { selectedUnit } = useAppSelector(state => state.persistable)
  const { classes, isFetching, pagination } = useAppSelector(state => state.classroom)
  const [page, setPage] = useState(1)

  const getClassroomWithStudentsCoordinator = (params?: object) => {
    dispatch(fetchClassroomWithStudentsCoordinatorRequest({
      school_unit_ids: selectedUnit?.id,
      ...params
    }))
  }

  const handleToStudentFormPage = () => navigate('/manage-students/create')

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
    getClassroomWithStudentsCoordinator({ page: value })
  }

  useEffect(() => {
    getClassroomWithStudentsCoordinator()

    return () => {
      dispatch(clearClassroomStudents())
    }
  }, [selectedUnit])

  return (
    <Box sx={{ paddingTop: '20px' }}>
      <Header>
        <Box className='title'>
          <StudentsIcon />
          <Typography component='h2'>Selecione uma turma</Typography>
        </Box>

        {classes && classes.length > 0 && (
          <Button
            type='button'
            variant='contained'
            className='btn'
            onClick={handleToStudentFormPage}
            startIcon={<PlusLightIcon />}
          >
            Adicionar aluno(a)
          </Button>
        )}
      </Header>

      <ClassroomsContainer>
        {isFetching && (
          (Array(10).fill(0).map((_, index) => {
            return (
              <Skeleton
                key={index}
                animation='wave'
                variant='rounded'
                sx={{
                  height: '54px',
                  width: '100%'
                }}
              />
            )
          }))
        )}

        {!isFetching && (
          <>
            {classes && classes.length > 0 && classes?.map((item) => {
              return (
                <StudentCollapse
                  key={item.id}
                  item={item}
                  unit={selectedUnit}
                />
              )
            })}

            {classes && classes.length === 0 && (
              <NoContentBox>
                <Box className='image'>
                  <img src={NoClassroomsImage} alt='imagem' />
                </Box>

                <Typography className='message'>
                  Você <span>não tem turmas</span> cadastradas ainda
                </Typography>
              </NoContentBox>
            )}
          </>
        )}
      </ClassroomsContainer>

      {pagination?.totalOfPages > 1 && (
        <PaginationBox>
          <Pagination
            size='small'
            color='primary'
            count={pagination.totalOfPages}
            page={page}
            onChange={handleChangePage}
          />
        </PaginationBox>
      )}
    </Box>
  )
}

export default ManageStudentsContainer
