import React, { useEffect, useState } from 'react'

// Components
import { Box, Typography } from '@mui/material'
import { Swiper, SwiperSlide } from 'swiper/react'
import BookUsedItem from '../Books/items/BookUsedItem'
import SimpleSelect from '../select/SimpleSelect'
import { Navigation } from 'swiper'

// Utils
import { IBookAccessed } from '../../models/IBooks'

// Icons
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import BookIcon from '../../assets/components/Books/book-icon.svg'
import { ReactComponent as NotebookSVG } from '../../assets/components/Books/notebook-icon.svg'
import { ReactComponent as SchoolSVG } from '../../assets/components/Books/school-icon.svg'
import { ReactComponent as ViewSVG } from '../../assets/components/Books/view-icon.svg'

// Styles
import 'swiper/css'
import {
  BoxStyled,
  FilterContainer,
  LinkContainer,
  LinkStyled,
  PaperStyled
} from './styles/BooksUsedView.styles'
import ErrorMessage from '../message/ErrorMessage'

interface IOption {
  id: number
  name: string
}

interface IBooskUsedViewProps {
  books: IBookAccessed[]
  subjectOptions: IOption[]
  unitOptions: IOption[]
  rankingType?: 'mostUsed' | 'lessUsed'
  isError: boolean
}

const BooksUsedView: React.FC<IBooskUsedViewProps> = ({
  books,
  subjectOptions,
  unitOptions,
  rankingType,
  isError
}) => {
  const [booksAccessed, setBooksAccessed] = useState<IBookAccessed[]>([])
  const [subject, setSubject] = useState<IOption | null>(null)
  const [unit, setUnit] = useState<IOption | null>(null)

  const handleFilterSubject = (option: IOption) => {
    setSubject(option)
    if (!option) {
      setBooksAccessed(books)
    } else {
      const items = books.filter((book) => book.subjects.some(subject => subject.id === option.id))
      setBooksAccessed(items)
    }
  }

  const handleFilterUnit = (option: IOption) => {
    setUnit(option)
    if (!option) {
      setBooksAccessed(books)
    } else {
      const items = books.filter((book) => book.units.some(unit => unit.id === option.id))
      setBooksAccessed(items)
    }
  }

  useEffect(() => {
    setBooksAccessed(books)
  }, [books])

  return (
    <PaperStyled>
      {isError && (
        <ErrorMessage />
      )}

      {!isError && (
        <>
          <Box className='header'>
            <Box className='header_label'>
              <img src={BookIcon} />
              <Typography component='h3'>
                {rankingType === 'mostUsed' && 'Livros mais utilizados'}
                {rankingType === 'lessUsed' && 'Livros menos utilizados'}
              </Typography>
            </Box>

            <Box className='header_filters'>
              <FilterContainer>
                <NotebookSVG />
                <SimpleSelect
                  className='subjects'
                  placeholder='Disciplina...'
                  isClearable
                  options={subjectOptions}
                  onChange={handleFilterSubject}
                  value={subject}
                />
              </FilterContainer>

              <FilterContainer>
                <SchoolSVG />
                <SimpleSelect
                  className='units'
                  placeholder='Escola...'
                  isClearable
                  options={unitOptions}
                  onChange={handleFilterUnit}
                  value={unit}
                />
              </FilterContainer>
            </Box>
          </Box>

          <BoxStyled>
            <button className={`main-slider-prev-${rankingType}`}>
              <ArrowBackIosNewRoundedIcon sx={{ fontSize: '14px', color: '#fff' }} />
            </button>
            <Swiper
              slidesPerView={'auto'}
              className='slider'
              modules={[Navigation]}
              navigation={{
                prevEl: `.main-slider-prev-${rankingType}`,
                nextEl: `.main-slider-next-${rankingType}`
              }}
            >
              {booksAccessed?.map((book: any, key: number) => {
                return (
                  <SwiperSlide key={key}>
                    <BookUsedItem
                      book={book}
                      tag={key === 0}
                      rankingType={rankingType}
                    />
                  </SwiperSlide>
                )
              }
              )}
            </Swiper>
            <button className={`main-slider-next-${rankingType}`}>
              <ArrowForwardIosRoundedIcon sx={{ fontSize: '14px', color: '#fff' }} />
            </button>
          </BoxStyled>

          <LinkContainer>
            <LinkStyled to='#'>
              <ViewSVG /> Lista completa
            </LinkStyled>
          </LinkContainer>
        </>
      )}
    </PaperStyled>
  )
}

export default BooksUsedView
