import { Box, Paper } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Header = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',

  '& .title': {
    alignItems: 'center',
    display: 'flex',
    gap: '16px',

    '& > h3': {
      color: theme.palette.text.primary,
      fontSize: '18px',
      fontWeight: 600,
    }
  }
}))

export const Container = styled(Paper)(({ theme }) => ({
  borderRadius: '16px',
  padding: '40px',
  marginTop: '24px',

  '& .label': {
    color: '#9f9f9f',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '150%',
  },

  '& .title': {
    color: theme.palette.text.primary,
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '150%',
    marginTop: '8px'
  },

  '& .info': {
    display: 'flex',
    gap: '24px',
    marginTop: '24px',

    '& .info_description': {
      border: '1px dashed #b7b2c8',
      borderRadius: '16px',
      flex: 1,
      padding: '24px 16px',

      '& .description': {
        color: theme.palette.text.primary,
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '150%',
        marginTop: '8px',
      }
    },

    '& .info_data': {
      border: '1px dashed #b7b2c8',
      borderRadius: '16px',
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
      padding: '24px 16px',

      '& .second_line': {
        alignItems: 'center',
        display: 'flex',
        gap: '60px',

        '& .subject_container': {
          display: 'none'
        }
      },

      '& .subject, & .grade, & .classroom, & .start_date, & .end_date': {
        color: theme.palette.text.primary,
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '150%',
        whiteSpace: 'nowrap'
      },

      '& .start_date': {
        color: theme.palette.secondary.main
      },

      '& .end_date': {
        color: theme.palette.error.main
      }
    }
  },

  '& .questions_container': {
    display: 'flex',
    flexDirection: 'column',
    gap: '35px',
    marginTop: '75px'
  },

  [theme.breakpoints.down('xl')]: {
    padding: '66px 24px',

    '& .info': {
      flexDirection: 'column',
      gap: 0,

      '& .info_data': {
        border: 'none',

        '& .first_line': {
          display: 'none'
        },

        '& .second_line': {
          justifyContent: 'space-between',

          '& .subject_container': {
            display: 'initial'
          }
        }
      }
    }
  }
}))

export const LoadingContainer = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',

  '& .load': {
    maxWidth: '300px',
    width: '100%',
  }
}))

export const ErrorMessageBox = styled(Box)(() => ({
  background: '#fff',
  borderRadius: '16px',
  padding: '24px 16px',
}))
