import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Header = styled(Box)(({ theme }) => ({
  alignItems: 'flex-start',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '16px',

  '& .title': {
    alignItems: 'center',
    display: 'flex',
    gap: '16px',

    '& > h2': {
      color: theme.palette.text.primary,
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '120%',
    }
  },

  '& > .btn': {
    borderRadius: '8px',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '120%',
    padding: '14.5px 24.2px',
    textTransform: 'none',
  }
}))

export const ClassroomsContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
}))

export const PaginationBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '32px'
}))

export const NoContentBox = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  background: '#fff',
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '24px 0 70px',

  '& .image': {
    maxWidth: '336px',

    '& > img': {
      width: '100%',
    }
  },

  '& .message': {
    color: theme.palette.text.primary,
    fontSize: '18px',
    fontWeight: 500,
    letterSpacing: '0.18px',

    '& > span': {
      color: theme.palette.primary.light,
    }
  }
}))
